import TabLink from '../TabLink';

export function SubscriptionTabLink({ children }) {
  return (
    <TabLink
      to="/subscription"
      deep
      eventName="view-subscription-page"
      elementName="subscription-link"
      sectionName="side-menu-top-subscription-link"
    >
      {children}
    </TabLink>
  );
}
