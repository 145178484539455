import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../coeffects/environment';
import { toast } from '../../../../../../effects/toast';

export const RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL =
  'RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL';

registerEventHandler(
  RESEND_FIRST_PAYMENT_CHECKOUT_EMAIL,
  ({ environment: { apiUrl } }, { publisherId }) => ({
    ...toast.show({ text: 'First Payment Email has been sent successfully!' }),
    ...http.post({
      url: `${apiUrl}/admin/publishers/${publisherId}/resend-first-payment-email`,
    }),
  }),
  [environment()]
);
