import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch, dispatchMany } from 'reffects';
import { useCallback } from 'react';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import InputText from 'design-system/components/InputText';
import InputNumber from 'design-system/components/InputNumber';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import Section from '../Section';
import {
  availablePaymentMethodsSelector,
  checkErrorOnMaxSuperboostableSelector,
  disabledAdditionalMonthSelector,
  isBillingCycleDisabledSelector,
  isCreatingDeveloperSelector,
  isDisabledPaymentMethodSelectSelector,
  isNextBillingDateDisabledSelector,
  isSuperboostablePropertiesDisabledSelector,
  planSelectorShouldBeDisabledSelector,
  publisherFormInfoSelector,
  selectedAdditionalMonthSelector,
  selectedSuperboostablePropertiesSelector,
  shouldShowBillingContactEmailInputSelector,
  shouldShowMultipleAccountOptionsSelector,
  shouldShowSiblingPublisherAutocompleteSelector,
} from '../selectors';
import { createPlansByMaxBoostablePropertiesSelector } from '../../selectors';
import PaymentMethodSelect from '../../partials/PaymentMethodSelect/PaymentMethodSelect';
import MaxBoostablePropertiesSelectForPublisherCreation from '../MaxBoostablePropertiesSelectForPublisherCreation';
import {
  CREATE_PUBLISHER_FORM_BILLING_CYCLE_CHANGED,
  CREATE_PUBLISHER_FORM_PLAN_OPTION_SELECTED,
  CREATE_PUBLISHER_FORM_TYPE_OF_CLIENT_OPTION_SELECTED,
  CREATE_PUBLISHER_FORM_VALUE_CHANGED,
  MULTIPLE_ACCOUNT_TYPE_CHANGED,
} from '../events';
import BillingEntityAutocomplete from './partials/BillingEntityAutocomplete/BillingEntityAutocomplete';
import {
  CREATE_PUBLISHER_PAGE,
  MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE,
} from '../constants';
import { useFeatureForCountrySelector } from '../../../../utils/featuresFlags/selectors';
import SiblingPublisherAutocomplete from './partials/SiblingPublisherAutocomplete/SiblingPublisherAutocomplete';
import PlanToSubscribe from '../../partials/PlanToSubscribe/PlanToSubscribe';
import TypeOfClientSelect from '../../partials/TypeOfClientSelect/TypeOfClientSelect';
import { EMAIL_VALIDATION_REGEX } from '../../../../constants';
import InputSelectWithHtmlValidation from '../../partials/InputSelectWithHtmlValidation';
import { isLATAM, isMexico } from '../../../../utils/countries';
import { BILLING_CYCLES } from '../../../../constants/subscription';
import { canUseAdditionalMonthCheckboxSelector } from '../../Agencies/partials/AgenciesTable/Modals/RequestSubscriptionChangeModal/selectors';
import { countryHasSuperboostEnabled } from '../../../../selectors/config';

const CheckboxLabel = styled(Checkbox.Label)`
  display: inline-flex;
`;

function AgencyBillingDetails({ onInputChange, onCheckboxChange }) {
  const publisher = useSelector(publisherFormInfoSelector);
  const isPlanSelectorDisabled = useSelector(
    planSelectorShouldBeDisabledSelector
  );
  const plansByMaxBoostableProperties = useSelector(
    createPlansByMaxBoostablePropertiesSelector(
      `${CREATE_PUBLISHER_PAGE}.maxBoostablePropertiesSelected`
    )
  );
  const isSuperboostablePropertiesDisabled = useSelector(
    isSuperboostablePropertiesDisabledSelector
  );
  const isBillingCycleDisabled = useSelector(isBillingCycleDisabledSelector);
  const billingCyclesForSelectedPlan = BILLING_CYCLES;
  const shouldShowBillingContactEmailInput = useSelector(
    shouldShowBillingContactEmailInputSelector
  );
  const shouldShowMultipleAccountOptions = useSelector(
    shouldShowMultipleAccountOptionsSelector
  );
  const shouldShowSiblingPublisherAutocomplete = useSelector(
    shouldShowSiblingPublisherAutocompleteSelector
  );
  const availablePaymentMethods = useSelector(availablePaymentMethodsSelector);
  const SUPERBOOST_ALL_COUNTRIES_3119 = useFeatureForCountrySelector(
    'SUPERBOOST_ALL_COUNTRIES_3119',
    publisher.country
  );
  const onInputChangeBuilderForSelects = useCallback(
    (name) =>
      ({ value }) =>
        onInputChange({ target: { value, name } }),
    [onInputChange]
  );
  const superboostEnabled = countryHasSuperboostEnabled(publisher.country);
  const mexicoCountrySelected = isMexico(publisher.country);
  const disabledPaymentMethod = useSelector(
    isDisabledPaymentMethodSelectSelector
  );
  const additionalMonth = useSelector(selectedAdditionalMonthSelector);
  const disabledAdditionalMonth = useSelector(disabledAdditionalMonthSelector);
  const latamCountrySelected = isLATAM(publisher.country);
  const canUseAdditionalMonthCheckbox = useSelector(
    canUseAdditionalMonthCheckboxSelector
  );

  return (
    <>
      {mexicoCountrySelected && (
        <TypeOfClientSelect
          value={publisher[TypeOfClientSelect.propertyName]}
          onChange={onTypeOfClientChanged}
        />
      )}
      <MaxBoostablePropertiesSelectForPublisherCreation />
      {!mexicoCountrySelected && (
        <PlanToSubscribe
          name="plan"
          label="Plan"
          required
          disabled={isPlanSelectorDisabled}
          value={publisher.plan}
          onChange={onPlanSelectorChanged}
          plansByMaxBoostableProperties={plansByMaxBoostableProperties}
          countryCode={publisher.country}
          displayFreeOption
        />
      )}
      {(SUPERBOOST_ALL_COUNTRIES_3119 || superboostEnabled) && (
        <SuperboostableProperties
          onChange={onSuperboostablePropertiesChanged}
          disabled={isSuperboostablePropertiesDisabled}
        />
      )}
      <BillingCycle
        required={!isBillingCycleDisabled}
        disabled={isBillingCycleDisabled}
        value={publisher.billingCycle}
        onChange={onBillingCycleChanged}
        billingCyclesForSelectedPlan={billingCyclesForSelectedPlan}
      />
      {latamCountrySelected && (
        <>
          {canUseAdditionalMonthCheckbox && (
            <>
              <EmptyCell />
              <CheckboxLabel>
                <Checkbox
                  name="additionalMonth"
                  checked={additionalMonth ?? false}
                  onChange={onCheckboxChange}
                  disabled={disabledAdditionalMonth}
                />
                <Body1>Add 1 month for free</Body1>
              </CheckboxLabel>
            </>
          )}
        </>
      )}

      <PaymentMethodSelect
        availablePaymentMethods={availablePaymentMethods}
        value={publisher.paymentMethod}
        onChange={onInputChangeBuilderForSelects('paymentMethod')}
        disabled={disabledPaymentMethod}
      />
      <BillingDate onChange={onInputChange} value={publisher.billingDate} />
      {shouldShowMultipleAccountOptions && (
        <>
          <MultipleAccountSelect publisher={publisher} />
          {shouldShowSiblingPublisherAutocomplete && (
            <SiblingPublisherAutocomplete />
          )}
          {shouldShowBillingContactEmailInput && (
            <InputText
              label="Billing contact email "
              name="billingContactEmail"
              onChange={onInputChange}
              value={publisher.billingContactEmail}
              type="email"
              pattern={EMAIL_VALIDATION_REGEX}
              required
            />
          )}
        </>
      )}
    </>
  );
}

function DeveloperBillingDetails() {
  return <BillingEntityAutocomplete required />;
}

function BillingCycle({
  required,
  disabled,
  value,
  onChange,
  billingCyclesForSelectedPlan,
}) {
  return (
    <InputSelectWithHtmlValidation
      name="billingCycle"
      label="Billing Cycle"
      value={value}
      disabled={disabled}
      required={required}
      choices={billingCyclesForSelectedPlan.map((billingCycle) => ({
        id: billingCycle,
        label: `${billingCycle} month`,
        value: billingCycle,
      }))}
      onSelect={onChange}
    />
  );
}

function BillingDate(props) {
  const isNextBillingDateDisabled = useSelector(
    isNextBillingDateDisabledSelector
  );

  return (
    <InputText
      label="Next billing date"
      type="date"
      name="billingDate"
      disabled={isNextBillingDateDisabled}
      required={!isNextBillingDateDisabled}
      {...props}
    />
  );
}

function SuperboostableProperties({ onChange, disabled }) {
  const checkErrorOnMaxSuperboostable = useSelector(
    checkErrorOnMaxSuperboostableSelector
  );
  const selectedSuperboostableProperties = useSelector(
    selectedSuperboostablePropertiesSelector
  );
  return (
    <InputNumber
      label="Superboostable Properties"
      name="superboostableProperties"
      value={
        selectedSuperboostableProperties === undefined
          ? 0
          : selectedSuperboostableProperties
      }
      placeholder="Superboostable Properties"
      onChange={({ target: { name, value } }) => onChange({ name, value })}
      error={checkErrorOnMaxSuperboostable}
      tip={
        checkErrorOnMaxSuperboostable
          ? `The amount can't be greater than boostable properties`
          : ''
      }
      disabled={disabled}
    />
  );
}
function MultipleAccountSelect({ publisher }) {
  const name = 'multipleAccountClient';
  return (
    <InputSelectWithHtmlValidation
      name={name}
      label="Multiple accounts client"
      value={publisher.multipleAccountClient}
      required
      choices={[
        {
          id: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.NO,
          label: 'No',
          value: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.NO,
        },
        {
          id: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.MAIN,
          label: 'Yes, main account',
          value: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.MAIN,
        },
        {
          id: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED,
          label: 'Yes, child account',
          value: MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED,
        },
      ]}
      onSelect={({ value }) =>
        onMultiAccountClientChanged({
          target: { name, value },
        })
      }
    />
  );
}

function BillingDetails({ ...props }) {
  const isDeveloper = useSelector(isCreatingDeveloperSelector);

  return (
    <Section title="Plan & billing details">
      {isDeveloper ? (
        <DeveloperBillingDetails {...props} />
      ) : (
        <AgencyBillingDetails {...props} />
      )}
    </Section>
  );
}

function onPlanSelectorChanged({ value }) {
  dispatch({
    id: CREATE_PUBLISHER_FORM_PLAN_OPTION_SELECTED,
    payload: { plan: value },
  });
}

function onTypeOfClientChanged({ value }) {
  dispatch({
    id: CREATE_PUBLISHER_FORM_TYPE_OF_CLIENT_OPTION_SELECTED,
    payload: { typeOfClient: value },
  });
}
function onMultiAccountClientChanged(event) {
  const { name, value } = event.target;
  dispatch({ id: MULTIPLE_ACCOUNT_TYPE_CHANGED, payload: { [name]: value } });
}
function onSuperboostablePropertiesChanged(event) {
  const { name, value } = event;
  dispatchMany([
    {
      id: CREATE_PUBLISHER_FORM_VALUE_CHANGED,
      payload: { name, value },
    },
  ]);
}

function onBillingCycleChanged({ value }) {
  dispatch({
    id: CREATE_PUBLISHER_FORM_BILLING_CYCLE_CHANGED,
    payload: { billingCycle: value },
  });
}

function EmptyCell() {
  return <div />;
}

export default styled(BillingDetails)``;
