import {
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
  DIRECT_DEBIT,
} from '../../../../constants/paymentMethod';

const creditCard = paymentMethod(CREDIT_CARD, 'Credit Card');
const bankTransfer = paymentMethod(BANK_TRANSFER, 'Bank Transfer');
const cash = paymentMethod(CASH, 'Cash');
const directDebit = paymentMethod(DIRECT_DEBIT, 'Direct Debit');

function paymentMethod(id, label) {
  return { id, label };
}

const countriesWithoutCreditCard = ['AE', 'ES', 'MX', 'TH'];
const countriesWithoutBankTransfer = ['CO'];
const countriesWithoutCash = ['AE', 'AR', 'EC', 'PA', 'ES'];
const countriesWithDirectDebit = ['ES'];

export function availablePaymentMethodsForCountry(country) {
  if (!country) {
    return [];
  }
  const paymentMethods = [];
  if (!countriesWithoutCreditCard.includes(country)) {
    paymentMethods.push(creditCard);
  }

  if (!countriesWithoutBankTransfer.includes(country)) {
    paymentMethods.push(bankTransfer);
  }

  if (!countriesWithoutCash.includes(country)) {
    paymentMethods.push(cash);
  }

  if (countriesWithDirectDebit.includes(country)) {
    paymentMethods.push(directDebit);
  }

  return paymentMethods;
}
