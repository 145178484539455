import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Tooltip from 'design-system/components/Tooltip';
import Menu from 'design-system/components/Menu/Menu';
import { MENU_ITEM_ICON_POSITION } from 'design-system/components/Menu/partials/BaseItem';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import useTracking from '../../hooks/useTracking';
import FormattedMessageWithValidation from '../FormattedMessageWithValidation/FormattedMessageWithValidation';

const LinkToSite = styled(FlatButton)`
  &[data-active] {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    ${Icon} {
      ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
    }
  }
`;

const HighlightContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 150px;
`;

const BannedIcon = styled(Icon)`
  &&& {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }
`;

function SiteLink({ link, site, banned, ...rest }) {
  if (banned) {
    return <BannedSite id={site} />;
  }

  return <Site id={site} href={link} {...rest} />;
}

export default function DropdownLinksMenu({
  sites,
  active,
  section,
  elementName,
  eventName,
  numberOfSites,
  hasBannedBrands,
}) {
  const buttonEnabled = active && sites.length > 0;
  if (sites.length === 1) {
    const [{ site, detailUrl }] = sites;
    const ref = useTracking({
      trackClick: {
        eventName,
        payload: { site },
      },
      trackImpression: {
        elementName,
      },
      sectionName: section,
    });
    return (
      <LinkToSite
        disabled={!buttonEnabled}
        active={buttonEnabled}
        icon={<Icon glyph="link" />}
        data-stonly="properties-links"
        forwardedAs="a"
        href={detailUrl}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        ref={ref}
      />
    );
  }
  return (
    <DropdownMenu
      tooltip={
        <Tooltip
          title={
            <FormattedMessageWithValidation id="properties_list_site_links_tooltip" />
          }
        />
      }
      button={
        <FlatButton
          disabled={!buttonEnabled}
          active={buttonEnabled}
          icon={
            <Icon
              glyph="link"
              counterText={
                buttonEnabled && numberOfSites != null
                  ? `x${numberOfSites}`
                  : ''
              }
            />
          }
          data-stonly="properties-links"
        />
      }
    >
      <Menu>
        {sites.map(({ site, detailUrl, banned }) => (
          <SiteLink
            key={site}
            site={site}
            link={detailUrl}
            section={section}
            eventName={eventName}
            elementName={elementName}
            banned={banned}
          />
        ))}
        {hasBannedBrands && (
          <Menu.Highlight icon={<Icon glyph="error" />}>
            <HighlightContent>
              <Body2 $weight={FONT_WEIGHT.NORMAL}>
                <FormattedMessageWithValidation id="properties_list_site_links_highlight_text" />
              </Body2>
            </HighlightContent>
          </Menu.Highlight>
        )}
      </Menu>
    </DropdownMenu>
  );
}

function Site({ id, href, section, elementName, eventName }) {
  const [linkElement, setLinkElement] = useState(null);
  const ref = useCallback((node) => {
    if (node != null && linkElement !== node) setLinkElement(node);
  }, []);
  useTracking({
    ref: linkElement,
    trackClick: {
      eventName,
      payload: { id },
    },
    trackImpression: {
      elementName,
    },
    sectionName: section,
  });

  return (
    <Menu.Item
      forwardedAs="a"
      href={href}
      id={id}
      label={<Body1 $noWrap>{id}</Body1>}
      target="_blank"
      rel="noreferrer"
      onClick={withoutEventPropagation()}
      onAuxClick={withoutEventPropagation()}
      ref={ref}
    />
  );
}

function BannedSite({ id }) {
  return (
    <Menu.Item
      id={id}
      label={<Body1 $noWrap>{id}</Body1>}
      disabled
      icon={
        <BannedIcon
          glyph="error"
          $color={COLOR_PALETTE.PUMPKIN_BASE}
          $size={ICON_SIZE.SMALL}
        />
      }
      iconPosition={MENU_ITEM_ICON_POSITION.END}
    />
  );
}
