import { createSelector } from 'reselect';
import { BACKOFFICE_ROLES, CUSTOMER_ROLES } from '../constants/user';

export const userSelector = (state) => state.user;

export const userIdSelector = createSelector(userSelector, (user) => user?.id);

export const userEmailSelector = createSelector(
  userSelector,
  (user) => user?.email
);

export const userRoleSelector = createSelector(
  userSelector,
  (user) => user?.role ?? null
);

const userIs = (userRole) =>
  createSelector(userRoleSelector, (role) => role === userRole);

export const userIsSuperadminSelector = userIs(BACKOFFICE_ROLES.SUPER_ADMIN);

export const userIsSalesSelector = userIs(BACKOFFICE_ROLES.SALES);

export const userIsSalesManagerSelector = userIs(
  BACKOFFICE_ROLES.SALES_MANAGER
);

export const userIsSupportSelector = userIs(BACKOFFICE_ROLES.SUPPORT);

export const userIsFinanceSelector = userIs(BACKOFFICE_ROLES.FINANCE);

export const userIsFinanceManagerSelector = userIs(
  BACKOFFICE_ROLES.FINANCE_MANAGER
);

export const userIsClientSelector = userIs(CUSTOMER_ROLES.CLIENT);

export const userIsPublisherCollaboratorSelector = userIs(
  CUSTOMER_ROLES.PUBLISHER_COLLABORATOR
);

export const oneOf =
  (...selectors) =>
  (state) =>
    selectors.some((f) => f(state));

export function isBackofficeUserSelector(state) {
  return Object.values(BACKOFFICE_ROLES).some((role) => userIs(role)(state));
}

export function localeSelector(state) {
  return state.locale;
}

export function userManagesMultiplePublishersSelector(state) {
  return userSelector(state)?.managesMultiplePublishers ?? false;
}

export function userManagedCountriesSelector(state) {
  return userSelector(state)?.managedCountries ?? [];
}
