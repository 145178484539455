import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { agencyTimeLineItemsSelector } from './selectors';
import { TimelineItem } from './Item';

const Container = styled.div`
  width: 75%;
  margin: auto;
  display: block;
  position: relative;
`;

const TimelineList = styled.ul`
  margin: 20px 0;
  padding: 0;
  display: inline-block;
`;

function Timeline() {
  const items = useSelector(agencyTimeLineItemsSelector);

  return (
    <div>
      <h2>Timeline</h2>
      <Container>
        <TimelineList>
          {items.slice(0, 30).map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TimelineItem event={item} key={`item_${i}`} />
          ))}
        </TimelineList>
      </Container>
    </div>
  );
}

export default Timeline;
