import Layout from 'design-system/components/Layout';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { useSelector } from 'reffects-store';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import BackLink from 'design-system/components/BackLink';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { GO_BACK_TO_LEADS } from '../../events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import LeadContact from './partials/LeadContact/LeadContact';
import LeadFeedback from '../LeadFeedback';
import {
  hasLeadPlusSelector,
  hasQualificationSelector,
  isLoadingSelector,
} from '../../selectors';
import { publisherSubscriptionPlanIsPaidSelector } from '../../../../selectors/publisher';
import LeadPlus from './partials/LeadPlus/LeadPlus';
import { useFeatureSelector } from '../../../../utils/featuresFlags/selectors';
import Qualification from './partials/Qualification/Qualification';
import QualificationFeedback from './partials/Qualification/QualificationFeedback';

const FlexSideBar = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const BackButton = styled(BackLink)`
  ${spacing.stack(SPACING_SIZE.S)}
`;

const Divider = styled.div`
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
`;

const LeadPlusDivider = styled.div.attrs({
  children: (
    <Tag variant={TAG_VARIANT.GOLD}>
      <FormattedMessageWithValidation id="leadpage_contact_lead_plus_activity" />
    </Tag>
  ),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
    position: relative;

  ${Tag} {
    position: absolute;
  }
`;

const QualificationDivider = styled.div.attrs({
  children: (
    <Tag variant={TAG_VARIANT.GOLD}>
      <FormattedMessageWithValidation id="leadpage_contact_lead_qualification_activity" />
    </Tag>
  ),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  ${divider.bottom(DIVIDER_SIZE.THIN)}
  ${radius.regular(RADIUS_SIZE.LARGE)}
    position: relative;

  ${Tag} {
    position: absolute;
  }
`;

export default function LeadInformation() {
  const isLoading = useSelector(isLoadingSelector);
  const hasLeadPlus = useSelector(hasLeadPlusSelector);
  const hasQualification = useSelector(hasQualificationSelector);
  const DEVELOPERS_QUALIFICATION_WEB_3899 = useFeatureSelector(
    'DEVELOPERS_QUALIFICATION_WEB_3899'
  );
  const publisherSubscriptionPlanIsPaid = useSelector(
    publisherSubscriptionPlanIsPaidSelector
  );

  return (
    <FlexSideBar>
      <Layout.Padded>
        <BackButton onClick={() => dispatch(GO_BACK_TO_LEADS)}>
          <FormattedMessageWithValidation id="leadpage_back_to_enquiries" />
        </BackButton>
        <LeadContact />
      </Layout.Padded>
      <Splitter
        isLoading={isLoading}
        hasLeadPlus={hasLeadPlus}
        hasQualification={DEVELOPERS_QUALIFICATION_WEB_3899 && hasQualification}
        publisherSubscriptionPlanIsPaid={publisherSubscriptionPlanIsPaid}
      />
      {hasLeadPlus && (
        <>
          <LeadPlus />
          {!isLoading && publisherSubscriptionPlanIsPaid && <LeadFeedback />}
        </>
      )}
      {DEVELOPERS_QUALIFICATION_WEB_3899 && hasQualification && (
        <>
          <Qualification />
          {!isLoading && <QualificationFeedback />}
        </>
      )}
    </FlexSideBar>
  );
}

function Splitter({
  isLoading,
  hasLeadPlus,
  hasQualification,
  publisherSubscriptionPlanIsPaid,
}) {
  if (isLoading) {
    return <Divider />;
  }

  if (hasLeadPlus && publisherSubscriptionPlanIsPaid) {
    return <LeadPlusDivider />;
  }

  if (hasQualification) {
    return <QualificationDivider />;
  }

  return <Divider />;
}
