import Layout from 'design-system/components/Layout';
import styled from 'styled-components';
import Grid from 'design-system/components/Grid';
import { SUBSCRIPTION } from '../../constants/routes';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import BankTransferPaymentNotice from './partials/BankTransferPaymentNotice';
import BankTransferInformation from './partials/BankTransferInformation';
import SubscriptionLayout from './partials/Layout/Layout';
import BackButton from './partials/Layout/BackButton';
import Header from './partials/Layout/Header';

const Content = styled.div`
  grid-column: 3 / span 8;
`;

export default function BankTransferDetails() {
  return (
    <SubscriptionLayout>
      <Layout.Padded>
        <Grid>
          <Content>
            <BackButton to={SUBSCRIPTION}>
              <FormattedMessageWithValidation id="subscription_back_to_subscription" />
            </BackButton>
            <Header>
              <FormattedMessageWithValidation id="subscription_bank_transfer_details_header" />
            </Header>
            <BankTransferInformation />
            <BankTransferPaymentNotice />
          </Content>
        </Grid>
      </Layout.Padded>
    </SubscriptionLayout>
  );
}
