import { uniqueId } from 'lodash';
import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import queryString from 'query-string';
import { state } from 'reffects-store';
import { table } from '../../../../partials/DataTable/effects/table';
import { environment } from '../../../../coeffects/environment';
import { mapLeadFilters } from './mapLeadFilters';
import { LEAD_PLUS_ELIGIBLE_LOADED } from './events';
import { featureFlags } from '../../../../coeffects/featureFlags';
import { LEAD_QUALIFICATION_LOADED } from '../../../LeadDetail/partials/LeadInformation/partials/Qualification/events';

export function createAdapter(collectionName, mapResponse) {
  const eventSuffix = uniqueId(collectionName);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;
  const UPDATE_PAGINATION_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_PAGINATION_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;
  const REQUEST_LEAD_PAGINATION = `REQUEST_LEAD_PAGINATION_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ select, environment: { apiUrl } }, context) => {
      const { currentPage, itemsPerPage } = select.pagination(context);
      const tableFilters = select.filters(context);
      const { field, direction } = select.sorting(context);
      const apiFilters = mapLeadFilters(tableFilters);

      const query = queryString.stringify(
        {
          _page: currentPage,
          _limit: itemsPerPage,
          _sort: field,
          _order: direction,
          ...apiFilters,
        },
        { skipNull: true }
      );

      return {
        ...table.setBulkProcessing(context.collectionName, {
          isLoading: true,
          isProcessing: false,
        }),
        ...http.get({
          url: `${apiUrl}/leads${query && `?${query}`}`,
          successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
        }),
        ...effects.dispatch({
          id: REQUEST_LEAD_PAGINATION,
          payload: {
            url: `${apiUrl}/leads-pagination${query && `?${query}`}`,
            context,
          },
        }),
      };
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    REQUEST_LEAD_PAGINATION,
    (_, { url, context }) => ({
      ...http.get({
        url,
        successEvent: [UPDATE_PAGINATION_ITEMS_TABLE_DATA_SUCCESS, context],
      }),
    }),
    []
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (
      {
        environment: { apiUrl },
        state: { paginationOnInbox, isDeveloper },
        features: { DEVELOPERS_QUALIFICATION_WEB_3899 },
      },
      [
        {
          data: { rows, totalFilteredRows, hasLeads },
        },
      ]
    ) => {
      const leads = mapResponse(rows);
      const visibleItemsIdList = leads.map(({ id }) => id);
      let totalFilteredRowsState = totalFilteredRows;
      if (paginationOnInbox > 0) {
        totalFilteredRowsState = paginationOnInbox;
      }

      const fetchExtraInformation = generateExtraInformationRequest(
        apiUrl,
        isDeveloper,
        leads,
        DEVELOPERS_QUALIFICATION_WEB_3899
      );

      return {
        ...table.update(collectionName, {
          collection: leads,
          totalItems: totalFilteredRowsState,
          isEmpty: !hasLeads,
          visibleItemsIdList,
        }),
        ...(leads.length > 0 ? fetchExtraInformation : {}),
      };
    },
    [
      environment(),
      state.get({
        paginationOnInbox: 'paginationOnInbox',
        isDeveloper: 'publisher.isDeveloper',
      }),
      featureFlags('DEVELOPERS_QUALIFICATION_WEB_3899'),
    ]
  );
  registerEventHandler(
    UPDATE_PAGINATION_ITEMS_TABLE_DATA_SUCCESS,
    (
      _,
      [
        {
          data: { totalFilteredRows },
        },
      ]
    ) => ({
      ...state.merge({
        paginationOnInbox: totalFilteredRows,
        dataTables: {
          [collectionName]: {
            pagination: {
              totalItems: totalFilteredRows,
            },
          },
        },
      }),
    }),
    []
  );

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      events: {
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,
        UPDATE_PAGINATION_ITEMS_TABLE_DATA_SUCCESS,
        REQUEST_LEAD_PAGINATION,
      },
    };
  }

  return {
    registerEvents,
  };
}

function generateExtraInformationRequest(
  apiUrl,
  isDeveloper,
  leads,
  DEVELOPERS_QUALIFICATION_WEB_3899
) {
  if (isDeveloper) {
    if (!DEVELOPERS_QUALIFICATION_WEB_3899) {
      return {};
    }

    return http.post({
      url: `${apiUrl}/qualified-leads`,
      body: createBodyToRetrieveLeadsQualification(leads),
      successEvent: LEAD_QUALIFICATION_LOADED,
    });
  }

  return http.post({
    url: `${apiUrl}/eligible-lead-plus`,
    body: createBodyToRetrieveEligibleLeads(leads),
    successEvent: LEAD_PLUS_ELIGIBLE_LOADED,
  });
}

function createBodyToRetrieveEligibleLeads(leads) {
  return {
    leads: leads.map(({ id, email, phone }) => ({
      id,
      contactEmail: email,
      contactPhone: phone,
    })),
  };
}

function createBodyToRetrieveLeadsQualification(leads) {
  return {
    leads: leads.map(({ id }) => id),
  };
}
