import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../../../coeffects/environment';

export const REQUEST_LEAD_QUALIFICATION = 'REQUEST_LEAD_QUALIFICATION';
export const LEAD_QUALIFICATION_LOADED = 'LEAD_QUALIFICATION_LOADED';

registerEventHandler(
  REQUEST_LEAD_QUALIFICATION,
  ({ environment: { apiUrl }, state: { leadId } }) =>
    http.get({
      url: `${apiUrl}/lead-qualification/${leadId}`,
      successEvent: LEAD_QUALIFICATION_LOADED,
    }),
  [environment(), state.get({ leadId: 'lead.id' })]
);

registerEventHandler(LEAD_QUALIFICATION_LOADED, (_, [{ data }]) =>
  state.set({ qualification: data })
);
