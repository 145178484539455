import { availablePaymentMethodsForCountry } from '../pages/partials/PaymentMethodSelect/selectors';
import { isChile, isLATAM, isMexico } from '../../utils/countries';

function isSmallPublisher(publisher) {
  if (isMexico(publisher.country) || isChile(publisher.country)) {
    return false;
  }
  if (isLATAM(publisher.country)) {
    return publisher.boostingLimit <= 10 && publisher.billingCycle < 12;
  }

  return false;
}

export function getAvailablePaymentMethods(publisher) {
  const paymentMethods = availablePaymentMethodsForCountry(publisher.country);
  if (isSmallPublisher(publisher)) {
    return paymentMethods.filter(({ id }) => id === 'credit-card');
  }
  if (publisher.subscriptionExpired) {
    return paymentMethods.filter(({ id }) => id !== 'cash');
  }
  return paymentMethods;
}
