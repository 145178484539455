import './ImagePreview.scss';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';

export default function ImagePreview({ image, onDelete }) {
  const src = isAFile(image) ? URL.createObjectURL(image) : image;

  return (
    <div className="ImagePreview">
      <img src={src} aria-hidden alt="property image preview" />
      <button
        className="ImagePreview__trash-bin"
        type="button"
        onClick={onDelete}
      >
        <Icon glyph="trash" $size={ICON_SIZE.BASE} />
      </button>
    </div>
  );
}

function isAFile(image) {
  return image instanceof Blob || image instanceof File;
}
