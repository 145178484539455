import { createDate } from './dates';
import { stonly } from '../effects/stonly';

export function identifyInStonly({
  id,
  email,
  dateOfTermsAndConditionsAcceptance,
  locale,
  isBackofficeUser,
  isDeveloper,
}) {
  const date = dateOfTermsAndConditionsAcceptance
    ? createDate(dateOfTermsAndConditionsAcceptance)
    : null;
  return stonly.identify(id, email, {
    locale,
    type: isDeveloper ? 'developer' : 'agency',
    date_of_terms_and_conditions_acceptance: date,
    is_admin: isBackofficeUser,
  });
}
