import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from '../partials/Layout/Layout';
import './SubscriptionPlansManagement.scss';
import registerSubscriptionPlansManagementEvents, {
  SUBSCRIPTION_PLANS_MANAGEMENT_INIT,
} from './events';
import Tabs from '../partials/Tabs/Tabs';
import SubscriptionPlanTable from './SubscriptionPlanTable';
import {
  FILTERS,
  ROWS_PER_PAGE,
  SUBSCRIPTION_PLANS_TABLE_ID,
} from './constants';

import createSubscriptionPlanTableAdapter from './SubscriptionPlanTableAdapter';
import { DataTableProvider } from '../../../partials/DataTable';
import SubscriptionPlanHeader from './SubscriptionPlanHeader';
import { publisherCountrySelector } from '../../../selectors/publisher';

function SubscriptionPlansManagement() {
  const countryCode = useSelector(publisherCountrySelector);
  const [searchParams] = useSearchParams();
  const countrySelected = searchParams.get('country') ?? countryCode;
  const publishesInTPSelected = searchParams.get('publishesInTP') ?? false;

  useEffect(() => {
    registerSubscriptionPlansManagementEvents();
    dispatch({
      id: SUBSCRIPTION_PLANS_MANAGEMENT_INIT,
      payload: { countryCode: countrySelected },
    });
  }, [countrySelected]);

  const context = {
    collectionName: SUBSCRIPTION_PLANS_TABLE_ID,
    defaultSorting: {},
    defaultFilters: {
      [FILTERS.COUNTRY]: countrySelected,
      [FILTERS.PUBLISHES_IN_TP]: publishesInTPSelected,
    },
    initialItemsPerPage: ROWS_PER_PAGE,
    adapter: createSubscriptionPlanTableAdapter(),
  };
  return (
    <Layout>
      <DataTableProvider {...context}>
        <div className="SubscriptionPlansManagement">
          <h2>Pricing Table</h2>
          <SubscriptionPlanHeader />
          <Tabs>
            <section label="Subscription plan">
              <SubscriptionPlanTable
                editionField="price"
                currencyField="currency"
                selectedCountry={countrySelected}
              />
            </section>
            <section label="CPC">
              <SubscriptionPlanTable
                editionField="cpcPrice"
                currencyField="cpcCurrency"
                selectedCountry={countrySelected}
              />
            </section>
          </Tabs>
        </div>
      </DataTableProvider>
    </Layout>
  );
}

export default SubscriptionPlansManagement;
