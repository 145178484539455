import { cloneElement, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'react-use';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { divider, DIVIDER_SIZE } from 'design-system/styles/divider';
import Loader from 'design-system/components/Loader';
import { scrollbar } from 'design-system/styles/scrollbar';
import {
  COLUMN_SORTING,
  TABLE_ROW_SEPARATION,
  TABLE_VARIANT,
} from './constants';
import ColumnTitle from './ColumnTitle';
import Cell from './Cell';
import { KpiCell } from './presets';
import CellLink from './CellLink';
import Row from './Row';

export const TABLE_SORTING = COLUMN_SORTING;
export const TABLE_ALIGNMENT = {
  RIGHT: Symbol('TABLE_ALIGNMENT_RIGHT'),
  LEFT: Symbol('TABLE_COLUMN_ALIGNMENT_LEFT'),
  CENTER: Symbol('TABLE_COLUMN_ALIGNMENT_CENTER'),
};

const Wrapper = styled.div``;

const TableContainer = styled.div`
  --scrollbar-width: 15px;

  position: relative;
  ${spacing.stack(SPACING_SIZE.M)}

  ${scrollbar}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${({ columns }) => columns && columns.map(alignColumn)}
`;

const Header = styled.thead`
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 2;
    `}
  ${Row} {
    ${Row.mods.disableHover()}
  }
`;

const LoaderWrapper = styled.tr`
  td {
    ${spacing.insetSandwich(SPACING_SIZE.XXXL)}

    ${Loader} {
      margin: 0 auto;
    }
  }
`;

const modAlignmentLeft = () => css`
  justify-content: flex-start;
`;

const modAlignmentRight = () => css`
  justify-content: flex-end;
`;

const modAlignmentCenter = () => css`
  justify-content: center;
`;

const modAlignment = (alignment) =>
  ({
    [TABLE_ALIGNMENT.LEFT]: modAlignmentLeft,
    [TABLE_ALIGNMENT.RIGHT]: modAlignmentRight,
    [TABLE_ALIGNMENT.CENTER]: modAlignmentCenter,
  }[alignment]);

function TableComponent({
  children,
  columns,
  sorting: { onSort, field: currentField, direction: currentDirection } = {},
  pagination,
  loading,
  adjustHeightToScreen,
  ...rest
}) {
  const containerElement = useRef();

  useAdjustHeightToScreen(adjustHeightToScreen, containerElement);

  return (
    <Wrapper {...rest}>
      <TableContainer ref={containerElement}>
        <Table columns={columns}>
          <Header sticky={adjustHeightToScreen}>
            <Row>
              {columns &&
                columns.map(
                  ({
                    id,
                    sortingField,
                    content,
                    alignment,
                    maxWidth,
                    fullWidth,
                    CellWrapper,
                  }) => {
                    const sortingDirection = chooseSortingDirection({
                      currentField,
                      currentDirection,
                      sortingField,
                    });
                    const onClick = () => {
                      if (sortingField == null) {
                        return;
                      }

                      onSort({ field: sortingField });
                    };

                    return !CellWrapper ? (
                      <ColumnTitle
                        key={id}
                        alignment={alignment}
                        sortingDirection={sortingDirection}
                        onClick={onClick}
                        maxWidth={maxWidth}
                        fullWidth={fullWidth}
                      >
                        {content}
                      </ColumnTitle>
                    ) : (
                      cloneElement(CellWrapper({ id, content }), { key: id })
                    );
                  }
                )}
            </Row>
          </Header>
          <tbody>
            {loading ? (
              <LoaderWrapper>
                <td colSpan={columns.length}>
                  <Loader />
                </td>
              </LoaderWrapper>
            ) : (
              children
            )}
          </tbody>
        </Table>
      </TableContainer>
      {pagination}
    </Wrapper>
  );
}

function modDefaultVariant() {
  return css`
    ${Row} {
      ${Cell} {
        ${spacing.inset(SPACING_SIZE.M)}
      }

      ${ColumnTitle} {
        ${spacing.insetSquish(SPACING_SIZE.M)}
      }
    }
  `;
}

function modSmallVariant() {
  return css`
    ${Row} {
      ${Cell} {
        ${spacing.inset(10)}
      }

      ${ColumnTitle} {
        padding: 10px ${SPACING_SIZE.S}px;
      }
    }
  `;
}

const modVariant = (variantName) =>
  ({
    [TABLE_VARIANT.DEFAULT]: modDefaultVariant,
    [TABLE_VARIANT.SMALL]: modSmallVariant,
  }[variantName]);

function modDefaultRowSeparation() {
  return css`
    ${Row} {
      ${divider.bottom(DIVIDER_SIZE.THIN)}
    }
  `;
}

function modEvenAndOddRowSeparation() {
  return css`
    ${Row}:nth-child(even) {
      ${Cell} {
        ${color.background(COLOR_PALETTE.NEUTRAL_A03)}
      }

      ${KpiCell} {
        ${color.background(COLOR_PALETTE.PRIMARY_A20)}
      }

      &:hover {
        ${Cell} {
          ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
        }

        ${KpiCell} {
          ${color.background(COLOR_PALETTE.PRIMARY_A40)}
        }
      }
    }

    tbody ${Row}:last-of-type {
      ${divider.bottom(DIVIDER_SIZE.THIN)}
    }
  `;
}

const modRowSeparation = (rowSeparation) =>
  ({
    [TABLE_ROW_SEPARATION.DEFAULT]: modDefaultRowSeparation,
    [TABLE_ROW_SEPARATION.EVEN_AND_ODD]: modEvenAndOddRowSeparation,
  }[rowSeparation]);

TableComponent.mods = {
  variant: modVariant,
  rowSeparation: modRowSeparation,
};

export default styled(TableComponent)`
  ${modDefaultVariant()}
  ${({ $rowSeparation }) =>
    modRowSeparation($rowSeparation ?? TABLE_ROW_SEPARATION.DEFAULT)}
  ${({ $fixedColumnsLeft }) => fixColumnsLeft($fixedColumnsLeft)}
  ${({ $fixedColumnsRight }) => fixColumnsRight($fixedColumnsRight)}
  
  ${TableContainer} {
    ${({ $fixedColumnsLeft, $fixedColumnRight }) =>
      $fixedColumnsLeft || $fixedColumnRight
        ? css`
            overflow-x: auto;
          `
        : null}
  }
`;

function fixColumnsLeft(fixedColumns) {
  if (fixedColumns?.length == null || fixedColumns.length <= 0) {
    return null;
  }

  return css`
    ${Table} {
      ${Row} {
        ${Cell}, ${ColumnTitle} {
          &:nth-of-type(-n + ${fixedColumns.length}) {
            z-index: 1;
            position: sticky;
          }

          &:nth-of-type(${fixedColumns.length}) {
            box-shadow: inset -1px 0 0 ${COLOR_PALETTE.NEUTRAL_A10};
          }

          ${fixedColumns.map(
            ({ width }, index) => css`
              &:nth-of-type(${index + 1}) {
                min-width: ${width}px;
                max-width: ${width}px;
                left: ${calculateColumnSpacing(index, fixedColumns)}px;
              }
            `
          )}
        }
      }
    }
  `;
}

function fixColumnsRight(fixedColumns) {
  if (fixedColumns?.length == null || fixedColumns.length <= 0) {
    return null;
  }

  return css`
    ${Table} {
      ${Row} {
        ${Cell}, ${ColumnTitle} {
          &:nth-last-of-type(-n + ${fixedColumns.length}) {
            z-index: 1;
            position: sticky;
          }

          &:nth-last-of-type(${fixedColumns.length}) {
            box-shadow: inset 1px 0 0 ${COLOR_PALETTE.NEUTRAL_A10};
          }

          ${fixedColumns.reverse().map(
            ({ width }, index) => css`
              &:nth-last-of-type(${index + 1}) {
                min-width: ${width}px;
                max-width: ${width}px;
                right: ${calculateColumnSpacing(index, fixedColumns) - 1}px;
              }
            `
          )}
        }
      }
    }
  `;
}

function alignColumn({ alignment }, index) {
  return css`
    ${ColumnTitle}, ${Cell} {
      &:nth-child(${index + 1}) > div {
        ${modAlignment(alignment)};
      }
    }

    ${CellLink} {
      &:nth-child(${index + 1}) > a {
        ${modAlignment(alignment)};
      }
    }
  `;
}

function chooseSortingDirection({
  currentField,
  currentDirection,
  sortingField,
}) {
  if (sortingField == null) {
    return COLUMN_SORTING.NOT_SORTABLE;
  }

  return currentField === sortingField
    ? currentDirection
    : COLUMN_SORTING.INDETERMINATE;
}

function calculateColumnSpacing(index, fixedColumns) {
  return index === 0
    ? 0
    : fixedColumns
        .slice(0, index)
        .reduce((totalWidth, { width }) => totalWidth + width, 0);
}

function useAdjustHeightToScreen(adjustHeightToScreen, containerElement) {
  const { height } = useWindowSize();

  useEffect(() => {
    if (adjustHeightToScreen && containerElement.current) {
      const adjustedHeight =
        height - containerElement.current.getBoundingClientRect().y;
      containerElement.current.style.height = `${adjustedHeight - 88}px`;
      containerElement.current.style.overflowY = 'auto';
    }
  }, [adjustHeightToScreen, containerElement.current, height]);
}
