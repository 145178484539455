import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import Grid from 'design-system/components/Grid';
import { OutlineButton } from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { COLOR_PALETTE, COLOR_SEMANTIC } from 'design-system/styles/color';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import ActionCard from '../../../../partials/ActionCard';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { helpCenterLinksSelector } from '../../../../selectors/config';
import LinkButton from '../LinkButton';
import { isPublisherFromMxSelector } from '../../../../selectors/publisher';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  align-items: end;
  justify-content: space-between;

  ${spacing.stack(SPACING_SIZE.S, false)}
`;

const GuideLinks = styled.div``;

const GuideLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  border-bottom: 1px solid ${COLOR_SEMANTIC.DIVIDER};
  ${spacing.inset(SPACING_SIZE.M)};
`;

function HelpCenter() {
  const links = useSelector(helpCenterLinksSelector);
  const isMXPublisher = useSelector(isPublisherFromMxSelector);

  return (
    <Wrapper>
      <Header>
        <Overline>
          <FormattedMessageWithValidation id="overview_help_center_section_title" />
        </Overline>
      </Header>
      <Grid
        areas={({ repeat }) => `
          "${repeat(8, 'helpCenterLinks')} ${repeat(4, 'cta')}"
        `}
        style={{
          alignItems: 'end',
        }}
      >
        <Grid.Area $id="helpCenterLinks">
          <GuideLinks>
            <GuideLink target="_blank" href={links.advice}>
              <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
                <FormattedMessageWithValidation id="overview_help_center_link1" />
              </Body1>
              <LinkButton />
            </GuideLink>
            {!isMXPublisher && (
              <GuideLink target="_blank" href={links.howToPromote}>
                <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
                  <FormattedMessageWithValidation id="overview_help_center_link2" />
                </Body1>
                <LinkButton />
              </GuideLink>
            )}
            <GuideLink target="_blank" href={links.faq}>
              <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
                <FormattedMessageWithValidation id="overview_help_center_link3" />
              </Body1>
              <LinkButton />
            </GuideLink>
          </GuideLinks>
        </Grid.Area>
        <Grid.Area $id="cta" data-stonly="helpcenter">
          <ActionCard
            illustration={<Illustration name="helpCenter" />}
            title={
              <FormattedMessageWithValidation id="overview_help_center_cta_title" />
            }
            cta={
              <OutlineButton
                $size={BUTTON_SIZE.SMALL}
                forwardedAs="a"
                href={links.home}
                target="_blank"
              >
                <FormattedMessageWithValidation id="overview_help_center_cta_link" />
              </OutlineButton>
            }
          />
        </Grid.Area>
      </Grid>
    </Wrapper>
  );
}

export default HelpCenter;
