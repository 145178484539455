import { CHANGE_REQUEST_STATUS } from '../../../../../constants/subscription';

function textForPendingScr() {
  return 'Pending to be paid';
}

function textForPaidScr(billingStatus) {
  if (billingStatus === 'conciliated') {
    return 'Upcoming subscription already paid';
  }
  if (billingStatus === 'not-informed') {
    return 'Paid, pending to be conciliated';
  }
  return 'Invoiced, pending to be conciliated';
}

function textForActivatedScr(billingStatus) {
  if (billingStatus === 'billed') {
    return 'Invoiced, pending to be conciliated';
  }
  return 'Activated, pending to be conciliated';
}

export function getSubscriptionChangeRequestLinkText({
  hasActiveFirstPaymentCheckout,
  status,
  billingStatus,
}) {
  if (hasActiveFirstPaymentCheckout) {
    return 'First subscription requested';
  }
  if (status === CHANGE_REQUEST_STATUS.PENDING) {
    return textForPendingScr();
  }
  if (status === CHANGE_REQUEST_STATUS.PAID) {
    return textForPaidScr(billingStatus);
  }
  return textForActivatedScr(billingStatus);
}
