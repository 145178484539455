import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { FormattedNumber } from 'react-intl';
import Grid from 'design-system/components/Grid';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import PublishFirstPropertyDisclaimer from './PublishFirstPropertyDisclaimer';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import Kpi from '../../../../partials/Kpi';
import ImpressionsChart from './ImpressionsChart';
import {
  isPropertiesSummaryLoading,
  kpisCardsDataSelector,
  publisherHasAnyCreatedAdSelector,
  selectedDateRangeSelector,
} from '../../selectors';
import { KPIS_DATA_REQUESTED } from '../../events';
import ViewsKpi from './MainKpi';
import DateRangeFilter from '../DateRangeFilter';
import { LeadCounter } from './LeadCounter';
import { showStatsIssuesBannerSelector } from '../../../../selectors/config';
import StatsIssuesDisclaimer from './StatsIssuesDisclaimer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ImpressionsChart} {
    max-height: 172px;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${spacing.stack(SPACING_SIZE.M, false)}
`;

const KpisColumn = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;
function KeyResults() {
  const kpis = useSelector(kpisCardsDataSelector);
  const selectedDate = useSelector(selectedDateRangeSelector);
  const isLoading = useSelector(isPropertiesSummaryLoading);
  const hasAnyPropertyCreated = useSelector(publisherHasAnyCreatedAdSelector);
  const showStatsIssuesBanner = useSelector(showStatsIssuesBannerSelector);

  return (
    <Wrapper>
      <Header>
        <Overline>
          <FormattedMessageWithValidation id="overview_key_results_section_title" />
        </Overline>
        <DateRangeFilter
          selectedRange={selectedDate}
          onRangeSelected={requestKpisData}
        />
      </Header>

      {kpis && (
        <Grid
          areas={({ repeat }) => `
          "${repeat(2, 'views')} ${repeat(6, 'impressions')} ${repeat(
            4,
            'visitsAndEnquiries'
          )}"
        `}
        >
          <Grid.Area $id="views">
            <KpisColumn>
              <ViewsKpi
                amount={<FormattedNumber value={kpis.impressions} />}
                message={
                  <FormattedMessageWithValidation id="overview_kpis_views" />
                }
              />
            </KpisColumn>
          </Grid.Area>
          <Grid.Area $id="impressions">
            <ImpressionsChart data-stonly="overview-stats" $height="100%" />
          </Grid.Area>
          <Grid.Area $id="visitsAndEnquiries">
            <KpisColumn>
              <Kpi
                amount={<FormattedNumber value={kpis.visits} />}
                message={
                  <FormattedMessageWithValidation id="overview_kpis_visits" />
                }
              />
              <Kpi amount={<FormattedNumber value={kpis.applies} />}>
                <LeadCounter value={kpis.leads} />
              </Kpi>
            </KpisColumn>
          </Grid.Area>
        </Grid>
      )}
      {!isLoading && !hasAnyPropertyCreated && (
        <PublishFirstPropertyDisclaimer />
      )}
      {!isLoading && hasAnyPropertyCreated && showStatsIssuesBanner && (
        <StatsIssuesDisclaimer />
      )}
    </Wrapper>
  );
}

function requestKpisData({ startDate, endDate }) {
  dispatch({
    id: KPIS_DATA_REQUESTED,
    payload: {
      period: {
        startDate,
        endDate,
      },
    },
  });
}

export default KeyResults;
