import {
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../../../../selectors/user';

export function canEditListingRulesSelector(state) {
  return userIsSuperadminSelector(state) || userIsSupportSelector(state);
}

export function brandsToPublishNonBoostedListingsToSelector(state) {
  return state?.brandsToPublishNonBoostedListingsTo;
}
