import { Link } from 'react-router-dom';

import { OutlinePrimaryButton } from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';

import TabLink from '../TabLink';
import UserMenu from '../UserMenu';
import useTracking from '../../../hooks/useTracking';
import { newUnitForm } from '../../../utils/proppitWebRouter';
import { SubscriptionTabLink } from './SubscriptionTabLink';
import { CompanyProfileTabLink } from './CompanyProfileTabLink';
import { OverviewTabLink } from './OverviewTabLink';
import { ReloadingUnreadLeads } from './ReloadingUnreadLeads';
import { InboxTabLink } from './InboxTabLink';

function Navigation() {
  const createPropertyTrackingRef = useTracking({
    trackClick: {
      eventName: 'create-new-unit',
    },
    trackImpression: {
      elementName: 'new-unit-btn',
      payload: { visible: true },
    },
    sectionName: 'side-menu-top-new-unit-button',
  });
  return [
    <OutlinePrimaryButton
      forwardedAs={Link}
      to={newUnitForm()}
      ref={createPropertyTrackingRef}
    >
      <FormattedMessageWithValidation id="menu_cta_new_unit" />
    </OutlinePrimaryButton>,
    <OverviewTabLink />,
    <TabLink
      to="/projects"
      deep
      eventName="view-projects-page"
      elementName="projects-link"
      sectionName="side-menu-top-projects-link"
    >
      <FormattedMessageWithValidation id="menu_projects" />
    </TabLink>,
    <TabLink
      to="/units"
      deep
      eventName="view-units-page"
      elementName="units-link"
      sectionName="side-menu-top-units-link"
    >
      <FormattedMessageWithValidation id="menu_units" />
    </TabLink>,
    <InboxTabLink />,
    <CompanyProfileTabLink>
      <FormattedMessageWithValidation id="menu_company_profile_developers" />
    </CompanyProfileTabLink>,
    <SubscriptionTabLink>
      <FormattedMessageWithValidation id="menu_subscription_developers" />
    </SubscriptionTabLink>,
    <UserMenu />,
  ].map((component, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <ReloadingUnreadLeads key={i}>{component}</ReloadingUnreadLeads>
  ));
}

export default Navigation;
