export const TRANSLATION_NAMES = {
  BANK_TRANSFER_NOTICE: 'bankTransferNotice',
  CONFIRMATION_CAPTION: 'confirmationCaption',
  PAY_BY_LINK_CONFIRMATION: 'payByLinkConfirmation',
  CONFIRMATION_TITLE: 'confirmationTitle',
  PAGE_TITLE: 'pageTitle',
};

export const UPSELL_SUBROUTE = 'upsell';
export const FIRST_PAYMENT_SUBROUTE = 'first-payment';
