import { Children } from 'react';
import styled, { css } from 'styled-components';

import { layout } from 'design-system/tokens/layout';
import Header from './Header';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import Content from './Content';
import Padded from './Padded';
import Footer from './Footer';

const LAYOUT_ARRANGEMENT = {
  CONTENT_ONLY: Symbol('LAYOUT_ARRANGEMENT_CONTENT_ONLY'),
  LEFT_SIDEBAR: Symbol('LAYOUT_ARRANGEMENT_LEFT_SIDEBAR'),
  RIGHT_SIDEBAR: Symbol('LAYOUT_ARRANGEMENT_RIGHT_SIDEBAR'),
  BOTH_SIDEBARS: Symbol('LAYOUT_ARRANGEMENT_BOTH_SIDEBARS'),
};

function hasChildOfType(children, type) {
  return Children.toArray(children).find((child) => child.type === type);
}

function getArrangementFromChildren(children) {
  const hasLeftSidebar = hasChildOfType(children, LeftSidebar);
  const hasRightSidebar = hasChildOfType(children, RightSidebar);

  if (hasLeftSidebar && hasRightSidebar) {
    return LAYOUT_ARRANGEMENT.BOTH_SIDEBARS;
  }

  if (hasLeftSidebar) {
    return LAYOUT_ARRANGEMENT.LEFT_SIDEBAR;
  }

  if (hasRightSidebar) {
    return LAYOUT_ARRANGEMENT.RIGHT_SIDEBAR;
  }

  return LAYOUT_ARRANGEMENT.CONTENT_ONLY;
}

const modIndependentScroll = () => css`
  height: 100vh;

  ${LeftSidebar},
  ${Content},
    ${RightSidebar} {
    overflow-y: auto;
  }

  ${Footer} {
    width: calc(100%);
    max-width: calc(100vw - 1em);
  }
`;

const modArrangementContentOnly =
  ({ hasFooter }) =>
  () =>
    css`
      --layout-columns: ${layout.colums.fullWidth};

      grid-template-areas:
        'header'
        'content'
        ${hasFooter && '"footer"'};

      ${Padded} {
        min-width: var(--layout-min-width);
        max-width: var(--layout-max-width);
        margin: 0 auto;
      }

      ${LeftSidebar},
      ${RightSidebar} {
        display: none;
      }
    `;

const modArrangementLeftSidebar =
  ({ hasFooter }) =>
  () =>
    css`
      --layout-columns: ${layout.colums.oneSidebar};

      grid-template-columns:
        minmax(var(--layout-sidebar-width), auto)
        minmax(
          calc(var(--layout-min-width) - var(--layout-sidebar-width)),
          calc(var(--layout-max-width) - var(--layout-sidebar-width))
        )
        auto;

      grid-template-areas:
        'header header header'
        'sidebar_left content .'
        ${hasFooter && '"footer footer footer"'};

      ${RightSidebar} {
        display: none;
      }
    `;

const modArrangementRightSidebar =
  ({ hasFooter }) =>
  () =>
    css`
      --layout-columns: ${layout.colums.oneSidebar};

      grid-template-columns:
        auto
        minmax(
          calc(var(--layout-min-width) - var(--layout-sidebar-width)),
          calc(var(--layout-max-width) - var(--layout-sidebar-width))
        )
        minmax(var(--layout-sidebar-width), auto);

      grid-template-areas:
        'header header header'
        '. content sidebar_right'
        ${hasFooter && '"footer footer footer"'};

      ${LeftSidebar} {
        display: none;
      }
    `;

const modArrangementBothSidebars =
  ({ hasFooter }) =>
  () =>
    css`
      --layout-columns: ${layout.colums.twoSidebars};

      grid-template-columns:
        minmax(var(--layout-sidebar-width), auto)
        minmax(
          calc(var(--layout-min-width) - (var(--layout-sidebar-width) * 2)),
          calc(var(--layout-max-width) - (var(--layout-sidebar-width) * 2))
        )
        minmax(var(--layout-sidebar-width), auto);

      grid-template-areas:
        'header header header'
        'sidebar_left content sidebar_right'
        ${hasFooter && '"footer footer footer"'};
    `;

const modArrangement = (arrangement) =>
  ({
    [LAYOUT_ARRANGEMENT.CONTENT_ONLY]: modArrangementContentOnly,
    [LAYOUT_ARRANGEMENT.LEFT_SIDEBAR]: modArrangementLeftSidebar,
    [LAYOUT_ARRANGEMENT.RIGHT_SIDEBAR]: modArrangementRightSidebar,
    [LAYOUT_ARRANGEMENT.BOTH_SIDEBARS]: modArrangementBothSidebars,
  }[arrangement]);

const Layout = styled.main.attrs(({ children }) => ({
  arrangement: getArrangementFromChildren(children),
  hasFooter: hasChildOfType(children, Footer),
}))`
  --layout-sidebar-width: ${layout.sidebarWidth}px;
  --layout-min-width: ${layout.minWidth}px;
  --layout-max-width: ${layout.maxWidth}px;
  --layout-gutter: ${layout.gutter}px;

  display: grid;

  grid-template-rows: min-content;

  width: 100%;
  min-height: 100vh;

  ${({ independentScroll }) => independentScroll && modIndependentScroll()}
  ${({ arrangement, hasFooter }) => modArrangement(arrangement)({ hasFooter })}
`;

Layout.Header = Header;
Layout.Content = Content;
Layout.LeftSidebar = LeftSidebar;
Layout.RightSidebar = RightSidebar;
Layout.Padded = Padded;
Layout.Footer = Footer;

export default Layout;
