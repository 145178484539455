import { INVOICES_TAB } from './partials/BillingSwitch/events';
import {
  hasSubscriptionChangeRequestForSuperboostSelector,
  publisherHasSuperboostSubscriptionSelector,
} from '../../selectors/publisher';

export function subscriptionPageShowInvoicesTabSelector(state) {
  return state['subscriptionPage:showTab'] === INVOICES_TAB;
}

export function showSuperboostBannerSelector(state) {
  return (
    publisherHasSuperboostSubscriptionSelector(state) ||
    hasSubscriptionChangeRequestForSuperboostSelector(state)
  );
}

export function pendingSCRsSelector(state) {
  return state.publisher?.pendingSCRs ?? [];
}
