import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import {
  isLoadingQualificationSelector,
  qualificationSelector,
} from './selectors';
import VisitInterest from './partials/VisitInterest';
import Budget from './partials/Budget';
import InfoRequired from './partials/InfoRequired';
import Region from './partials/Region';
import PreferredChannel from './partials/PreferredChannel';
import useTracking from '../../../../../../hooks/useTracking';

const Wrapper = styled.div`
  ${spacing.inset(SPACING_SIZE.XL)}
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
  word-break: break-word;
`;

export default function Qualification() {
  const isLoading = useSelector(isLoadingQualificationSelector);
  const { visitInterest, budget, infoRequired, region, preferredChannel } =
    useSelector(qualificationSelector);
  const trackImpression = useTracking({
    trackImpression: {
      elementName: 'lead-qualification',
    },
    sectionName: 'lead-qualification-section',
  });

  if (isLoading) {
    return null;
  }

  return (
    <Wrapper ref={trackImpression}>
      {visitInterest && (
        <VisitInterest
          question={visitInterest.question}
          answer={visitInterest.answers[0]}
        />
      )}
      {budget && <Budget question={budget.question} answers={budget.answers} />}
      {infoRequired && (
        <InfoRequired
          question={infoRequired.question}
          answers={infoRequired.answers}
        />
      )}
      {region && (
        <Region question={region.question} answer={region.answers[0]} />
      )}
      {preferredChannel && (
        <PreferredChannel
          question={preferredChannel.question}
          answers={preferredChannel.answers}
        />
      )}
    </Wrapper>
  );
}
