import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withStylesAndInnerRef } from 'design-system/utils';
import InputBase from 'design-system/components/InputBase/InputBase';
import InputLabel from 'design-system/components/InputLabel';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import { COLOR_PALETTE } from 'design-system/styles/color';
import DialCodeSelect from './DialCodeSelect';
import choices from './collection';
import { useInputBase } from '../../hooks/useInputBase';

const Wrapper = styled(InputBase)`
  ${InputBase.Box} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing.value(SPACING_SIZE.XS)};

    input {
      ${spacing.inline(SPACING_SIZE.NONE)}
    }

    ${Subtitle2} {
      user-select: none;
      text-align: left;
      max-width: 5ex;
    }
  }
`;

const Inputs = styled.div`
  display: flex;
  align-items: flex-start;

  ${spacing.stack(SPACING_SIZE.S)}

  ${InputBase.Box} {
    flex: 1;
  }
`;

function InputPhone({
  Component = 'input',
  innerRef,
  label,
  value,
  defaultCountryCode,
  'data-test': dataTest,
  ...props
}) {
  const initialCountryItem = findItemByCountryCode(
    choices,
    value?.countryCode ?? defaultCountryCode
  );
  const [countryItem, setCountryItem] = useState(initialCountryItem);
  const [phoneNumber, setPhoneNumber] = useState(value.phoneNumber);
  useEffect(() => {
    setCountryItem(
      findItemByCountryCode(choices, value?.countryCode ?? defaultCountryCode)
    );
    setPhoneNumber(value.phoneNumber);
  }, [value]);

  useEffect(() => {
    props.onChange({
      phoneNumber,
      dialCode: countryItem?.value,
      countryCode: countryItem?.id,
    });
  }, [countryItem?.id, countryItem?.value, phoneNumber]);

  const { inputBaseProps, boxProps, inputProps } = useInputBase(props);

  const { error, required } = inputBaseProps;

  return (
    <Wrapper {...inputBaseProps} data-test={dataTest}>
      <InputLabel error={error} required={required} disabled={props.disabled}>
        {label}
      </InputLabel>
      <Inputs>
        <DialCodeSelect
          error={error}
          choices={choices}
          onSelect={setCountryItem}
          value={findItemByCountryCode(choices, countryItem?.id)}
          defaultSelected={findItemByCountryCode(choices, defaultCountryCode)}
          disabled={props.disabled}
        />
        <InputBase.Box {...boxProps}>
          <Subtitle2 $color={COLOR_PALETTE.NEUTRAL_A40}>
            {countryItem?.value}
          </Subtitle2>
          <Component
            type="text"
            ref={innerRef}
            {...inputProps}
            value={phoneNumber}
            onChange={(e) =>
              setPhoneNumber(e.target.rawValue ?? e.target.value)
            }
          />
        </InputBase.Box>
      </Inputs>
    </Wrapper>
  );
}

function findItemByCountryCode(items, countryCode) {
  return items.find((item) => item.id === countryCode);
}

export default withStylesAndInnerRef(InputPhone);
