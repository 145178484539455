import { subscribe } from 'reffects-store';
import { hasPendingSubscriptionChangeRequestSelector } from './selectors';
import CurrentPlanBanner from './CurrentPlanBanner';
import { activeSubscriptionSelector } from '../../../../selectors/publisher';

export default subscribe(CurrentPlanBanner, (state) => ({
  subscription: activeSubscriptionSelector(state),
  hasPendingSubscriptionChangeRequest:
    hasPendingSubscriptionChangeRequestSelector(state),
}));
