import styled from 'styled-components';
import { useEffect } from 'react';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { PAYMENT_IFRAME_EVENT_RECEIVED } from './events';

const GatewayIframe = styled.iframe`
  width: 100%;
  border: none;
`;

export default function PaymentIframe({
  src,
  onSuccessEvent,
  onBackEvent,
  onCheckoutFailed,
}) {
  useEffect(() => {
    const messageListener = (event) => {
      dispatch({
        id: PAYMENT_IFRAME_EVENT_RECEIVED,
        payload: {
          id: event?.data.id,
          payload: event?.data.payload,
          onSuccessEvent,
          onBackEvent,
          onCheckoutFailed,
        },
      });
    };

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  });

  const height = useSelector((state) => state['paymentsIframe:height'] ?? null);

  return (
    <>
      <GatewayIframe scrolling="no" height={height} src={src} />
    </>
  );
}
