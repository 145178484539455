import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { ROWS_PER_PAGE } from 'design-system/components/Table';
import Layout from 'design-system/components/Layout';
import Illustration from 'design-system/components/Illustration/Illustration';
import EmptyState from 'design-system/components/EmptyState';
import { COLLECTION_NAME } from './partials/LeadsTable/AgencyLeadsTable/constants';
import { createAdapter } from './partials/LeadsTable/tableAdapter';
import { DataTableProvider } from '../../partials/DataTable';
import { useDataTable } from '../../partials/DataTable/hooks/useDataTable';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import ExportOptions from './partials/ExportOptions/ExportOptions';
import AgencyLeadsTable from './partials/LeadsTable/AgencyLeadsTable/AgencyLeadsTable';
import { mapAgencyLeads } from './partials/LeadsTable/responseMapper';
import { lastMomentOfDay, threeMonthsAgo, toUTCDate } from '../../utils/dates';
import {
  BANNERS,
  showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector,
} from '../../selectors/banners';
import LeadQualityAwareness from '../../partials/LeadQualityAwareness/LeadQualityAwareness';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${spacing.stack(SPACING_SIZE.M)}
`;

const Actions = styled.div`
  & > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const LeadQualityBannerSpaced = styled(LeadQualityAwareness)`
  ${spacing.stack(SPACING_SIZE.M)}
`;

const now = new Date();
const context = {
  collectionName: COLLECTION_NAME,
  defaultSorting: {
    field: 'lastActivity',
    direction: 'desc',
  },
  defaultFilters: {
    lastActivityDateRange: {
      id: 'filter_last_3_month',
      startDate: toUTCDate(threeMonthsAgo(now)).toISOString(),
      endDate: toUTCDate(lastMomentOfDay(now)).toISOString(),
    },
  },
  initialItemsPerPage: ROWS_PER_PAGE.TWENTY_FIVE,
  adapter: createAdapter(COLLECTION_NAME, mapAgencyLeads),
};

export default function AgencyLeads() {
  return (
    <DataTableProvider {...context}>
      <Content />
    </DataTableProvider>
  );
}

function Content() {
  const { isEmpty } = useDataTable();
  const banner = useSelector((state) =>
    showNotUsingYourWholePlanBannerOrLeadQualityAwarenessSelector(
      state,
      new Date()
    )
  );
  if (isEmpty) {
    return (
      <>
        <Layout.Padded>
          <Heading1>
            <FormattedMessageWithValidation id="menu_inbox" />
          </Heading1>
        </Layout.Padded>
        <EmptyState
          fullScreen
          illustration={<Illustration name="inboxEnvelope" />}
          title={<FormattedMessageWithValidation id="enquiries_empty_title" />}
          description={
            <FormattedMessageWithValidation id="enquiries_empty_body" />
          }
        />
      </>
    );
  }

  return (
    <Layout.Padded>
      <Header>
        <Heading1>
          <FormattedMessageWithValidation id="menu_inbox" />
        </Heading1>
        <Actions>
          <ExportOptions />
        </Actions>
      </Header>
      {banner === BANNERS.leadQualityAwareness && (
        <LeadQualityBannerSpaced section="inbox" />
      )}
      <AgencyLeadsTable />
    </Layout.Padded>
  );
}
