import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import ContactCard from 'design-system/components/ContactCard/ContactCard';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import Button, { BUTTON_SIZE } from 'design-system/components/Button';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  hasLeadPlusSelector,
  hasQualificationSelector,
  isLoadingSelector,
  leadSelector,
} from '../../../../selectors';
import VerifiableDatum from './VerifiableDatum';
import QrModal from './QrModal';
import { OPEN_DIALOG } from '../../../../../../events/dialogs';
import { QR_MODAL } from './constants';
import ContactQr from '../../../ContactQr';
import VerifiableEmail from './VerifiableEmail';
import { useFeatureSelector } from '../../../../../../utils/featuresFlags/selectors';

const Wrapper = styled(ContactCard)`
  width: 100%;
`;

const QrButton = styled(Button).attrs({ icon: <Icon glyph="qr" /> })`
  align-self: flex-end;

  ${Button.mods.size(BUTTON_SIZE.LARGE)}
  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.BASE)}
  }
`;

const LeadName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

export default function LeadContact() {
  const isLoading = useSelector(isLoadingSelector);
  const lead = useSelector(leadSelector);
  if (isLoading) {
    return <ContactCard.Skeleton qr={<ContactQr.Skeleton />} $width={210} />;
  }

  return (
    <Wrapper
      name={
        <LeadName>
          <span>{lead.name}</span>
          <LeadTag />
        </LeadName>
      }
    >
      {lead.countryCode && (
        <ContactCard.Datum glyph="location">
          <FormattedMessageWithValidation
            id={`country_${lead.countryCode.toLowerCase()}`}
          />
        </ContactCard.Datum>
      )}
      {lead.emails?.map((e) => (
        <VerifiableEmail
          value={e.address}
          glyph="email"
          key={e.address}
          isVerified={e.isVerified}
        />
      ))}
      {lead.phones?.map((p) => (
        <VerifiableDatum
          value={p.number}
          isVerified={p.isVerified}
          glyph="phone"
          key={p.number}
          link={`tel:${p.number}`}
        />
      ))}
      <QrButton
        onClick={() =>
          dispatch({
            id: OPEN_DIALOG,
            payload: {
              id: QR_MODAL,
              parameters: {
                name: lead.name,
                phone: lead.phones[0]?.number,
                email: lead.emails[0]?.address,
              },
            },
          })
        }
      />
      <QrModal />
    </Wrapper>
  );
}

function LeadTag() {
  const hasLeadPlus = useSelector(hasLeadPlusSelector);
  const hasQualification = useSelector(hasQualificationSelector);
  const DEVELOPERS_QUALIFICATION_WEB_3899 = useFeatureSelector(
    'DEVELOPERS_QUALIFICATION_WEB_3899'
  );

  return (
    <>
      {hasLeadPlus && (
        <Tag variant={TAG_VARIANT.GOLD}>
          <FormattedMessageWithValidation id="leadpage_contact_lead_plus" />
        </Tag>
      )}
      {DEVELOPERS_QUALIFICATION_WEB_3899 && hasQualification && (
        <Tag variant={TAG_VARIANT.GOLD}>
          <FormattedMessageWithValidation id="leadpage_contact_qualification" />
        </Tag>
      )}
    </>
  );
}
