import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../selectors/user';
import { homeRoute } from '../../../../utils/proppitWebRouter';
import { PATHS } from '../../../constants/routes';
import { BrandLogo } from '../../../../partials/BrandLogo';
import HeaderProfileLink from './HeaderProfileLink';

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--white-color);
  color: var(--white-color);
  padding: 1.2rem 1.5rem 1.2rem 1.5rem;
  border-bottom: 1px solid var(--divider-color);
  display: flex;
  align-items: center;
  min-width: 1200px;

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const Menu = styled.nav`
  flex: 1;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;

    li {
      ${spacing.inline(SPACING_SIZE.XS)}
      ${spacing.inset(SPACING_SIZE.S)}
      cursor: pointer;
      border-radius: ${SPACING_SIZE.XS};
      &:first-child {
        margin: 0;
      }
      &:hover {
        background-color: var(--gray-color-04);
      }
      a {
        text-decoration: none;
        color: var(--black-color);
      }
    }
  }
`;

function Header() {
  const showCampaignsLink = useSelector(
    oneOf(
      userIsSuperadminSelector,
      userIsSupportSelector,
      userIsFinanceSelector,
      userIsFinanceManagerSelector
    )
  );
  const showPricingTableLink = useSelector(userIsSuperadminSelector);

  return (
    <Wrapper>
      <Link to={homeRoute()} className="Header__Link">
        <BrandLogo />
      </Link>
      <Menu>
        <ul>
          <li>
            <Link to="/admin/create-user" data-tag="Header_create_publisher">
              Create publisher
            </Link>
          </li>
          <li>
            <Link to="/admin/users" data-tag="Header_users_list">
              Users list
            </Link>
          </li>
          <li>
            <Link to={PATHS.AGENCIES} data-tag="Header_agencies">
              Agencies
            </Link>
          </li>
          <li>
            <Link to={PATHS.DEVELOPERS} data-tag="Header_developers">
              Developers
            </Link>
          </li>
          {showCampaignsLink && (
            <li>
              <Link to={PATHS.CAMPAIGNS} data-tag="Header_campaigns">
                Campaigns
              </Link>
            </li>
          )}
          {showPricingTableLink && (
            <li>
              <Link
                to="/admin/subscription-plans-management"
                data-tag="Header_pricing_table"
              >
                Pricing Table
              </Link>
            </li>
          )}
        </ul>
      </Menu>
      <HeaderProfileLink />
    </Wrapper>
  );
}

export default Header;
