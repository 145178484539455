import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { FormattedList, FormattedNumber } from 'react-intl';
import { dispatch } from 'reffects';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import Table from 'design-system/components/Table';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Tooltip from 'design-system/components/Tooltip';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import PropertySnippet from '../../../../partials/PropertySnippet';
import FormattedRelativeDate from '../../../../partials/FormattedDate/FormattedRelativeDate';
import { unitSitesLinkSelector } from './selectors';
import { UNITS_TABLE_BULK_CHECKBOX_CHANGED } from '../../events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { isUnitBulkSelectedSelector } from './BulkActionsMenu/selectors';
import { editUnitForm } from '../../../../utils/proppitWebRouter';
import FormattedDateTime from '../../../../partials/FormattedDate/FormattedDateTime';
import { getUnitSelector } from '../../../../selectors/units';
import {
  ATTEMPT_UNPUBLISH_UNIT,
  PUBLISH_UNIT,
  UNPUBLISH_UNIT,
} from './events.publish';
import { useDialog } from '../../../../hooks/useDialog';
import { siteWherePublishingNamesSelector } from '../../../../selectors/config';
import { DELETE_UNIT } from './events.delete';
import useTracking from '../../../../hooks/useTracking';
import PropertyOperationPrice from './PropertyOperationPrice';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { UNIT_PUBLICATION_DISABLED } from '../constants';
import DropdownLinksMenu from '../../../../partials/DropdownSitesMenu';
import { isPublicationEnabledForProjectSelector } from '../../../publication/selectors';
import { GENERIC_CONFIRMATION_MODAL } from '../../../../partials/modals/generic/constants';

const NoWrapText = styled(Body1).attrs({
  $weight: FONT_WEIGHT.LIGHT,
  $ellipse: true,
})`
  white-space: nowrap;
`;

const HiddenActions = styled.div`
  visibility: hidden;
`;

const KpiCell = styled(Table.CellLink)`
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TableRow = styled(Table.Row)`
  &:hover {
    ${KpiCell} {
      ${color.background(COLOR_PALETTE.PRIMARY_A20)}
    }

    ${HiddenActions} {
      visibility: visible;
    }
  }

  ${Tooltip} {
    justify-content: center;
  }
`;

const CheckboxCell = styled(Table.Cell)`
  padding-right: 0;
`;

function UnitRow({ id, ...rest }) {
  const { openDialog } = useDialog(GENERIC_CONFIRMATION_MODAL);

  const unit = useSelector((state) => getUnitSelector(state, id));

  const webs = useSelector(siteWherePublishingNamesSelector);

  const {
    creationDate,
    impressions,
    clicks,
    enquiries,
    bedrooms,
    bathrooms,
    project,
    referenceId,
    published,
    operations,
  } = unit;

  const sites = useSelector(unitSitesLinkSelector)({ id, isBoosted: false });
  const isPublicationEnabled = useSelector(
    isPublicationEnabledForProjectSelector
  )({
    projectId: project.id,
  });
  const isChecked = useSelector(isUnitBulkSelectedSelector)(id);

  const getPublishingTooltipText = () =>
    !published ? 'item_menu_publish_unit' : 'item_menu_unpublish_unit';

  const handleTogglePublication = useCallback(() => {
    if (!published) {
      if (!isPublicationEnabled) {
        dispatch({
          id: OPEN_DIALOG,
          payload: { id: UNIT_PUBLICATION_DISABLED },
        });
      } else {
        dispatch({
          id: PUBLISH_UNIT,
          payload: { unitId: id },
        });
      }
    } else {
      dispatch({
        id: ATTEMPT_UNPUBLISH_UNIT,
      });
      openDialog({
        messages: {
          title: {
            id: 'units_page_unpublish_confirm_dialog_title',
          },
          body: {
            id: 'units_page_unpublish_confirm_dialog_body',
            values: {
              sites: <FormattedList type="conjunction" value={webs} />,
            },
          },
        },
        actions: {
          confirm: {
            id: UNPUBLISH_UNIT,
            payload: { unitId: id },
          },
        },
      });
    }
  }, [published]);

  const handleDelete = useCallback(() => {
    openDialog({
      messages: {
        title: {
          id: 'modal_delete_unit_title',
        },
        body: {
          id: 'modal_delete_unit_body',
        },
        confirmButton: {
          id: 'modal_delete_unit_cta',
        },
      },
      actions: {
        confirm: {
          id: DELETE_UNIT,
          payload: { unitId: id },
        },
      },
    });
  }, []);

  return (
    <TableRow {...rest}>
      <CheckboxCell>
        <Checkbox
          checked={isChecked}
          onChange={() =>
            dispatch({
              id: UNITS_TABLE_BULK_CHECKBOX_CHANGED,
              payload: { unitId: id },
            })
          }
        />
      </CheckboxCell>
      <CellLink id={id} maxWidth={200}>
        <PropertySnippet
          image={project.image}
          title={project.name}
          address={project.address}
        />
      </CellLink>
      <CellLink id={id} maxWidth={200}>
        <NoWrapText>{referenceId}</NoWrapText>
      </CellLink>
      <CellLink id={id}>
        <NoWrapText>{bedrooms}</NoWrapText>
      </CellLink>
      <CellLink id={id}>
        <NoWrapText>{bathrooms}</NoWrapText>
      </CellLink>
      <CellLink id={id}>
        <PropertyOperationPrice operations={operations} />
      </CellLink>
      <KpiCellLink id={id} data-stonly="properties-stats">
        <NoWrapText>
          <FormattedNumber value={impressions} />
        </NoWrapText>
      </KpiCellLink>
      <KpiCellLink id={id} data-stonly="properties-stats">
        <NoWrapText>
          <FormattedNumber value={clicks} />
        </NoWrapText>
      </KpiCellLink>
      <KpiCellLink id={id} data-stonly="properties-stats">
        <NoWrapText>
          <FormattedNumber value={enquiries} />
        </NoWrapText>
      </KpiCellLink>
      <CellLink id={id}>
        <Tooltip
          title={
            <FormattedDateTime
              value={creationDate}
              dateStyle="full"
              timeStyle="short"
            />
          }
        >
          <NoWrapText>
            <FormattedRelativeDate
              date={new Date(creationDate)}
              now={new Date()}
            />
          </NoWrapText>
        </Tooltip>
      </CellLink>
      <Table.Cell>
        <Tooltip
          title={
            <FormattedMessageWithValidation id={getPublishingTooltipText()} />
          }
        >
          <FlatButton
            icon={<Icon glyph={published ? 'eye' : 'eyeCrossed'} />}
            onClick={handleTogglePublication}
            data-stonly="properties-publish"
          />
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <DropdownLinksMenu
          active={published}
          sites={sites}
          section="units-table-site-links"
          elementName="unit-site-link"
          eventName="click-unit-site-link"
        />
        <HiddenActions>
          <Tooltip
            title={
              <FormattedMessageWithValidation id="properties_list_remove_property" />
            }
          >
            <FlatButton icon={<Icon glyph="bin" />} onClick={handleDelete} />
          </Tooltip>
        </HiddenActions>
      </Table.Cell>
    </TableRow>
  );
}

function CellLink({ children, id, ...rest }) {
  const trackClickRef = useTracking({
    trackClick: {
      eventName: 'edit-unit',
    },
    sectionName: 'units-table-item',
  });
  return (
    <Table.CellLink
      forwardedAs={Link}
      to={editUnitForm(id)}
      ref={trackClickRef}
      {...rest}
    >
      {children}
    </Table.CellLink>
  );
}

function KpiCellLink({ children, id, ...rest }) {
  return (
    <KpiCell forwardedAs={Link} to={editUnitForm(id)} {...rest}>
      {children}
    </KpiCell>
  );
}

export default styled(UnitRow)``;
