import { registerEventHandler } from 'reffects';
import { CUSTOMER_ROLES } from '../../constants/user';
import { redirectTo } from '../../effects/routing';
import { homeRoute } from '../../utils/proppitWebRouter';

export const CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE =
  'CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE';

registerEventHandler(CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE, (_, { role }) =>
  role === CUSTOMER_ROLES.PUBLISHER_COLLABORATOR ? redirectTo(homeRoute()) : {}
);
