import { subscribe, useSelector } from 'reffects-store';
import styled from 'styled-components';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import Layout from 'design-system/components/Layout';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import ContactSupport from './partials/ContactSupport';
import CurrentPlanBanner from './partials/CurrentPlanBanner';
import PaymentInformation from './partials/PaymentInformation';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SubscriptionLayout from './partials/Layout/Layout';
import Header from './partials/Layout/Header';
import {
  changedBillingCycleInTHSelector,
  publisherSubscriptionPlanIsPaidSelector,
} from '../../selectors/publisher';
import { SubscriptionComparingTable } from './partials/SubscriptionTable';
import BillingSwitch from './partials/BillingSwitch/BillingSwitch';
import { useFeatureForPublisherCountrySelector } from '../../utils/featuresFlags/selectors';
import InvoicesTable from './partials/InvoicesTable/InvoicesTable';
import {
  pendingSCRsSelector,
  showSuperboostBannerSelector,
  subscriptionPageShowInvoicesTabSelector,
} from './selectors';
import PendingPlanChangeBanner from './partials/PendingPlanChangeBanner';
import {
  userIsPublisherCollaboratorSelector,
  userRoleSelector,
} from '../../selectors/user';
import ContractedSuperboostBanner from './partials/SubscriptionBanners/ContractedSuperboostBanner';
import SubscriptionDetailsModal from './partials/SubscriptionDetailsModal';
import PendingSuperboostBanner from './partials/SubscriptionBanners/PendingSuperboostBanner';
import { CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE } from './events';

function Subscription({
  hasPaidSubscription,
  showSuperboostBanner,
  hasExtras,
  hasPendingSuperboost,
  showInvoicesTab,
}) {
  const isShowInvoicesFFEnabled = useFeatureForPublisherCountrySelector(
    'SHOW_BILLING_INVOICES_IN_SUBSCRIPTION_PAGE_1753'
  );
  const role = useSelector(userRoleSelector);
  const isPublisherCollaborator = useSelector(
    userIsPublisherCollaboratorSelector
  );

  const changedBillingCycleInTH = useSelector(changedBillingCycleInTHSelector);

  useEffect(
    () =>
      dispatch({
        id: CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE,
        payload: { role },
      }),
    [role]
  );
  if (isPublisherCollaborator) {
    return null;
  }
  return (
    <SubscriptionLayout
      sidebar={
        <Layout.Padded>
          <PaymentInformation />
          <ContactSupport />
        </Layout.Padded>
      }
    >
      <Layout.Padded>
        <Header data-stonly="subscription">
          <FormattedMessageWithValidation id="subscription_section_header" />
        </Header>
        {isShowInvoicesFFEnabled && <BillingSwitch />}
        {showInvoicesTab ? (
          <InvoicesTable />
        ) : (
          <SubscriptionTab
            hasPaidSubscription={hasPaidSubscription}
            showSuperboostBanner={showSuperboostBanner}
            hasExtras={hasExtras}
            hasPendingSuperboost={hasPendingSuperboost}
            changedBillingCycleInTH={changedBillingCycleInTH}
          />
        )}
        <SubscriptionDetailsModal />
      </Layout.Padded>
    </SubscriptionLayout>
  );
}

const SubscriptionSection = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
  > ${Subtitle1} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M)}
  }
`;

function SubscriptionTab({
  hasPaidSubscription,
  showSuperboostBanner,
  changedBillingCycleInTH,
}) {
  const pendingSCRs = useSelector(pendingSCRsSelector);
  return hasPaidSubscription ? (
    <>
      <SubscriptionSection>
        <Subtitle1>
          <FormattedMessageWithValidation id="subscription_section_plan_details" />
        </Subtitle1>
        <div>
          {!changedBillingCycleInTH && <CurrentPlanBanner />}
          {pendingSCRs.map((pendingSCR) => (
            <PendingPlanChangeBanner scr={pendingSCR} />
          ))}
        </div>
      </SubscriptionSection>
      <SubscriptionSection>
        {showSuperboostBanner && (
          <>
            <Subtitle1>
              <FormattedMessageWithValidation id="subscription_section_extras" />
            </Subtitle1>
            <div>
              <ContractedSuperboostBanner />
              {pendingSCRs.map((pendingSCR) => (
                <PendingSuperboostBanner scr={pendingSCR} />
              ))}
            </div>
          </>
        )}
      </SubscriptionSection>
    </>
  ) : (
    <SubscriptionComparingTable />
  );
}

export default subscribe(Subscription, (state) => ({
  hasPaidSubscription: publisherSubscriptionPlanIsPaidSelector(state),
  showSuperboostBanner: showSuperboostBannerSelector(state),
  showInvoicesTab: subscriptionPageShowInvoicesTabSelector(state),
}));
