import { isEmpty } from 'lodash';
import {
  EMPTY_ERRORS_STATE,
  GROUPS,
  STEP_BY_ID,
  VALID_COUNTRIES,
} from './constants';
import { publisherCountrySelector } from '../../../selectors/publisher';
import { propertiesSummarySelector } from '../../../selectors/propertiesSummary';
import { localeSelector } from '../../../selectors/user';

export function csvHeadersSelector(state) {
  return state.csv?.headers || undefined;
}

export function getCsvExampleLinkSelector(state) {
  const locale = localeSelector(state);

  if (!locale.startsWith('es-')) {
    return 'https://images.proppit.com/upload_csv/ExampleUploadCSV_en.csv';
  }

  if (locale.startsWith('es-MX')) {
    return 'https://images.proppit.com/upload_csv/ExampleUploadCSV_MX.csv';
  }

  return 'https://images.proppit.com/upload_csv/ExampleUploadCSV.csv';
}

export function getCsvExampleGuideLinkSelector(state) {
  const locale = localeSelector(state);

  if (!locale.startsWith('es-')) {
    return 'https://images.proppit.com/upload_csv/Guia.pdf';
  }

  return 'https://images.proppit.com/upload_csv/Guia.pdf';
}

export function csvHeadersOptionsSelector(state) {
  const headers = state.csv?.headers || [];

  return headers
    .map(({ value, position }) => ({
      label: value === '' ? ' ' : value,
      value: position,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function csvRowsSelector(state) {
  return state.csv?.rows || undefined;
}

export function proppitFieldsSelector(state) {
  return state.csv?.choices || [];
}

export function csvFileErrorsSelector(state) {
  return state.csv?.errors || EMPTY_ERRORS_STATE;
}

export function csvFileErrorsByFieldSelector(state, fieldId) {
  const errors = csvFileErrorsSelector(state);
  return errors[fieldId] || [];
}

export function csvFileStepsSelector(state) {
  return state.csv?.step || 1;
}

export function isCurrentCSVStepSelector(state, id) {
  return csvFileStepsSelector(state) === STEP_BY_ID[id];
}

export function csvFileStepStatusSelector(state, fieldOrder) {
  const step = csvFileStepsSelector(state);
  if (step === fieldOrder) return 'CURRENT';
  if (step > fieldOrder) return 'COMPLETED';
  return undefined;
}

export function conditionsToShowModalUploadCSVSelector(state) {
  const countryCode = publisherCountrySelector(state);
  const propertySummary = propertiesSummarySelector(state);
  const publishedProperties = propertySummary?.publishedProperties;
  const maxBoostableProperties = propertySummary?.maxBoostableProperties;

  if (!VALID_COUNTRIES.includes(countryCode)) {
    return false;
  }
  if (csvHasBeenUploadedSelector(state)) {
    return false;
  }

  if (isEmpty(propertySummary)) {
    return false;
  }

  if (publishedProperties === undefined || publishedProperties === 0) {
    return true;
  }

  const planUsage = (publishedProperties / maxBoostableProperties) * 100;
  return planUsage <= 30;
}

export function csvHasBeenUploadedSelector(state) {
  return state['csv:hasBeenUploaded'] || false;
}

export function getChoicesByStepIdSelector(state, id) {
  const fieldName = getFieldNameByStepIdSelector(state, id);

  return getChoicesByFieldNameSelector(state, fieldName);
}

export function getChoicesByFieldNameSelector(state, fieldName) {
  const choices = proppitFieldsSelector(state);

  const fieldChoices = choices.find((choice) => choice.fieldName === fieldName);
  if (!fieldChoices) return [];

  return (
    Object.entries(fieldChoices.fieldOptions.choices).map(
      ([choiceName, choiceLabel]) => ({
        label: choiceLabel,
        value: choiceName,
      })
    ) || []
  );
}

export function getRowValuesByStepIdSelector(state, id) {
  const fieldName = getFieldNameByStepIdSelector(state, id);
  return getRowValuesByHeaderPositionSelector(state, fieldName);
}

export function getRowValuesByHeaderPositionSelector(state, fieldName) {
  const headerValues = state.csv?.headerValues || undefined;
  if (headerValues === undefined) return [];
  const valuePosition = headerValues[fieldName];
  if (valuePosition === undefined) return [];

  const headers = csvHeadersSelector(state);
  const foundHeader = headers.find(
    ({ position }) => position === valuePosition
  );
  if (!foundHeader) return [];
  const rowKey = foundHeader.value;

  const rowValues = csvRowsSelector(state);
  let allValues;
  let required = false;
  if (
    fieldName === 'amenities' ||
    fieldName === 'rules' ||
    fieldName === 'nearbyLocations'
  ) {
    allValues = rowValues.flatMap((row) => {
      if (row[rowKey]) {
        return row[rowKey].split(',').map((value) => value.trim());
      }
      return [];
    });
  } else {
    required = true;
    allValues = rowValues.reduce((acc, row) => {
      if (row[rowKey]) acc.push(row[rowKey]);
      return acc;
    }, []);
  }

  return [...new Set(allValues)]
    .filter((value) => value !== '')
    .map((value) => ({
      fieldName: value,
      fieldOptions: {
        required,
        tag: value,
      },
    }));
}

export function csvFileHeaderValuesSelector(state) {
  return state.csv?.headerValues || undefined;
}

export function getStepsForSideBarSelector(state) {
  const headerValues = csvFileHeaderValuesSelector(state);

  return GROUPS.map((group) => {
    if (!group.children) {
      return group;
    }

    if (group.children) {
      if (headerValues !== undefined) {
        const children = group.children.map((child) => {
          const fieldName = getFieldNameByStepIdSelector(state, child.id);

          if (headerValues?.[fieldName] !== undefined) {
            return child;
          }

          return {};
        });
        return { ...group, children: children.filter((child) => child.id) };
      }
      return { ...group, children: [] };
    }
    return group;
  });
}

export function getUploadErrorsSelector(state) {
  return state.csv?.uploadErrors;
}

export function getFieldNameByStepIdSelector(state, id) {
  const slicedString = id.slice('select'.length);

  return slicedString.charAt(0).toLowerCase() + slicedString.slice(1);
}

export function getSelectedValue(state, fieldName, fieldNameToMap) {
  const base = state.csv?.selectedValues?.[fieldNameToMap];

  return base?.[fieldName] ?? '';
}
