import styled from 'styled-components';
import { useInputBase } from 'design-system/hooks/useInputBase';
import InputBase from 'design-system/components/InputBase/InputBase';
import TextareaAutosize from 'react-textarea-autosize';
import { noop, withStylesAndInnerRef } from 'design-system/utils';

const Input = styled.textarea`
  width: 100%;
  resize: none;

  border: none;
  outline: none;
  background: transparent;
`;

function InputTextarea({ innerRef, maxLength, autoResize, ...rest }) {
  const { inputBaseProps, boxProps, inputProps } = useInputBase(rest);

  return (
    <InputBase {...inputBaseProps}>
      <InputBase.Box {...boxProps}>
        <Input
          as={autoResize && TextareaAutosize}
          ref={innerRef}
          maxLength={maxLength}
          {...inputProps}
        />
      </InputBase.Box>
    </InputBase>
  );
}
InputTextarea.defaultProps = {
  onChange: noop,
};
export default withStylesAndInnerRef(InputTextarea);
