export const QUALIFICATION_QUESTIONS = [
  {
    key: 'visitInterest',
    value: 'lead_qualification_visit_interest_question',
  },
  {
    key: 'budget',
    value: 'lead_qualification_budget_question',
  },
  { key: 'infoRequired', value: 'lead_qualification_information_question' },
  { key: 'region', value: 'lead_qualification_other_interests_question' },
  {
    key: 'preferredChannel',
    value: 'lead_qualification_preferred_channel_question',
  },
];

export const QUALIFICATIONS_WITH_MULTIPLE_ANSWERS = {
  budget: [
    'lead_qualification_budget_response_since',
    'lead_qualification_budget_response',
  ],
  region: [
    'lead_qualification_other_interests_with_rooms',
    'lead_qualification_other_interests_with_min_rooms',
  ],
  infoRequired: [
    {
      key: 'payment_conditions',
      value: 'lead_qualification_information_payment',
    },
    {
      key: 'photos_and_details',
      value: 'lead_qualification_information_photos_and_details',
    },
    {
      key: 'delivery_date',
      value: 'lead_qualification_information_delivery_date',
    },
  ],
};

export const QUALIFICATION_PREFERRABLE_CHANNELS_EXTRA_DATA = {
  whatsapp: {
    label: 'lead_qualification_preferred_channel_whatsapp',
    icon: 'whatsapp',
  },
  email: {
    label: 'lead_qualification_preferred_channel_email',
    icon: 'email',
  },
  call: {
    label: 'lead_qualification_preferred_channel_call',
    icon: 'phone',
  },
  other: {
    label: 'lead_qualification_preferred_channel_other',
    icon: 'info',
  },
};
