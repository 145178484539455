import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import ImagePreview from './ImagePreview';

import './ImageUploader.scss';
import { imageSelectorByPage } from './selectors';
import {
  CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED,
  CREATE_PUBLISHER_IMAGE_UPLOADED,
} from '../events';
import Section from '../Section';

export function ImageUploader() {
  const image = useSelector(imageSelectorByPage);

  return (
    <Section title="Logo">
      <div>
        {!image && (
          <div>
            <input
              className="PublisherImageUploader__input"
              type="file"
              id="file"
              name="image"
              onChange={(event) => {
                dispatch({
                  id: CREATE_PUBLISHER_IMAGE_UPLOADED,
                  payload: {
                    file: event.target.files[0],
                  },
                });
                resetInputFile(event.target);
              }}
              accept=".jpg, .jpeg, .png"
            />
            <label className="PublisherImageUploader__label" htmlFor="file">
              <span>+</span>
            </label>
          </div>
        )}

        {image && (
          <ImagePreview
            key={`${image}`}
            image={image}
            onDelete={() => dispatch(CREATE_PUBLISHER_IMAGE_DELETE_REQUESTED)}
          />
        )}
      </div>
    </Section>
  );
}

function resetInputFile(target) {
  // eslint-disable-next-line no-param-reassign
  target.value = null;
}
