import { COUNTRY_CODE } from '../constants/country';

const COUNTRY_NAMES_BY_COUNTRY_CODE = {
  AE: 'United Arab Emirates',
  AR: 'Argentina',
  CL: 'Chile',
  CO: 'Colombia',
  EC: 'Ecuador',
  ES: 'Spain',
  ID: 'Indonesia',
  MX: 'Mexico',
  MY: 'Malaysia',
  PA: 'Panama',
  PH: 'Philippines',
  PE: 'Peru',
  TH: 'Thailand',
  VN: 'Vietnam',
};

const LATAM_COUNTRY_CODES = ['AR', 'CL', 'CO', 'EC', 'MX', 'PA', 'PE'];
const SEA_COUNTRY_CODES = ['PH', 'TH', 'VN', 'MY', 'ID'];

export function dotPropertyOperatesIn(countryCode) {
  return isSEA(countryCode);
}

export function isSEA(countryCode) {
  return SEA_COUNTRY_CODES.includes(countryCode);
}

export function isLATAM(countryCode) {
  return LATAM_COUNTRY_CODES.includes(countryCode);
}

export function isMexico(countryCode) {
  return countryCode === COUNTRY_CODE.MEXICO;
}

export function isSpain(countryCode) {
  return countryCode === COUNTRY_CODE.SPAIN;
}

export function isArgentina(countryCode) {
  return countryCode === COUNTRY_CODE.ARGENTINA;
}

export function isChile(countryCode) {
  return countryCode === COUNTRY_CODE.CHILE;
}

export function isUnitedArabEmirates(countryCode) {
  return countryCode === COUNTRY_CODE.UNITED_ARAB_EMIRATES;
}

export function countriesSortedByCountryName() {
  return Object.fromEntries(
    Object.entries(COUNTRY_NAMES_BY_COUNTRY_CODE).sort(([, a], [, b]) =>
      a.localeCompare(b)
    )
  );
}
export function countryNameByCountryCode(countryCode) {
  return COUNTRY_NAMES_BY_COUNTRY_CODE[countryCode.toUpperCase()];
}
