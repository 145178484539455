import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import TabLink from '../TabLink';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { unreadLeadsCountSelector } from '../../../selectors/leads';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${TabLink} {
    ${({ $hasUnreadLeads }) =>
      $hasUnreadLeads && spacing.inline(SPACING_SIZE.NONE)};
  }
`;

const UnreadCount = styled(Overline)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)};
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)};
  padding: 0 ${spacing.value(SPACING_SIZE.S)};
  ${radius.regular(RADIUS_SIZE.LARGE)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function InboxTabLink() {
  const unreadCount = useSelector(unreadLeadsCountSelector);
  const hasUnreadLeads = Boolean(unreadCount);
  return (
    <Wrapper>
      <TabLink
        to="/enquiries"
        deep
        eventName="view-enquiries-page"
        elementName="enquiries-link"
        sectionName="side-menu-top-enquiries-link"
        $hasUnreadLeads={hasUnreadLeads}
      >
        <FormattedMessageWithValidation id="menu_inbox" />
      </TabLink>
      {hasUnreadLeads && <UnreadCount>{unreadCount}</UnreadCount>}
    </Wrapper>
  );
}
