import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';
import {
  AMENITY_ITEM_AIR_CONDITIONING,
  AMENITY_ITEM_ALARM,
  AMENITY_ITEM_BALCONY,
  AMENITY_ITEM_BUILTIN_WARDROBE,
  AMENITY_ITEM_CAR_PARK,
  AMENITY_ITEM_CCTV,
  AMENITY_ITEM_CELLAR,
  AMENITY_ITEM_CHILDRENS_AREA,
  AMENITY_ITEM_CLEANING_ROOM,
  AMENITY_ITEM_CONCIERGE,
  AMENITY_ITEM_DISABLED_ACCESS,
  AMENITY_ITEM_ELECTRICITY,
  AMENITY_ITEM_EQUIPPED_KITCHEN,
  AMENITY_ITEM_EXTERIOR,
  AMENITY_ITEM_FIREPLACE,
  AMENITY_ITEM_GARDEN,
  AMENITY_ITEM_GREEN_AREA,
  AMENITY_ITEM_GRILL,
  AMENITY_ITEM_GUARDHOUSE,
  AMENITY_ITEM_GYM,
  AMENITY_ITEM_HEATING,
  AMENITY_ITEM_INTEGRAL_KITCHEN,
  AMENITY_ITEM_INTERCOM,
  AMENITY_ITEM_INTERNET,
  AMENITY_ITEM_JACUZZI,
  AMENITY_ITEM_LIBRARY,
  AMENITY_ITEM_LIFT,
  AMENITY_ITEM_MULTIUSE_ROOM,
  AMENITY_ITEM_NATURAL_GAS,
  AMENITY_ITEM_OFFICE,
  AMENITY_ITEM_PANORAMIC_VIEW,
  AMENITY_ITEM_ROOF_GARDEN,
  AMENITY_ITEM_SAUNA,
  AMENITY_ITEM_SECURITY,
  AMENITY_ITEM_SECURITY_DOOR,
  AMENITY_ITEM_SEMI_DETACHED,
  AMENITY_ITEM_SERVICE_ROOM,
  AMENITY_ITEM_STORAGE_ROOM,
  AMENITY_ITEM_SWIMMING_POOL,
  AMENITY_ITEM_TENNIS_COURT,
  AMENITY_ITEM_TERRACE,
  AMENITY_ITEM_VIDEO_CABLE,
  AMENITY_ITEM_WATER,
  AMENITY_ITEM_WATER_TANK,
  AMENITY_ITEM_YARD,
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_BEACH_HOUSE,
  PROPERTY_TYPE_BUILDING,
  PROPERTY_TYPE_BUNGALOW,
  PROPERTY_TYPE_CAR_PARK,
  PROPERTY_TYPE_COMMERCIAL,
  PROPERTY_TYPE_COMMERCIAL_IN_MALL,
  PROPERTY_TYPE_COMMERCIAL_LAND,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_DOCTOR_OFFICE,
  PROPERTY_TYPE_DUPLEX,
  PROPERTY_TYPE_FIRST_FLOOR,
  PROPERTY_TYPE_FLAT,
  PROPERTY_TYPE_GATED_COMMUNITY_HOUSE,
  PROPERTY_TYPE_HORIZONTAL_CONDO,
  PROPERTY_TYPE_HOTEL,
  PROPERTY_TYPE_HOUSE,
  PROPERTY_TYPE_INDUSTRIAL_UNIT,
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_LOFT,
  PROPERTY_TYPE_OFFICE,
  PROPERTY_TYPE_PENTHOUSE,
  PROPERTY_TYPE_QUINTA,
  PROPERTY_TYPE_RANCH,
  PROPERTY_TYPE_ROOM,
  PROPERTY_TYPE_RUSTIC_HOUSE,
  PROPERTY_TYPE_SERVICED_APARTMENT,
  PROPERTY_TYPE_STORAGE_ROOM,
  PROPERTY_TYPE_STUDIO,
  PROPERTY_TYPE_SUITE,
  PROPERTY_TYPE_TOWNHOUSE,
  PROPERTY_TYPE_VILLA,
} from '../../../../constants/ad';
import { isSEA } from '../../../../utils/countries';
import { translations } from '../../../../coeffects/translations';
import { downloadCsv, downloadXls } from '../../../../effects/downloadFile';
import { analytics } from '../../../../effects/analytics';

export const DOWNLOAD_PROPERTIES_EXCEL_REQUESTED =
  'DOWNLOAD_PROPERTIES_EXCEL_REQUESTED';
export const DOWNLOAD_PROPERTIES_CSV_REQUESTED =
  'DOWNLOAD_PROPERTIES_CSV_REQUESTED';
export const PROPERTIES_DOWNLOAD_REQUESTED = 'PROPERTIES_DOWNLOAD_REQUESTED';
export const PROPERTIES_DOWNLOAD_SUCCESSFULLY =
  'PROPERTIES_DOWNLOAD_SUCCESSFULLY';
export const PROPERTIES_DOWNLOAD_FILE_GENERATED =
  'PROPERTIES_DOWNLOAD_FILE_GENERATED';
const FORMAT_XLS = 'xls';
const FORMAT_CSV = 'csv';

registerEventHandler(DOWNLOAD_PROPERTIES_CSV_REQUESTED, () => ({
  ...state.set({
    'ads:exportFormat': FORMAT_CSV,
    'ads:isExportMenuOpened': false,
  }),
  ...analytics.trackClick('export-listings-data-to-csv', 'listings-table'),
  ...effects.dispatch(PROPERTIES_DOWNLOAD_REQUESTED),
}));

registerEventHandler(DOWNLOAD_PROPERTIES_EXCEL_REQUESTED, () => ({
  ...state.set({
    'ads:exportFormat': FORMAT_XLS,
    'ads:isExportMenuOpened': false,
  }),
  ...analytics.trackClick('export-listings-data-to-excel', 'listings-table'),
  ...effects.dispatch(PROPERTIES_DOWNLOAD_REQUESTED),
}));

registerEventHandler(
  PROPERTIES_DOWNLOAD_REQUESTED,
  ({ environment: { apiUrl } }) => {
    const url = `${apiUrl}/properties-to-export-file`;
    return http.get({
      url,
      successEvent: PROPERTIES_DOWNLOAD_SUCCESSFULLY,
    });
  },
  [environment()]
);

registerEventHandler(
  PROPERTIES_DOWNLOAD_SUCCESSFULLY,
  (
    /* eslint-disable camelcase */
    {
      state: { sitesToPublish, country, publishesOnThailandProperty },
      translations: {
        excel_cells_date,
        excel_cells_email,
        excel_cells_telephone,
        excel_cells_reference_ID,
        excel_cells_property_title,
        excel_cells_property_title_english,
        excel_cells_description,
        excel_cells_description_english,
        excel_cells_property_type,
        excel_cells_price,
        excel_cells_price_sell,
        excel_cells_price_rent,
        excel_cells_operation_type_sale_and_rent,
        excel_cells_city,
        newprop_duration_header,
        propspage_table_community_fees,
        newprop_furnished_title,
        excel_cells_bedrooms,
        newprop_bathrooms_emptystate,
        newprop_area_usable_emptystate,
        land_area,
        excel_cells_project_name,
        excel_cells_operation_type,
        newprop_proptype1,
        newprop_proptype2,
        newprop_proptype,
        newprop_proptype4,
        newprop_proptype7,
        newprop_proptype11,
        newprop_proptype5,
        newprop_proptype6,
        newprop_proptype8,
        newprop_proptype9,
        newprop_proptype10,
        newprop_proptype13,
        newprop_proptype14,
        newprop_proptype15,
        newprop_proptype16,
        newprop_proptype17,
        newprop_proptype18,
        newprop_proptype19,
        newprop_proptype20,
        newprop_proptype21,
        ad_form_property_type_hotel,
        ad_form_property_type_suite,
        ad_form_property_type_flat,
        ad_form_property_type_penthouse,
        ad_form_property_type_bungalow,
        ad_form_property_type_duplex,
        ad_form_property_type_rustic_house,
        ad_form_property_type_first_floor,
        ad_form_property_type_building,
        ad_form_property_type_storage_room,
        ad_form_property_type_ranch,
        ad_form_property_type_quinta,
        excel_cells_operation_type_sale,
        excel_cells_operation_type_rent,
        newprop_ownership_header,
        listings_excel_status,
        listings_excel_boosting,
        listings_excel_publish_date,
        overview_kpis_enquiries,
        subscription_plans_no,
        overview_subscription_block_webs_yes,
        listings_excel_displays,
        listings_excel_visits,
        newprop_amenities_header,
        newprop_area_total_emptystate,
        newprop_estrato_header,
        excel_cells_contact_method_whatsapp,
        excel_cells_contact_method_viber,
        excel_cells_contact_method_facebook_msn,
        excel_cells_contact_method_line,
        excel_cells_status_published,
        excel_cells_status_unpublished,
        newprop_amenities7,
        newprop_amenities_alarm,
        newprop_amenities_balcony,
        newprop_amenities_wardroble,
        newprop_amenities2,
        newprop_amenities_wine_cellar,
        newprop_amenities9,
        newprop_amenities6,
        newprop_amenities_disabled_access,
        newprop_amenities_electricity,
        newprop_amenities_kitchen_equipped,
        newprop_amenities_fireplace,
        newprop_amenities11,
        newprop_amenities5,
        newprop_amenities_surveillance_booth,
        newprop_amenities8,
        newprop_amenities10,
        newprop_amenities_heating,
        newprop_amenities_kitchen_builtin,
        newprop_amenities_jacuzzi,
        newprop_amenities_elevator,
        newprop_amenities_library,
        newprop_amenities_natural_gas,
        newprop_amenities_office,
        newprop_amenities_views_panoramic,
        newprop_amenities_roof_garden,
        newprop_amenities_tennis_court,
        newprop_amenities_terrace,
        newprop_amenities_sauna,
        newprop_amenities12,
        newprop_amenities_utility_room,
        newprop_amenities3,
        newprop_amenities4,
        newprop_amenities_water,
        newprop_amenities_cistern,
        newprop_amenities_patio,
        adform_field_apto_credito,
        newprop_amenities_storage_room,
        newprop_amenities_security_door,
        newprop_amenities_green_area,
        newprop_amenities_semi_detached,
        newprop_amenities_intercom,
        newprop_amenities_exterior,
        newprop_amenities_cctv,
        newprop_amenities_cleaning_room,
        newprop_amenities_multiuse_room,
      },
    },
    [response]
  ) => {
    function obtainOperationTypeTag(operations) {
      const tagsByOperationType = {
        sell: excel_cells_operation_type_sale,
        rent: excel_cells_operation_type_rent,
        sellAndRent: excel_cells_operation_type_sale_and_rent,
      };

      if (operations.length === 2) {
        return tagsByOperationType.sellAndRent;
      }

      if (operations.length > 0) {
        return tagsByOperationType[operations[0].type];
      }

      return tagsByOperationType.unknown;
    }

    function obtainStatusAsText(boolean) {
      const tagsBooleanAsText = {
        true: excel_cells_status_published,
        false: excel_cells_status_unpublished,
        undefined: '',
      };
      return tagsBooleanAsText[boolean];
    }

    function obtainBooleanAsText(boolean) {
      const tagsBooleanAsText = {
        true: overview_subscription_block_webs_yes,
        false: subscription_plans_no,
        undefined: '',
      };
      return tagsBooleanAsText[boolean];
    }

    function obtainArrayAsText(array) {
      if (!array) {
        return '';
      }
      return array.join(', ');
    }

    function obtainPropertyTypeTag(propertyType) {
      const tagsByPropertyType = {
        [PROPERTY_TYPE_APARTMENT]: newprop_proptype1,
        [PROPERTY_TYPE_HOUSE]: newprop_proptype2,
        [PROPERTY_TYPE_CONDO]: newprop_proptype,
        [PROPERTY_TYPE_VILLA]: newprop_proptype4,
        [PROPERTY_TYPE_COMMERCIAL]: newprop_proptype7,
        [PROPERTY_TYPE_LAND]: newprop_proptype11,
        [PROPERTY_TYPE_TOWNHOUSE]: newprop_proptype5,
        [PROPERTY_TYPE_SERVICED_APARTMENT]: newprop_proptype6,
        [PROPERTY_TYPE_OFFICE]: newprop_proptype8,
        [PROPERTY_TYPE_INDUSTRIAL_UNIT]: newprop_proptype9,
        [PROPERTY_TYPE_CAR_PARK]: newprop_proptype10,
        [PROPERTY_TYPE_STUDIO]: newprop_proptype18,
        [PROPERTY_TYPE_ROOM]: newprop_proptype14,
        [PROPERTY_TYPE_BEACH_HOUSE]: newprop_proptype13,
        [PROPERTY_TYPE_HOTEL]: ad_form_property_type_hotel,
        [PROPERTY_TYPE_SUITE]: ad_form_property_type_suite,
        [PROPERTY_TYPE_FLAT]: ad_form_property_type_flat,
        [PROPERTY_TYPE_PENTHOUSE]: ad_form_property_type_penthouse,
        [PROPERTY_TYPE_BUNGALOW]: ad_form_property_type_bungalow,
        [PROPERTY_TYPE_DUPLEX]: ad_form_property_type_duplex,
        [PROPERTY_TYPE_RUSTIC_HOUSE]: ad_form_property_type_rustic_house,
        [PROPERTY_TYPE_FIRST_FLOOR]: ad_form_property_type_first_floor,
        [PROPERTY_TYPE_BUILDING]: ad_form_property_type_building,
        [PROPERTY_TYPE_STORAGE_ROOM]: ad_form_property_type_storage_room,
        [PROPERTY_TYPE_RANCH]: ad_form_property_type_ranch,
        [PROPERTY_TYPE_QUINTA]: ad_form_property_type_quinta,
        [PROPERTY_TYPE_GATED_COMMUNITY_HOUSE]: newprop_proptype15,
        [PROPERTY_TYPE_HORIZONTAL_CONDO]: newprop_proptype16,
        [PROPERTY_TYPE_LOFT]: newprop_proptype17,
        [PROPERTY_TYPE_COMMERCIAL_IN_MALL]: newprop_proptype19,
        [PROPERTY_TYPE_COMMERCIAL_LAND]: newprop_proptype20,
        [PROPERTY_TYPE_DOCTOR_OFFICE]: newprop_proptype21,
      };
      return tagsByPropertyType[propertyType];
    }

    function obtainAmenitiesAsText(amenities) {
      const tagsByAmenity = {
        [AMENITY_ITEM_AIR_CONDITIONING]: newprop_amenities7,
        [AMENITY_ITEM_ALARM]: newprop_amenities_alarm,
        [AMENITY_ITEM_BALCONY]: newprop_amenities_balcony,
        [AMENITY_ITEM_BUILTIN_WARDROBE]: newprop_amenities_wardroble,
        [AMENITY_ITEM_CAR_PARK]: newprop_amenities2,
        [AMENITY_ITEM_CELLAR]: newprop_amenities_wine_cellar,
        [AMENITY_ITEM_CHILDRENS_AREA]: newprop_amenities9,
        [AMENITY_ITEM_CONCIERGE]: newprop_amenities6,
        [AMENITY_ITEM_DISABLED_ACCESS]: newprop_amenities_disabled_access,
        [AMENITY_ITEM_ELECTRICITY]: newprop_amenities_electricity,
        [AMENITY_ITEM_EQUIPPED_KITCHEN]: newprop_amenities_kitchen_equipped,
        [AMENITY_ITEM_FIREPLACE]: newprop_amenities_fireplace,
        [AMENITY_ITEM_GARDEN]: newprop_amenities11,
        [AMENITY_ITEM_GRILL]: newprop_amenities5,
        [AMENITY_ITEM_GUARDHOUSE]: newprop_amenities_surveillance_booth,
        [AMENITY_ITEM_GYM]: newprop_amenities8,
        [AMENITY_ITEM_INTERNET]: newprop_amenities10,
        [AMENITY_ITEM_HEATING]: newprop_amenities_heating,
        [AMENITY_ITEM_INTEGRAL_KITCHEN]: newprop_amenities_kitchen_builtin,
        [AMENITY_ITEM_JACUZZI]: newprop_amenities_jacuzzi,
        [AMENITY_ITEM_LIFT]: newprop_amenities_elevator,
        [AMENITY_ITEM_LIBRARY]: newprop_amenities_library,
        [AMENITY_ITEM_NATURAL_GAS]: newprop_amenities_natural_gas,
        [AMENITY_ITEM_OFFICE]: newprop_amenities_office,
        [AMENITY_ITEM_PANORAMIC_VIEW]: newprop_amenities_views_panoramic,
        [AMENITY_ITEM_ROOF_GARDEN]: newprop_amenities_roof_garden,
        [AMENITY_ITEM_TENNIS_COURT]: newprop_amenities_tennis_court,
        [AMENITY_ITEM_TERRACE]: newprop_amenities_terrace,
        [AMENITY_ITEM_SAUNA]: newprop_amenities_sauna,
        [AMENITY_ITEM_SECURITY]: newprop_amenities12,
        [AMENITY_ITEM_SERVICE_ROOM]: newprop_amenities_utility_room,
        [AMENITY_ITEM_SWIMMING_POOL]: newprop_amenities3,
        [AMENITY_ITEM_VIDEO_CABLE]: newprop_amenities4,
        [AMENITY_ITEM_WATER]: newprop_amenities_water,
        [AMENITY_ITEM_WATER_TANK]: newprop_amenities_cistern,
        [AMENITY_ITEM_YARD]: newprop_amenities_patio,
        [AMENITY_ITEM_STORAGE_ROOM]: newprop_amenities_storage_room,
        [AMENITY_ITEM_SECURITY_DOOR]: newprop_amenities_security_door,
        [AMENITY_ITEM_GREEN_AREA]: newprop_amenities_green_area,
        [AMENITY_ITEM_SEMI_DETACHED]: newprop_amenities_semi_detached,
        [AMENITY_ITEM_INTERCOM]: newprop_amenities_intercom,
        [AMENITY_ITEM_EXTERIOR]: newprop_amenities_exterior,
        [AMENITY_ITEM_CCTV]: newprop_amenities_cctv,
        [AMENITY_ITEM_CLEANING_ROOM]: newprop_amenities_cleaning_room,
        [AMENITY_ITEM_MULTIUSE_ROOM]: newprop_amenities_multiuse_room,
      };

      return amenities.map((amenity) => tagsByAmenity[amenity]).join(', ');
    }

    function obtainValueAsText(number) {
      return number ? `&#8203;${number}` : '';
    }

    function obtainLocalTitle(data) {
      const title = data.reduce((acc, curr) => {
        if (curr.locale !== 'en-US') {
          return curr.text;
        }

        return acc;
      }, '');

      return title || null;
    }

    function obtainEnglishTitle(data) {
      const title = data.reduce((acc, curr) => {
        if (curr.locale === 'en-US') {
          return curr.text;
        }

        return acc;
      }, '');

      return title || null;
    }

    function obtainDescription(data) {
      const description = data.reduce((acc, curr) => {
        if (curr.locale !== 'en-US') {
          return curr.text;
        }

        return acc;
      }, '');

      return description || null;
    }

    function obtainDescriptionEnglish(data) {
      const description = data.reduce((acc, curr) => {
        if (curr.locale === 'en-US') {
          return curr.text;
        }

        return acc;
      }, '');

      return description || null;
    }

    function obtainPrice(data) {
      const price = data[0]?.price;

      return !price?.amount || !price?.currency
        ? ''
        : formatPrice(data[0].price.amount, data[0].price.currency);
    }

    function obtainSellPrice(data) {
      const sellPrice = data.reduce((acc, curr) => {
        if (curr.type === 'sell') {
          acc.amount = curr.price.amount;
          acc.currency = curr.price.currency;
        }

        return acc;
      }, {});

      return !sellPrice.amount || !sellPrice.currency
        ? ''
        : formatPrice(sellPrice.amount, sellPrice.currency);
    }

    function obtainRentPrice(data) {
      const rentPrice = data.reduce((acc, curr) => {
        if (curr.type === 'rent') {
          acc.amount = curr.price.amount;
          acc.currency = curr.price.currency;
        }

        return acc;
      }, {});

      return !rentPrice.amount || !rentPrice.currency
        ? ''
        : formatPrice(rentPrice.amount, rentPrice.currency);
    }

    function obtainCommunityFees(communityFeesAmount, communityFeesCurrency) {
      if (!communityFeesAmount || !communityFeesCurrency) {
        return '';
      }

      return formatPrice(communityFeesAmount, communityFeesCurrency);
    }

    function formatPrice(amount, currency) {
      const symbolsByCurrency = {
        USD: '$',
        CLF: 'UF',
        CLP: '$',
        COP: '$',
        MXN: 'MEX$',
        PHP: '₱',
        THB: '฿',
        VND: '₫',
        ARS: '$',
        PAB: 'B/.',
        PEN: 'S/',
      };
      return `${amount}${symbolsByCurrency[currency]}`;
    }

    function obtainUsableArea(usableArea, usableAreaUnit) {
      if (!usableArea || !usableAreaUnit) {
        return '';
      }

      const translatedUsableAreaUnit =
        usableAreaUnit === 'sqm' ? 'm2' : usableAreaUnit;

      return `${usableArea}${translatedUsableAreaUnit}`;
    }

    function obtainTotalArea(floorArea) {
      if (!floorArea) {
        return '';
      }

      return `${floorArea}m2`;
    }

    function obtainLandArea(plotArea) {
      return !plotArea
        ? ''
        : plotArea.reduce((acc, curr) => `${acc}${curr.value}${curr.unit}`, '');
    }

    function subtractProjectName(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== excel_cells_project_name)
      );
    }

    function subtractLinkThailandProperty(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== 'Link ThailandProperty')
      );
    }

    function subtractOwnership(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== newprop_ownership_header)
      );
    }

    function subtractTenureInYears(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== newprop_duration_header)
      );
    }

    function subtractTotalArea(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== newprop_area_total_emptystate)
      );
    }

    function subtractStratum(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== newprop_estrato_header)
      );
    }

    function subtractSellAndRentPrices(data) {
      return data
        .map((row) =>
          row.filter((field) => field.header !== excel_cells_price_sell)
        )
        .map((row) =>
          row.filter((field) => field.header !== excel_cells_price_rent)
        );
    }

    function subtractPrice(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== excel_cells_price)
      );
    }

    function subtractEnglishTitle(data) {
      return data.map((row) =>
        row.filter(
          (field) => field.header !== excel_cells_property_title_english
        )
      );
    }

    function subtractSuitableForCredit(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== adform_field_apto_credito)
      );
    }

    function subtractEnglishDescription(data) {
      return data.map((row) =>
        row.filter((field) => field.header !== excel_cells_description_english)
      );
    }

    function subtractChats(data) {
      const chats = {
        facebookMessenger: ['TH', 'VN', 'PH'],
        viber: ['PH'],
        line: ['TH'],
      };

      if (!chats.facebookMessenger.includes(country)) {
        data = data.map((row) =>
          row.filter(
            (field) => field.header !== excel_cells_contact_method_facebook_msn
          )
        );
      }

      if (!chats.viber.includes(country)) {
        data = data.map((row) =>
          row.filter(
            (field) => field.header !== excel_cells_contact_method_viber
          )
        );
      }

      if (!chats.line.includes(country)) {
        data = data.map((row) =>
          row.filter(
            (field) => field.header !== excel_cells_contact_method_line
          )
        );
      }

      return data;
    }

    let translatedContent = response.data.map((ad) =>
      [
        {
          header: excel_cells_reference_ID,
          value: obtainValueAsText(ad.referenceId),
        },
        { header: excel_cells_date, value: ad.creationDate },
        {
          header: listings_excel_publish_date,
          value: ad.publishDate,
        },
        {
          header: listings_excel_status,
          value: obtainStatusAsText(ad.published),
        },
        country !== 'MX' && {
          header: listings_excel_boosting,
          value: obtainBooleanAsText(ad.boosted),
        },
        {
          header: excel_cells_property_title,
          value: obtainLocalTitle(ad.title),
        },
        {
          header: excel_cells_property_title_english,
          value: obtainEnglishTitle(ad.title),
        },
        {
          header: excel_cells_description,
          value: obtainDescription(ad.description),
        },
        {
          header: excel_cells_description_english,
          value: obtainDescriptionEnglish(ad.description),
        },
        { header: excel_cells_project_name, value: ad.projectName },
        { header: excel_cells_city, value: ad.address },
        {
          header: excel_cells_property_type,
          value: obtainPropertyTypeTag(ad.propertyType),
        },
        {
          header: excel_cells_operation_type,
          value: obtainOperationTypeTag(ad.operations),
        },
        {
          header: excel_cells_price,
          value: obtainPrice(ad.operations),
        },
        {
          header: excel_cells_price_sell,
          value: obtainSellPrice(ad.operations),
        },
        {
          header: excel_cells_price_rent,
          value: obtainRentPrice(ad.operations),
        },
        {
          header: adform_field_apto_credito,
          value: obtainBooleanAsText(ad.suitableForCredit),
        },
        { header: newprop_estrato_header, value: ad.stratum },
        { header: newprop_duration_header, value: ad.tenureInYears },
        { header: newprop_ownership_header, value: ad.ownership },
        {
          header: propspage_table_community_fees,
          value: obtainCommunityFees(
            ad.communityFeesAmount,
            ad.communityFeesCurrency
          ),
        },
        { header: newprop_furnished_title, value: ad.furnished },
        { header: excel_cells_bedrooms, value: ad.bedrooms },
        { header: newprop_bathrooms_emptystate, value: ad.bathrooms },
        {
          header: newprop_area_usable_emptystate,
          value: obtainUsableArea(ad.usableArea, ad.usableAreaUnit),
        },
        {
          header: newprop_area_total_emptystate,
          value: obtainTotalArea(ad.floorArea),
        },
        { header: land_area, value: obtainLandArea(ad.plotArea) },
        {
          header: excel_cells_email,
          value: obtainArrayAsText(ad.contactEmails),
        },
        {
          header: excel_cells_telephone,
          value: obtainValueAsText(ad.contactPhone),
        },
        {
          header: excel_cells_contact_method_whatsapp,
          value: obtainValueAsText(ad.contactWhatsApp),
        },
        {
          header: excel_cells_contact_method_facebook_msn,
          value: obtainValueAsText(ad.contactFacebookMessenger),
        },
        {
          header: excel_cells_contact_method_viber,
          value: obtainValueAsText(ad.contactViber),
        },
        {
          header: excel_cells_contact_method_line,
          value: obtainValueAsText(ad.contactLine),
        },
        ...createSitesToPublish(ad.id, sitesToPublish, ad.published),
        {
          header: newprop_amenities_header,
          value: obtainAmenitiesAsText(ad.amenities),
        },
        { header: listings_excel_displays, value: ad.impressions },
        { header: listings_excel_visits, value: ad.clicks },
        { header: overview_kpis_enquiries, value: ad.enquiries },
      ].filter(Boolean)
    );

    if (!isSEA(country)) {
      translatedContent = subtractProjectName(translatedContent);
      translatedContent = subtractOwnership(translatedContent);
      translatedContent = subtractTenureInYears(translatedContent);
      translatedContent = subtractSellAndRentPrices(translatedContent);
      translatedContent = subtractEnglishTitle(translatedContent);
      translatedContent = subtractEnglishDescription(translatedContent);
    }

    if (country === 'PH') {
      translatedContent = subtractEnglishTitle(translatedContent);
      translatedContent = subtractEnglishDescription(translatedContent);
    }

    if (country !== 'AR') {
      translatedContent = subtractSuitableForCredit(translatedContent);
    }

    if (isSEA(country)) {
      translatedContent = subtractTotalArea(translatedContent);
      translatedContent = subtractPrice(translatedContent);
    }
    if (country !== 'CO') {
      translatedContent = subtractStratum(translatedContent);
    }

    if (!publishesOnThailandProperty) {
      translatedContent = subtractLinkThailandProperty(translatedContent);
    }

    translatedContent = subtractChats(translatedContent);

    return effects.dispatch({
      id: PROPERTIES_DOWNLOAD_FILE_GENERATED,
      payload: translatedContent,
    });
  },
  [
    state.get({
      sitesToPublish: 'countryConfig.sitesToPublish',
      country: 'publisher:countryCode',
      publishesOnThailandProperty: 'publisher.publishesOnThailandProperty',
    }),
    translations([
      'newprop_ownership_header',
      'newprop_amenities_header',
      'excel_cells_date',
      'excel_cells_email',
      'excel_cells_telephone',
      'excel_cells_reference_ID',
      'excel_cells_property_title',
      'excel_cells_property_title_english',
      'excel_cells_description',
      'excel_cells_description_english',
      'excel_cells_property_type',
      'excel_cells_price',
      'excel_cells_price_sell',
      'excel_cells_price_rent',
      'excel_cells_operation_type_sale_and_rent',
      'newprop_duration_header',
      'propspage_table_community_fees',
      'newprop_furnished_title',
      'excel_cells_city',
      'excel_cells_bedrooms',
      'newprop_bathrooms_emptystate',
      'newprop_area_usable_emptystate',
      'land_area',
      'excel_cells_project_name',
      'overview_kpis_enquiries',
      'excel_cells_operation_type',
      'excel_cells_floor_area',
      'newprop_proptype1',
      'newprop_proptype2',
      'newprop_proptype',
      'newprop_proptype4',
      'newprop_proptype7',
      'newprop_proptype11',
      'newprop_proptype5',
      'newprop_proptype6',
      'newprop_proptype8',
      'newprop_proptype9',
      'newprop_proptype10',
      'newprop_proptype12',
      'newprop_proptype13',
      'newprop_proptype14',
      'newprop_proptype15',
      'newprop_proptype16',
      'newprop_proptype17',
      'newprop_proptype18',
      'newprop_proptype19',
      'newprop_proptype20',
      'newprop_proptype21',
      'ad_form_property_type_hotel',
      'ad_form_property_type_suite',
      'ad_form_property_type_flat',
      'ad_form_property_type_penthouse',
      'ad_form_property_type_bungalow',
      'ad_form_property_type_duplex',
      'ad_form_property_type_rustic_house',
      'ad_form_property_type_first_floor',
      'ad_form_property_type_building',
      'ad_form_property_type_storage_room',
      'ad_form_property_type_ranch',
      'ad_form_property_type_quinta',
      'excel_cells_operation_type_sale',
      'excel_cells_operation_type_rent',
      'excel_cells_lead_type_chat',
      'excel_cells_lead_type_mail',
      'excel_cells_lead_type_phone',
      'excel_cells_contact_method_whatsapp',
      'excel_cells_contact_method_line',
      'excel_cells_contact_method_viber',
      'excel_cells_contact_method_facebook_msn',
      'enqpage_table_status2',
      'enqpage_table_status1',
      'status_enquiries_visit',
      'status_enquiries_proposal',
      'status_enquiries_won',
      'status_enquiries_lost',
      'listings_excel_publish_date',
      'listings_excel_boosting',
      'listings_excel_status',
      'overview_subscription_block_webs_yes',
      'subscription_plans_no',
      'listings_excel_displays',
      'listings_excel_visits',
      'newprop_area_total_emptystate',
      'newprop_estrato_header',
      'excel_cells_status_published',
      'excel_cells_status_unpublished',
      'newprop_amenities7',
      'newprop_amenities_alarm',
      'newprop_amenities_balcony',
      'newprop_amenities_wardroble',
      'newprop_amenities2',
      'newprop_amenities_wine_cellar',
      'newprop_amenities9',
      'newprop_amenities6',
      'newprop_amenities_disabled_access',
      'newprop_amenities_electricity',
      'newprop_amenities_kitchen_equipped',
      'newprop_amenities_fireplace',
      'newprop_amenities11',
      'newprop_amenities5',
      'newprop_amenities_surveillance_booth',
      'newprop_amenities8',
      'newprop_amenities10',
      'newprop_amenities_heating',
      'newprop_amenities_kitchen_builtin',
      'newprop_amenities_jacuzzi',
      'newprop_amenities_elevator',
      'newprop_amenities_library',
      'newprop_amenities_natural_gas',
      'newprop_amenities_office',
      'newprop_amenities_views_panoramic',
      'newprop_amenities_roof_garden',
      'newprop_amenities_tennis_court',
      'newprop_amenities_terrace',
      'newprop_amenities_sauna',
      'newprop_amenities12',
      'newprop_amenities_utility_room',
      'newprop_amenities3',
      'newprop_amenities4',
      'newprop_amenities_water',
      'newprop_amenities_cistern',
      'newprop_amenities_patio',
      'newprop_amenities_cctv',
      'newprop_amenities_cleaning_room',
      'newprop_amenities_multiuse_room',
      'adform_field_apto_credito',
    ]),
  ]
);

function createSitesToPublish(adId, sitesToPublish, isPublished) {
  return sitesToPublish
    .filter(({ domain }) => domain)
    .map(({ site, domain }) => ({
      header: `Link ${site}`,
      value: isPublished ? `${domain}${adId}` : '',
    }));
}

registerEventHandler(
  PROPERTIES_DOWNLOAD_FILE_GENERATED,
  ({ state: { format } }, payload) => {
    if (format === FORMAT_XLS) {
      return downloadXls({ filename: 'listings.xls', content: payload });
    }

    return downloadCsv({ filename: 'listings.csv', content: payload });
  },
  [state.get({ format: 'ads:exportFormat' })]
);
