import { ROUTE_TYPE, RouteProvider } from './context';
import TrackPageView from '../../partials/Tracking/TrackPageView';
import RouteWithInterstitial from './RouteWithInterstitial';

function PublicRoute({ as: Component, disableInterstitial, ...props }) {
  const { pageViewName, pageViewPayloadSelector } = props;
  return (
    <RouteProvider routeType={ROUTE_TYPE.PUBLIC}>
      <RouteWithInterstitial disable={disableInterstitial}>
        <TrackPageView
          pageViewName={pageViewName}
          payload={pageViewPayloadSelector && pageViewPayloadSelector(props)}
        >
          <Component {...props} />
        </TrackPageView>
      </RouteWithInterstitial>
    </RouteProvider>
  );
}

export default PublicRoute;
