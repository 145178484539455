import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import DropdownMenu from 'design-system/components/DropdownMenu';
import { DROPDOWN_POSITION } from 'design-system/components/Dropdown';
import Menu from 'design-system/components/Menu/Menu';
import Button, {
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { DEFAULT_ENQUIRIES_STAGE_FILTER } from './constants';
import { useFilter } from '../../../../partials/DataTable/hooks/useFilter';
import { LEAD_FILTERS } from '../../constants';

const DropdownButton = styled(Button)`
  ${Button.mods.variant(BUTTON_VARIANT.OUTLINE)}
  ${Button.mods.size(BUTTON_SIZE.BASE)}
`;

const filters = {
  'new lead': <FormattedMessageWithValidation id="enqpage_table_status2" />,
  contacted: <FormattedMessageWithValidation id="enqpage_table_status1" />,
  visit: <FormattedMessageWithValidation id="status_enquiries_visit" />,
  proposal: <FormattedMessageWithValidation id="status_enquiries_proposal" />,
  won: <FormattedMessageWithValidation id="status_enquiries_won" />,
  lost: <FormattedMessageWithValidation id="status_enquiries_lost" />,
};

export default function StageFilters() {
  const { value, setValue } = useFilter(LEAD_FILTERS.STATUS);

  return (
    <DropdownMenu
      button={
        <DropdownButton icon={<Icon glyph="chevronDown" />}>
          {filters[value] ?? (
            <FormattedMessageWithValidation id="enqpage_status_filters_emptystate" />
          )}
        </DropdownButton>
      }
      active={value && value !== DEFAULT_ENQUIRIES_STAGE_FILTER}
      onConfirm={({ value: selectedValue }) => setValue(selectedValue)}
      position={DROPDOWN_POSITION.BOTTOM_LEFT}
    >
      <Menu>
        <Menu.Item
          id={DEFAULT_ENQUIRIES_STAGE_FILTER}
          label={
            <Body1 $noWrap>
              <FormattedMessageWithValidation id="enqpage_status_filters_all" />
            </Body1>
          }
          value={null}
        />
        {Object.entries(filters).map(([status, label]) => (
          <Menu.Item
            key={status}
            id={status}
            label={<Body1 $noWrap>{label}</Body1>}
            value={status}
          />
        ))}
      </Menu>
    </DropdownMenu>
  );
}
