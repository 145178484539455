import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import Icon from 'design-system/components/Icon';
import { useRegisterFieldErrors } from '../FieldGroup';
import {
  addressSelector,
  addressSuggestionsLoadingSelector,
  addressSuggestionsSelector,
  displayableAddressSelector,
} from './selectors';
import {
  ADDRESS_AUTOCOMPLETE_CLEARED,
  ADDRESS_SEARCH_SUGGESTIONS,
  ADDRESS_SUGGESTIONS_SELECTED,
  ADDRESS_TEXT_INTRODUCED,
  MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS,
} from './events';
import useInternationalization from '../../../../hooks/useInternationalization';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { ADDRESS_INPUT_NAME } from '../../constants';
import { projectSelector } from '../../selectors';

const Wrapper = styled.div`
  grid-column: 1 / span 6;
`;

const optionToString = (option) => (option === undefined ? '' : option.address);

function AddressField() {
  const intl = useInternationalization();
  const itemsSuggestions = useSelector(addressSuggestionsSelector);
  const suggestionsLoading = useSelector(addressSuggestionsLoadingSelector);
  const project = useSelector(projectSelector);
  const address = useSelector(addressSelector);
  const displayableAddress = useSelector(displayableAddressSelector);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange },
  } = useController({
    name: ADDRESS_INPUT_NAME,
    control,
  });

  useRegisterFieldErrors({
    name: ADDRESS_INPUT_NAME,
    errors: errors[ADDRESS_INPUT_NAME],
  });

  useEffect(() => {
    onChange(address);
  }, [address]);

  return (
    <Wrapper>
      <Autocomplete
        menuWrapperDataTag="address_suggestions"
        value={displayableAddress}
        options={itemsSuggestions}
        onValueChange={onQueryChange}
        onOptionSelected={handleSelectOption}
        optionToString={optionToString}
        isLoading={suggestionsLoading}
        minCharactersToOpen={MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS}
        renderInput={({ value, getInputProps, onClear }) => (
          <InputText
            data-tag="address_input"
            disabled={project}
            required
            label={
              <FormattedMessageWithValidation id="newprop_address_emptystate" />
            }
            placeholder={intl.formatMessageWithValidation({
              id: 'propspage_placeholder_address',
            })}
            error={Boolean(errors[ADDRESS_INPUT_NAME])}
            tip={errors[ADDRESS_INPUT_NAME]?.message}
            icon={!project && displayableAddress && <Icon glyph="cross" />}
            onIconClick={
              displayableAddress &&
              (() => {
                onClear();
                dispatch(ADDRESS_AUTOCOMPLETE_CLEARED);
              })
            }
            {...getInputProps({ ref, value, autoComplete: 'nope' })}
          />
        )}
        renderOptions={({ options, getOptionProps }) =>
          options.map((option, index) => (
            <Autocomplete.Option
              title={option.address}
              {...getOptionProps({
                index,
                option,
                key: `suggestion_${index}`,
                refKey: 'innerRef',
              })}
            />
          ))
        }
        emptyStateMessage={
          <FormattedMessageWithValidation id="newprop_address_no_options" />
        }
        loadingStateMessage={
          <FormattedMessageWithValidation id="publishing_form_suggester_component_loading" />
        }
      />
    </Wrapper>
  );
}

function handleSelectOption(selectedOption) {
  dispatch({ id: ADDRESS_SUGGESTIONS_SELECTED, payload: selectedOption });
}

const searchSuggestions = debounce((inputValue) => {
  dispatch({
    id: ADDRESS_SEARCH_SUGGESTIONS,
    payload: { addressText: inputValue },
  });
}, 2000);

function onQueryChange(inputValue) {
  dispatch({
    id: ADDRESS_TEXT_INTRODUCED,
    payload: { addressText: inputValue },
  });

  searchSuggestions(inputValue);
}

export default AddressField;
