import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import Table, { TABLE_ROW_SEPARATION } from 'design-system/components/Table';
import Dialog from 'design-system/components/Dialog/Dialog';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { useDialog } from '../../../../../../../hooks/useDialog';
import {
  EDIT_LISTING_RULES_MODAL,
  PUBLISHER_EDIT_LISTING_RULES_CLOSED,
  PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED,
} from './events';
import {
  brandsToPublishNonBoostedListingsToSelector,
  canEditListingRulesSelector,
} from './selectors';
import { BRANDS, BRANDS_TO_SITES } from '../../../../../../../constants';

const FORM_ID = 'brands-to-publish-to';

const NON_BOOSTED_LISTINGS_CHECKBOX_NAME = 'NonBoostedListings';

function isDisabled(brand) {
  return brand === BRANDS.THAILAND_PROPERTY;
}

const TableText = styled(Body1)`
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
`;

const CenteredTableText = styled(TableText)`
  width: 100%;
  text-align: center;
`;

const CenteredCheckbox = styled(Checkbox)`
  margin: 0 auto;
`;

function EditListingRulesModal({ onSuccessEvent }) {
  const { open, closeDialog, parameters } = useDialog(EDIT_LISTING_RULES_MODAL);
  const onClose = () => {
    closeDialog();
    dispatch(PUBLISHER_EDIT_LISTING_RULES_CLOSED);
  };

  const canEditListingRules = useSelector(canEditListingRulesSelector);
  const brandsToPublishNonBoostedListingsTo = useSelector(
    brandsToPublishNonBoostedListingsToSelector
  );

  if (brandsToPublishNonBoostedListingsTo == null) {
    return null;
  }

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={onClose}>
        Edit publisher&#39;s listing rules
      </Dialog.Header>
      <Dialog.Content>
        <form
          id={FORM_ID}
          onSubmit={(e) => {
            e.preventDefault();
            const nonBoostedListingsSelectedOptions = Array.from(
              new FormData(e.target).getAll(NON_BOOSTED_LISTINGS_CHECKBOX_NAME)
            );
            dispatch({
              id: PUBLISHER_EDIT_LISTING_RULES_MODAL_SUBMITTED,
              payload: {
                onSuccessEvent,
                nonBoostedListingsSelectedOptions,
                ...parameters,
              },
            });
          }}
        >
          <Table $rowSeparation={TABLE_ROW_SEPARATION.EVEN_AND_ODD}>
            <Table.Row>
              <Table.Cell>
                <TableText>Brand</TableText>
              </Table.Cell>
              <Table.Cell>
                <CenteredTableText>Boosted</CenteredTableText>
              </Table.Cell>
              <Table.Cell>
                <CenteredTableText>Non boosted</CenteredTableText>
              </Table.Cell>
            </Table.Row>
            {Object.entries(brandsToPublishNonBoostedListingsTo).map(
              ([brand, value]) => (
                <Table.Row key={brand}>
                  <Table.Cell>
                    <TableText>{BRANDS_TO_SITES[brand] ?? brand}</TableText>
                  </Table.Cell>
                  <Table.Cell>
                    <CenteredCheckbox disabled checked />
                  </Table.Cell>
                  <Table.Cell>
                    <CenteredCheckbox
                      id={brand}
                      name={NON_BOOSTED_LISTINGS_CHECKBOX_NAME}
                      defaultChecked={value}
                      value={brand}
                      disabled={isDisabled(brand)}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table>
        </form>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={onClose} $size={BUTTON_SIZE.SMALL}>
          Cancel
        </FlatButton>
        <SecondaryButton
          type="submit"
          form={FORM_ID}
          $size={BUTTON_SIZE.SMALL}
          disabled={!canEditListingRules}
        >
          Update preferences
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

export default EditListingRulesModal;
