import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { BUTTON_SIZE } from 'design-system/components/Button';
import {
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL,
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_PAYMENT_METHOD_CHANGED,
  PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SUBMITTED,
} from './events';
import {
  availablePaymentMethodsSelector,
  isDisabledPaymentMethodSelectSelector,
  selectedPaymentMethodSelector,
} from './selectors';
import PaymentMethodSelect from '../../../../../partials/PaymentMethodSelect/PaymentMethodSelect';
import { useDialog } from '../../../../../../../hooks/useDialog';

function ChangePaymentMethodModal({ onSuccessEvent }) {
  const { open, closeDialog } = useDialog(
    PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL
  );
  const selectedPaymentMethod = useSelector(selectedPaymentMethodSelector);
  const loading = useSelector(
    (state) => state['change-payment-method-modal']?.loading ?? false
  );
  const errors = useSelector(
    (state) => state['change-payment-method-modal']?.errors ?? false
  );
  const availablePaymentMethods = useSelector(availablePaymentMethodsSelector);
  const disabledPaymentMethod = useSelector(
    isDisabledPaymentMethodSelectSelector
  );

  return (
    <Dialog open={open}>
      <Dialog.Header onClose={closeDialog}>Change payment method</Dialog.Header>
      <Dialog.Content>
        <PaymentMethodSelect
          availablePaymentMethods={availablePaymentMethods}
          value={selectedPaymentMethod}
          onChange={(e) =>
            dispatch({
              id: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_PAYMENT_METHOD_CHANGED,
              payload: { paymentMethod: e.value },
            })
          }
          disabled={disabledPaymentMethod}
        />
        {errors && <p>An error occurred.</p>}
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton
          onClick={closeDialog}
          $size={BUTTON_SIZE.SMALL}
          disabled={loading}
        >
          Cancel
        </FlatButton>
        <SecondaryButton
          onClick={() => {
            dispatch({
              id: PUBLISHER_CHANGE_PAYMENT_METHOD_MODAL_SUBMITTED,
              payload: {
                onSuccessEvent,
              },
            });
          }}
          disabled={loading}
          $size={BUTTON_SIZE.SMALL}
        >
          Save
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

export default ChangePaymentMethodModal;
