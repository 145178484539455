import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Link } from 'react-router-dom';
import {
  BUTTON_ICON_POSITION,
  BUTTON_SIZE,
} from 'design-system/components/Button';
import Icon from 'design-system/components/Icon';
import { FlatButton } from 'design-system/components/Button/presets';

const Button = styled(FlatButton)`
  ${spacing.stack(SPACING_SIZE.L)}
`;

export default function BackButton({ to, children }) {
  return (
    <Button
      $size={BUTTON_SIZE.SMALL}
      icon={<Icon glyph="chevronLeft" />}
      $iconPosition={BUTTON_ICON_POSITION.START}
      forwardedAs={Link}
      to={to}
    >
      {children}
    </Button>
  );
}
