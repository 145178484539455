import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { TRACK_PAGE_VIEW_MOUNTED } from './events';

export default function TrackPageView({ pageViewName, payload, children }) {
  useMount(() =>
    dispatch({
      id: TRACK_PAGE_VIEW_MOUNTED,
      payload: { pageViewName, payload },
    })
  );

  return <>{children}</>;
}
