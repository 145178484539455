import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useController, useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import InputText from 'design-system/components/InputText';
import {
  addressSuggestionsLoadingSelector,
  addressSuggestionsSelector,
} from '../../publication/partials/Location/selectors';
import {
  ADDRESS_AUTOCOMPLETE_CLEARED,
  ADDRESS_SEARCH_SUGGESTIONS,
  ADDRESS_SUGGESTIONS_SELECTED,
  MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS,
} from '../../publication/partials/Location/events';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import IconMultiLanguage from '../../../partials/IconMultiLanguage';

const MULTILANG_ADDRESS_FIELD_NAME = 'addresses';

const optionToString = (option) => (option === undefined ? '' : option.address);

function MultiLangAddressField({ locale, showFlagIcon, ...rest }) {
  const itemsSuggestions = useSelector(addressSuggestionsSelector);
  const suggestionsLoading = useSelector(addressSuggestionsLoadingSelector);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { value: address, ref, onChange },
  } = useController({
    name: `${MULTILANG_ADDRESS_FIELD_NAME}[${locale}]`,
    control,
  });

  const handleSelectOption = (selectedOption) => {
    dispatch({
      id: ADDRESS_SUGGESTIONS_SELECTED,
      payload: selectedOption,
    });
  };

  const searchSuggestions = debounce((inputValue) => {
    dispatch({
      id: ADDRESS_SEARCH_SUGGESTIONS,
      payload: { addressText: inputValue, locale },
    });
  }, 500);

  const handleQueryChange = (inputValue) => {
    onChange(inputValue);
    searchSuggestions(inputValue);
  };

  return (
    <Autocomplete
      value={address}
      options={itemsSuggestions}
      onValueChange={handleQueryChange}
      onOptionSelected={handleSelectOption}
      optionToString={optionToString}
      isLoading={suggestionsLoading}
      minCharactersToOpen={MINIMUM_LENGTH_TO_RETRIEVE_SUGGESTIONS}
      renderInput={({ value, getInputProps, onClear }) => (
        <InputText
          error={Boolean(errors[MULTILANG_ADDRESS_FIELD_NAME]?.[locale])}
          tip={errors[MULTILANG_ADDRESS_FIELD_NAME]?.[locale]?.message}
          icon={showFlagIcon && <IconMultiLanguage locale={locale} />}
          onIconClick={
            value &&
            (() => {
              onClear();
              onChange('');
              dispatch(ADDRESS_AUTOCOMPLETE_CLEARED);
            })
          }
          {...rest}
          {...getInputProps({ ref, value, autoComplete: 'nope' })}
        />
      )}
      renderOptions={({ options, getOptionProps }) =>
        options.map((option, index) => (
          <Autocomplete.Option
            title={option.address}
            {...getOptionProps({
              index,
              option,
              key: `suggestion_${index}`,
              refKey: 'innerRef',
            })}
          />
        ))
      }
      emptyStateMessage={
        <FormattedMessageWithValidation id="newprop_address_no_options" />
      }
      loadingStateMessage={
        <FormattedMessageWithValidation id="publishing_form_suggester_component_loading" />
      }
    />
  );
}

export default MultiLangAddressField;
