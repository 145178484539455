import { coeffect, registerCoeffectHandler } from 'reffects';

export function registerQueryParamsAllCoeffect(globalObject) {
  registerCoeffectHandler('queryParamsAll', function retrieveQueryParams() {
    const urlParams = new URLSearchParams(globalObject.location.search);

    const queryParams = Array.from(urlParams.keys()).reduce(
      function convertQueryParamsToObject(queryParamValues, queryParam) {
        const queryParamValue = urlParams
          .getAll(queryParam)
          .map(function urlDecodeParams(param) {
            return decodeURIComponent(param);
          });

        if (queryParamValue.length === 0) {
          return { ...queryParamValues, [queryParam]: null };
        }

        if (queryParamValue.length === 1) {
          return { ...queryParamValues, [queryParam]: queryParamValue[0] };
        }

        return { ...queryParamValues, [queryParam]: queryParamValue };
      },
      {}
    );

    return {
      queryParamsAll: queryParams,
    };
  });
}

export function queryParamsGetAll() {
  return coeffect('queryParamsAll');
}
