import styled from 'styled-components';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Menu from 'design-system/components/Menu/Menu';
import { OutlineButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { BUTTON_ICON_POSITION } from 'design-system/components/Button';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { LEAD_STATUS_TO_TEXT_MAP } from './constants';
import useTracking from '../../../../hooks/useTracking';

const SkeletonWrapper = styled.div`
  width: 112px;
  height: 32px;
  border-radius: 16px;
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 80px;
    height: 12px;
    ${color.background(COLOR_PALETTE.NEUTRAL_A10)}
  }
`;

function LeadStatusDropdown({ lead, onStatusChanged }) {
  const ref = useTracking({
    trackImpression: {
      elementName: 'lead-status-btn',
    },
    sectionName: 'lead-detail-listing',
  });

  const handleStatusChange = (e) => {
    if (e.id !== lead.status) {
      onStatusChanged({ leadId: lead.id, status: e.id });
    }
  };

  return (
    <DropdownMenu
      onConfirm={handleStatusChange}
      button={
        <OutlineButton
          icon={<Icon glyph="chevronDown" />}
          $iconPosition={BUTTON_ICON_POSITION.END}
          ref={ref}
        >
          {LEAD_STATUS_TO_TEXT_MAP[lead.status]}
        </OutlineButton>
      }
    >
      <Menu>
        {Object.entries(LEAD_STATUS_TO_TEXT_MAP).map(([id, label]) => (
          <Menu.Item key={id} id={id} label={<Body1 $noWrap>{label}</Body1>} />
        ))}
      </Menu>
    </DropdownMenu>
  );
}

function Skeleton() {
  return (
    <SkeletonWrapper>
      <div />
    </SkeletonWrapper>
  );
}

LeadStatusDropdown.Skeleton = Skeleton;

export default LeadStatusDropdown;
