import styled from 'styled-components';
import Illustration from 'design-system/components/Illustration/Illustration';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${Illustration} {
    ${spacing.inline(SPACING_SIZE.L)}
  }
`;

const Info = styled.div`
  > ${Subtitle3} {
    display: block;
    ${spacing.stack(SPACING_SIZE.S)}
  }
`;

const cardIllustrations = {
  visa: 'visaCreditCard',
  mc: 'masterCardCreditCard',
  amex: 'maestroCardCreditCard',
};

const cardTypeNames = {
  visa: 'subscription_payment_creditCard_visa',
  mc: 'subscription_payment_creditCard_mastercard',
  amex: 'subscription_payment_creditCard_maestro',
};

function CreditCard({ cardType, tokenizedCardNumber }) {
  const illustration = cardIllustrations[cardType];
  const cardName = cardTypeNames[cardType];
  return (
    <Wrapper>
      {illustration && <Illustration name={illustration} />}
      <Info>
        {cardName && (
          <Subtitle3>
            <FormattedMessageWithValidation id={cardName} />
          </Subtitle3>
        )}
        <Body1 $weight={FONT_WEIGHT.LIGHT}>{tokenizedCardNumber}</Body1>
      </Info>
    </Wrapper>
  );
}

export default CreditCard;
