import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import styled from 'styled-components';
import Table, {
  TABLE_ALIGNMENT,
  TABLE_SORTING,
} from 'design-system/components/Table';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Tooltip from 'design-system/components/Tooltip';
import Typography from 'design-system/components/Typography/Typography';
import UnitRow from './UnitRow';
import EmptyUnitRow from './EmptyUnitRow';
import BulkActionsMenu from './BulkActionsMenu';
import { SORTING_DIRECTIONS } from '../../../Tables/constants';
import { tableRowsSelector, tableSelector } from '../../../Tables/selectors';
import { UNITS_BULK_TOGGLE_VISIBLE } from './BulkActionsMenu/events';
import { UNITS_TABLE_SORTED } from './events';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { UNITS_TABLE_NAME } from '../../constants';
import { isBulkCheckboxCheckedSelector } from './BulkActionsMenu/selectors';
import { Pagination } from './Pagination';

const sortingDirectionMap = {
  [SORTING_DIRECTIONS.ASCENDING]: TABLE_SORTING.ASCENDING,
  [SORTING_DIRECTIONS.DESCENDING]: TABLE_SORTING.DESCENDING,
};

function UnitRows({ list }) {
  if (list.length === 0) {
    return <EmptyUnitRow />;
  }

  return (
    <>
      {list.map((unitId, index) => (
        <UnitRow
          key={unitId}
          id={unitId}
          data-stonly={index === 0 ? 'properties-table' : undefined}
        />
      ))}
    </>
  );
}

const CheckboxTableHead = styled.th`
  width: 32px;
  text-align: left;
  padding: 12px 0 9px 16px;
`;

function ColumnTitle({ id }) {
  return (
    <Typography>
      <FormattedMessageWithValidation id={id} />
    </Typography>
  );
}

const COLUMNS = (bulkChecked) => [
  {
    id: 'isSelectedForBulkAction',
    content: (
      <Checkbox
        checked={bulkChecked}
        onChange={onToggleBulkVisibleProperties}
      />
    ),
    CellWrapper: ({ content }) => (
      <CheckboxTableHead>{content}</CheckboxTableHead>
    ),
  },
  {
    id: 'unit',
    sortingField: 'project.name',
    content: <ColumnTitle id="unitspage_table_unit" />,
  },
  {
    id: 'referenceId',
    content: <ColumnTitle id="propspage_table_reference_id" />,
  },
  {
    id: 'rooms',
    sortingField: 'bedrooms',
    content: <ColumnTitle id="unitspage_table_rooms" />,
  },
  {
    id: 'baths',
    sortingField: 'bathrooms',
    content: <ColumnTitle id="unitspage_table_baths" />,
  },
  {
    id: 'price',
    content: <ColumnTitle id="propspage_table_price" />,
  },
  {
    id: 'displays',
    content: (
      <Tooltip
        title={<FormattedMessageWithValidation id="proppage_table_stat_1" />}
      >
        <ColumnTitle id="propspage_table_stats_displays" />
      </Tooltip>
    ),
    alignment: TABLE_ALIGNMENT.CENTER,
  },
  {
    id: 'views',
    content: (
      <Tooltip
        title={<FormattedMessageWithValidation id="proppage_table_stat_2" />}
      >
        <ColumnTitle id="propspage_table_stats_views" />
      </Tooltip>
    ),
    alignment: TABLE_ALIGNMENT.CENTER,
  },
  {
    id: 'leads',
    content: (
      <Tooltip
        title={<FormattedMessageWithValidation id="proppage_table_stat_3" />}
      >
        <ColumnTitle id="propspage_table_stats_leads" />
      </Tooltip>
    ),
    alignment: TABLE_ALIGNMENT.CENTER,
  },
  {
    id: 'creation',
    sortingField: 'creationDate',
    content: <ColumnTitle id="propspage_table_date" />,
  },
  {
    id: 'listed',
    sortingField: 'isActive',
    content: <ColumnTitle id="propspage_table_is_listed" />,
    alignment: TABLE_ALIGNMENT.CENTER,
  },
  {
    id: 'actions',
    content: <ColumnTitle id="propspage_table_actions" />,
  },
];

const TableWrapper = styled(Table)`
  position: relative;
`;
const tableName = 'unitsTable';
function UnitsTable() {
  const units = useSelector((state) => tableRowsSelector(state, tableName));
  const isLoading = useSelector((state) => state.unitsTable.isLoading);
  const { sorting } = useSelector((state) =>
    tableSelector(state, UNITS_TABLE_NAME)
  );
  const isBulkSelected = useSelector(isBulkCheckboxCheckedSelector);

  if (isLoading) return null;

  return (
    <TableWrapper
      columns={COLUMNS(isBulkSelected)}
      sorting={{
        field: sorting.field,
        direction: sortingDirectionMap[sorting.direction],
        onSort: ({ field }) =>
          dispatch({
            id: UNITS_TABLE_SORTED,
            payload: { field },
          }),
      }}
      pagination={<Pagination />}
    >
      <BulkActionsMenu onChangeSelectedItems={onToggleBulkVisibleProperties} />
      <UnitRows list={units} />
    </TableWrapper>
  );
}

function onToggleBulkVisibleProperties() {
  dispatch(UNITS_BULK_TOGGLE_VISIBLE);
}

export default UnitsTable;
