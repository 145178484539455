import { Children, useMemo } from 'react';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import styled from 'styled-components';
import Table, { COLUMN_SORTING } from 'design-system/components/Table';
import Tooltip from 'design-system/components/Tooltip';
import Typography from 'design-system/components/Typography/Typography';
import { useDatatableContext } from './hooks/useDatatableContext';
import { Rows } from './partials/Rows';
import { Pagination } from './partials/Pagination';
import { sortingSelector } from './selectors/sorting';
import { DATA_TABLE_UPDATE_SORTING } from './events/sorting';
import { BulkCheckbox } from './partials/BulkCheckbox';
import { useContextReadyEffect } from './hooks/useContextReadyEffect';
import { DATA_TABLE_INIT } from './events/init';
import { useDataTable } from './hooks/useDataTable';
import { emptyObject } from '../../utils/emptyObject';

const TableWrapper = styled(Table)`
  position: relative;
`;

const CheckboxTableHead = styled.th`
  width: 32px;
  text-align: left;
  padding: 12px 0 9px 16px;
`;

function DataTableComponent({
  children,
  menu,
  allowPagination,
  clickableRows,
  rowEvents = emptyObject,
  noItemsMessage,
  loadingComponent,
  fixedColumnsLeft,
  fixedColumnsRight,
  adjustHeightToScreen,
  ...props
}) {
  useContextReadyEffect((context) => {
    dispatch({
      id: DATA_TABLE_INIT,
      payload: context,
    });
  });

  const context = useDatatableContext();
  const { isLoading } = useDataTable();

  const { updateEvents } = context;
  const showBulkCheckboxColumn = Boolean(updateEvents.bulk);
  const nonNullChildren = Children.toArray(children);

  const columns = useMemo(
    () => generateColumnsFromChildren(nonNullChildren, showBulkCheckboxColumn),
    [nonNullChildren, showBulkCheckboxColumn]
  );

  const sorting = useSelector((state) => sortingSelector(state, context));
  const handleSorting = ({ field }) =>
    dispatch({
      id: DATA_TABLE_UPDATE_SORTING,
      payload: {
        ...context,
        sorting: {
          field,
          direction: toggleSortingDirection(sorting.direction),
        },
      },
    });

  if (isLoading && loadingComponent) {
    return loadingComponent;
  }

  return (
    <TableWrapper
      columns={columns}
      sorting={{
        ...mapStateSortingToTableSorting(sorting),
        onSort: handleSorting,
      }}
      loading={isLoading}
      pagination={allowPagination && <Pagination />}
      adjustHeightToScreen={adjustHeightToScreen}
      $fixedColumnsLeft={fixedColumnsLeft}
      $fixedColumnsRight={fixedColumnsRight}
      {...props}
    >
      {menu}
      <Rows
        columns={nonNullChildren}
        events={rowEvents}
        clickableRows={clickableRows}
        noItemsMessage={noItemsMessage}
      />
    </TableWrapper>
  );
}

export const DataTable = styled(DataTableComponent)``;

const checkboxColumn = {
  id: 'bulk_checkbox',
  content: <BulkCheckbox />,
  CellWrapper: ({ content }) => (
    <CheckboxTableHead>{content}</CheckboxTableHead>
  ),
};

function generateColumn({
  id,
  title,
  tooltip,
  alignment,
  sortingField,
  sortable,
  maxWidth,
  fullWidth,
}) {
  const content = tooltip ? (
    <Tooltip title={tooltip}>
      <Typography>{title}</Typography>
    </Tooltip>
  ) : (
    <Typography>{title}</Typography>
  );

  return {
    id,
    sortingField: (sortable || sortingField) && (sortingField ?? id),
    content,
    alignment,
    maxWidth,
    fullWidth,
  };
}

function generateColumnsFromChildren(children, showBulkCheckboxColumn) {
  const columns = children.map(({ props }) => generateColumn(props));
  if (showBulkCheckboxColumn) return [checkboxColumn, ...columns];

  return columns;
}

function mapStateSortingToTableSorting({ field, direction }) {
  const stateDirectionToTableDirectionMap = {
    asc: COLUMN_SORTING.ASCENDING,
    desc: COLUMN_SORTING.DESCENDING,
  };

  return {
    field,
    direction: stateDirectionToTableDirectionMap[direction] ?? null,
  };
}

function toggleSortingDirection(direction) {
  return direction === 'asc' ? 'desc' : 'asc';
}
