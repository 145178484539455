import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { globals, http } from 'reffects-batteries';
import { FEATURES_VALUES } from './utils/featuresFlags/constants';
import { localeFromNavigatorLanguage } from './utils/locale';
import userData from './userData';
import {
  COUNTRY_CONFIG_LOADED,
  TRANSLATIONS_LOADED_WITH_RENDER,
} from './App/events';
import { environment } from './coeffects/environment';
import {
  authToken as authTokenCoeffect,
  authTokenPayload,
} from './coeffects/authToken';
import { location as getLocation } from './coeffects/location';
import { loadTranslations } from './effects/loadTranslations';
import { pageReload } from './effects/pageReload';
import { loadCountryConfig } from './effects/loadCountryConfig';
import { TERMS_AND_CONDITIONS_DIALOG } from './partials/TermsAndConditionsDialog/constants';
import { OPEN_DIALOG } from './events/dialogs';
import { uuid } from './coeffects/uuid';
import featureFlag from './utils/featuresFlags/featureFlag';
import featureFlagsState from './utils/featuresFlags/featureFlagsState';
import { IS_LOGGED_IN } from './pages/Authentication/constants';
import { clipboard } from './effects/clipboard';
import { CHURN_MODAL } from './partials/modals/churn/constants';
import { VERIFIED_LEADS_STATS_REQUESTED } from './partials/LeadQualityAwareness/events';

export const INITIALIZE_APP = 'INITIALIZE_APP';
export const FEATURE_FLAGS_LOADED = 'FEATURE_FLAGS_LOADED';
export const FEATURE_FLAGS_LOAD_FAILED = 'FEATURE_FLAGS_LOAD_FAILED';
export const CHECK_USER_AUTHENTICATION = 'CHECK_USER_AUTHENTICATION';
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN';
export const TOKEN_VALID_REQUEST_SUCCESS = 'TOKEN_VALID_REQUEST_SUCCESS';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_DATA_LOADED_SUCCESS = 'USER_DATA_LOADED_SUCCESS';
export const START_LOGGED_PUBLISHER_CHECKING =
  'START_LOGGED_PUBLISHER_CHECKING';
export const CHECK_LOGGED_PUBLISHER = 'CHECK_LOGGED_PUBLISHER';

export const PUBLISHER_REQUESTED = 'PUBLISHER_REQUESTED';
export const PUBLISHER_LOADED = 'PUBLISHER_LOADED';
export const PUBLISHER_PAYMENT_METHODS_REQUESTED =
  'PUBLISHER_PAYMENT_METHODS_REQUESTED';
export const CREDIT_CARD_DATA_LOADED = 'CREDIT_CARD_DATA_LOADED';
export const CREDIT_CARD_DATA_LOAD_FAILED = 'CREDIT_CARD_DATA_LOAD_FAILED';
export const UNREAD_LEADS_COUNT_REQUESTED = 'UNREAD_LEADS_COUNT_REQUESTED';
export const UNREAD_LEADS_COUNT_LOADED = 'UNREAD_LEADS_COUNT_LOADED';
export const XML_IMPORT_STATS_SUMMARY_REQUESTED =
  'XML_IMPORT_STATS_SUMMARY_REQUESTED';
export const XML_IMPORT_STATS_SUMMARY_LOADED =
  'XML_IMPORT_STATS_SUMMARY_LOADED';
export const PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED =
  'PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED';
export const PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOADED =
  'PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOADED';
export const PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOAD_ERROR =
  'PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOAD_ERROR';
export const COPY_TEXT_TO_CLIPBOARD = 'COPY_TEXT_TO_CLIPBOARD';
export const PROPERTIES_SUMMARY_REQUESTED = 'PROPERTIES_SUMMARY_REQUESTED';
export const PROPERTIES_SUMMARY_LOADED = 'PROPERTIES_SUMMARY_LOADED';
export const PROPERTIES_SUMMARY_LOAD_FAILED = 'PROPERTIES_SUMMARY_LOAD_FAILED';

registerEventHandler(
  PUBLISHER_REQUESTED,
  ({ environment: { apiUrl }, state: { id } }) => ({
    ...(id ? {} : state.set({ 'publisher.loading': true })),
    ...http.get({
      url: `${apiUrl}/publishers/me`,
      successEvent: { id: PUBLISHER_LOADED },
    }),
  }),
  [environment(), state.get({ id: 'publisher.id' })]
);

registerEventHandler(
  PUBLISHER_LOADED,
  ({ uuid: { newUuid } }, [{ data }]) =>
    state.merge({
      publisher: {
        ...data,
        logoVersion: newUuid,
        backgroundImageVersion: newUuid,
        loading: false,
      },
    }),
  [uuid()]
);

registerEventHandler(
  PUBLISHER_PAYMENT_METHODS_REQUESTED,
  ({ environment: { apiUrl } }) => ({
    ...state.set({
      payment: { loading: true },
    }),
    ...http.get({
      url: `${apiUrl}/payment-methods`,
      successEvent: CREDIT_CARD_DATA_LOADED,
      errorEvent: CREDIT_CARD_DATA_LOAD_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(CREDIT_CARD_DATA_LOADED, (_, [creditCard]) =>
  state.set({
    payment: { creditCard },
  })
);

registerEventHandler(CREDIT_CARD_DATA_LOAD_FAILED, () =>
  state.set({
    payment: {},
  })
);

registerEventHandler(
  UNREAD_LEADS_COUNT_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/leads/unread`,
      successEvent: UNREAD_LEADS_COUNT_LOADED,
    }),
  [environment()]
);

registerEventHandler(UNREAD_LEADS_COUNT_LOADED, (_, [{ data }]) =>
  state.set({ leadsUnreadCount: data })
);

export default function registerEntryPointEvents() {
  registerEventHandler(
    INITIALIZE_APP,
    ({ environment: { apiUrl, version } }) => ({
      ...http.get({
        url: `${apiUrl}/features`,
        successEvent: FEATURE_FLAGS_LOADED,
        errorEvent: FEATURE_FLAGS_LOAD_FAILED,
      }),
      ...http.post({
        url: `${apiUrl}/version`,
        body: { version },
      }),
    }),
    [environment()]
  );

  registerEventHandler(FEATURE_FLAGS_LOADED, (_, [response]) => ({
    ...state.set(
      featureFlagsState(
        Object.entries(response.data).map(
          ([name, { enabled, countries } = {}]) =>
            featureFlag({ name, enabled, countries })
        )
      )
    ),
    ...effects.dispatch(CHECK_USER_AUTHENTICATION),
  }));

  registerEventHandler(FEATURE_FLAGS_LOAD_FAILED, () =>
    state.set({
      [FEATURES_VALUES]: {},
    })
  );

  registerEventHandler(
    CHECK_USER_AUTHENTICATION,
    ({
      authToken,
      environment: { apiUrl },
      location: { path, queryString },
      state: { redirectAfterLogin = null },
    }) => {
      const incomingUrl = /(\/login|\/reset-password.*)$/.test(path)
        ? redirectAfterLogin
        : path + queryString;

      if (authToken == null) {
        return {
          ...state.set({
            redirectAfterLogin: incomingUrl,
          }),
          ...effects.dispatch(USER_NOT_LOGGED_IN),
        };
      }

      return {
        ...state.set({
          redirectAfterLogin: incomingUrl,
        }),
        ...http.get({
          url: `${apiUrl}/token/valid?token=${authToken}`,
          successEvent: TOKEN_VALID_REQUEST_SUCCESS,
          errorEvent: USER_NOT_LOGGED_IN,
        }),
      };
    },
    [
      authTokenCoeffect(),
      environment(),
      getLocation(),
      state.get({ redirectAfterLogin: 'redirectAfterLogin' }),
    ]
  );

  registerEventHandler(TOKEN_VALID_REQUEST_SUCCESS, (_, [response]) => {
    const eventId = response.data.tokenIsValid
      ? USER_LOGGED_IN
      : USER_NOT_LOGGED_IN;
    return effects.dispatch(eventId);
  });

  registerEventHandler(
    USER_NOT_LOGGED_IN,
    ({
      globals: {
        navigator: { language },
      },
    }) => {
      const locale = localeFromNavigatorLanguage(language);

      return {
        ...loadTranslations({
          locale,
          onFinishEventId: TRANSLATIONS_LOADED_WITH_RENDER,
        }),
        ...state.set({
          [IS_LOGGED_IN]: false,
        }),
      };
    },
    [globals.get('navigator')]
  );

  registerEventHandler(
    USER_LOGGED_IN,
    ({ environment: { apiUrl } }) => ({
      ...state.set({
        [IS_LOGGED_IN]: true,
      }),
      ...userData.request({
        apiUrl,
        successEvent: USER_DATA_LOADED_SUCCESS,
      }),
    }),
    [environment()]
  );

  registerEventHandler(
    USER_DATA_LOADED_SUCCESS,
    ({ environment: { apiUrl } }, [{ data }]) => {
      const {
        country,
        locale,
        isBackofficeUser,
        acceptedTermsAndConditions,
        askChurnReason,
      } = data;

      const events = [
        { id: START_LOGGED_PUBLISHER_CHECKING, payload: { isBackofficeUser } },
        { id: PUBLISHER_REQUESTED },
        { id: PROPERTIES_SUMMARY_REQUESTED },
        { id: PUBLISHER_PAYMENT_METHODS_REQUESTED },
        { id: UNREAD_LEADS_COUNT_REQUESTED },
        { id: PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED },
        { id: VERIFIED_LEADS_STATS_REQUESTED },
      ];

      if (!acceptedTermsAndConditions) {
        events.push({
          id: OPEN_DIALOG,
          payload: { id: TERMS_AND_CONDITIONS_DIALOG },
        });
      }

      if (askChurnReason) {
        events.push({
          id: OPEN_DIALOG,
          payload: { id: CHURN_MODAL },
        });
      }

      return {
        ...userData.setIntoState(data),
        ...loadCountryConfig({
          apiUrl,
          country,
          successEvent: COUNTRY_CONFIG_LOADED,
        }),
        ...loadTranslations({
          locale,
          onFinishEventId: TRANSLATIONS_LOADED_WITH_RENDER,
        }),
        ...effects.dispatchMany(events),
      };
    },
    [environment()]
  );

  registerEventHandler(
    START_LOGGED_PUBLISHER_CHECKING,
    (
      {
        environment: {
          devFlags: { disableLoggedPublisherChecking },
        },
      },
      { isBackofficeUser }
    ) => {
      if (isBackofficeUser && !disableLoggedPublisherChecking) {
        return effects.dispatch(CHECK_LOGGED_PUBLISHER);
      }
      return null;
    },
    [environment()]
  );

  registerEventHandler(
    CHECK_LOGGED_PUBLISHER,
    ({
      state: { publisherId: publisherIdInState },
      authTokenPayload: {
        user: { publisherId: loggedPublisherId },
      },
    }) => {
      if (loggedPublisherId === undefined) {
        return effects.dispatchLater({
          id: CHECK_LOGGED_PUBLISHER,
          milliseconds: 1000,
        });
      }

      if (publisherIdInState === loggedPublisherId) {
        return effects.dispatchLater({
          id: CHECK_LOGGED_PUBLISHER,
          milliseconds: 1000,
        });
      }

      return pageReload();
    },
    [
      state.get({ publisherId: 'publisher:id', role: 'user.role' }),
      authTokenPayload(),
    ]
  );
}

registerEventHandler(
  XML_IMPORT_STATS_SUMMARY_REQUESTED,
  ({ state: { publishesManually }, environment: { apiUrl } }) =>
    publishesManually
      ? {}
      : http.get({
          url: `${apiUrl}/publishers/me/import-stats`,
          successEvent: XML_IMPORT_STATS_SUMMARY_LOADED,
        }),
  [
    state.get({ publishesManually: 'publisher.publishAdsManually' }),
    environment(),
  ]
);

function formatDateForSafaryCompatibility(data) {
  return data.date.replace(/-/g, '/');
}

registerEventHandler(XML_IMPORT_STATS_SUMMARY_LOADED, (_, [{ data }]) => {
  const date = formatDateForSafaryCompatibility(data);
  return state.set({ importStatsSummary: { ...data, date } });
});

registerEventHandler(
  PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/publishers/me/pending-subscription-change-request`,
      successEvent: PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOADED,
      errorEvent: PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOAD_ERROR,
    }),
  [environment()]
);

registerEventHandler(
  PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOADED,
  (_, [{ data }]) => {
    let pendingSubscriptionChangeRequest = null;
    if (data.length > 0) {
      const firstSCR = data[0];
      pendingSubscriptionChangeRequest = firstSCR;
    }

    return state.merge({
      publisher: {
        pendingSubscriptionChangeRequest,
        pendingSCRs: data,
      },
    });
  }
);

registerEventHandler(PENDING_SUBSCRIPTION_CHANGE_REQUEST_LOAD_ERROR, () =>
  state.merge({ publisher: { pendingSubscriptionChangeRequest: undefined } })
);

registerEventHandler(COPY_TEXT_TO_CLIPBOARD, (_, text) => clipboard.copy(text));

registerEventHandler(
  PROPERTIES_SUMMARY_REQUESTED,
  ({ environment: { apiUrl } }) => ({
    ...http.get({
      url: `${apiUrl}/properties-summary`,
      successEvent: [PROPERTIES_SUMMARY_LOADED],
      errorEvent: [PROPERTIES_SUMMARY_LOAD_FAILED],
    }),
    ...state.set({
      propertiesSummary: {
        loaded: false,
        loading: true,
        loadFailed: false,
      },
    }),
  }),
  [environment()]
);

registerEventHandler(PROPERTIES_SUMMARY_LOADED, (_, [response]) => ({
  ...state.set({
    propertiesSummary: {
      loaded: true,
      loading: false,
      loadFailed: false,
      maxBoostableProperties: response.data.maxBoostableProperties,
      publishedProperties: response.data.publishedProperties,
      boostedProperties: response.data.boostedProperties,
      createdProperties: response.data.createdProperties,
      superboostedProperties: response.data.superboostedProperties,
      maxSuperboostableProperties: response.data.maxSuperboostableProperties,
    },
  }),
}));

registerEventHandler(PROPERTIES_SUMMARY_LOAD_FAILED, () =>
  state.set({
    propertiesSummary: {
      loaded: true,
      loading: false,
      loadFailed: true,
    },
  })
);
