import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import RadioButton from 'design-system/components/RadioButton/RadioButton';
import Anchor from 'design-system/components/Anchor';
import {
  OutlineButton,
  PrimaryButton,
} from 'design-system/components/Button/presets';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { legalLinksSelector } from '../../../../../selectors/config';
import BankTransfer from './BankTransfer';
import {
  BACK_TO_BILLING_INFO,
  PAYMENT_METHOD_SELECTED,
  SUBMIT_PAYMENT_FORM,
  TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE,
} from './events';
import CreditCard from './CreditCard';
import {
  canSubmitFormSelector,
  formSubmittingSelector,
  selectedPaymentMethodSelector,
  showBackButtonSelector,
  showBankTransferOptionSelector,
  showCashOptionSelector,
  showCreditCardOptionSelector,
  termsAndConditionsAcceptedSelector,
} from './selectors';
import {
  BANK_TRANSFER,
  CASH,
  CREDIT_CARD,
} from '../../../../../constants/paymentMethod';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.XL)}
  }

  ${BankTransfer} {
    margin-left: ${spacing.value(SPACING_SIZE.XL)};
  }
`;

const Title = styled(Subtitle1)`
  ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
`;

const PaymentMethodsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    ${spacing.stack(SPACING_SIZE.L)}
  }
`;

const PaymentMethodOptionWrapper = styled.label`
  > * {
    ${spacing.stack(SPACING_SIZE.M)}
  }

  .radio {
    display: flex;
    align-items: center;
    gap: ${SPACING_SIZE.M}px;
    cursor: pointer;
  }

  ${Subtitle2} {
    display: flex;
    align-items: center;
    gap: ${spacing.value(SPACING_SIZE.S)};
    ${spacing.inline(SPACING_SIZE.S, false)}
  }
`;

const TermsAndConditions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.lineHeight(20)}
  ${typography.font(FONT_FAMILY.PRIMARY)}

  ${Anchor} {
    ${typography.size(FONT_SIZE.S)}
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
  }

  label {
    cursor: pointer;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const RecurrentPaymentTip = styled.div`
  margin-left: ${SPACING_SIZE.XL}px;

  ${color.background(COLOR_PALETTE.PRIMARY_A10)}
  ${spacing.inset(SPACING_SIZE.S)}
  ${radius.regular(RADIUS_SIZE.BASE)}
  ${spacing.stack(SPACING_SIZE.M)}
`;

function PaymentMethodOption({
  title,
  value,
  selectedPaymentMethod,
  children,
  disabled,
}) {
  const selectPaymentMethod = () =>
    dispatch({
      id: PAYMENT_METHOD_SELECTED,
      payload: { paymentMethod: value },
    });
  return (
    <PaymentMethodOptionWrapper>
      <div className="radio">
        <RadioButton
          name="paymentMethod"
          value={value}
          checked={selectedPaymentMethod === value}
          onChange={selectPaymentMethod}
          disabled={disabled}
        />
        <Subtitle2>{title}</Subtitle2>
      </div>
      {children}
    </PaymentMethodOptionWrapper>
  );
}

function PaymentMethods({
  selectedPaymentMethod,
  subscriptionChangeRequestId,
  disabled,
}) {
  const showBankTransferOption = useSelector(showBankTransferOptionSelector);
  const showCashOption = useSelector(showCashOptionSelector);
  const showCreditCardOption = useSelector(showCreditCardOptionSelector);

  useEffect(() => {
    if (!showBankTransferOption && !showCashOption && showCreditCardOption) {
      dispatch({
        id: PAYMENT_METHOD_SELECTED,
        payload: { paymentMethod: CREDIT_CARD },
      });
    } else {
      dispatch({
        id: PAYMENT_METHOD_SELECTED,
        payload: { paymentMethod: null },
      });
    }
  }, [showBankTransferOption, showCashOption, showCreditCardOption]);
  if (!showBankTransferOption && !showCashOption && showCreditCardOption) {
    return (
      <CreditCard subscriptionChangeRequestId={subscriptionChangeRequestId} />
    );
  }
  return (
    <PaymentMethodsWrapper>
      {showCashOption && (
        <PaymentMethodOption
          title={
            <FormattedMessageWithValidation id="checkout_payMethod_byLink" />
          }
          value={CASH}
          selectedPaymentMethod={selectedPaymentMethod}
          disabled={disabled}
        />
      )}
      {showBankTransferOption && (
        <PaymentMethodOption
          title={
            <FormattedMessageWithValidation id="checkout_payMethod_bankTransfer" />
          }
          value={BANK_TRANSFER}
          selectedPaymentMethod={selectedPaymentMethod}
          disabled={disabled}
        >
          {selectedPaymentMethod === BANK_TRANSFER && <BankTransfer />}
        </PaymentMethodOption>
      )}
      {showCreditCardOption && (
        <PaymentMethodOption
          title={
            <FormattedMessageWithValidation id="checkout_payMethod_bankCard" />
          }
          tag="checkout_payMethod_bankTransfer"
          value={CREDIT_CARD}
          selectedPaymentMethod={selectedPaymentMethod}
          disabled={disabled}
        >
          <RecurrentPaymentTip>
            <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
              <FormattedMessageWithValidation id="checkout_bankcard_recurrentText" />
            </Body1>
          </RecurrentPaymentTip>
          {selectedPaymentMethod === CREDIT_CARD && (
            <CreditCard
              subscriptionChangeRequestId={subscriptionChangeRequestId}
            />
          )}
        </PaymentMethodOption>
      )}
    </PaymentMethodsWrapper>
  );
}

function Form({ subscriptionChangeRequestId }) {
  const { termsAndConditions, privacyPolicy } = useSelector(legalLinksSelector);
  const selectedPaymentMethod = useSelector(selectedPaymentMethodSelector);
  const termsAndConditionsAccepted = useSelector(
    termsAndConditionsAcceptedSelector
  );
  const canSubmitForm = useSelector(canSubmitFormSelector);
  const showBackButton = useSelector(showBackButtonSelector);
  const isFormSubmitting = useSelector(formSubmittingSelector);

  return (
    <Wrapper>
      <Title>
        <FormattedMessageWithValidation id="checkout_step_payment_method" />
      </Title>
      <PaymentMethods
        selectedPaymentMethod={selectedPaymentMethod}
        subscriptionChangeRequestId={subscriptionChangeRequestId}
        disabled={isFormSubmitting}
      />
      {selectedPaymentMethod !== CREDIT_CARD && (
        <>
          {selectedPaymentMethod != null && (
            <TermsAndConditions>
              <Checkbox
                id="acceptTermsAndConditions"
                checked={termsAndConditionsAccepted}
                onChange={(e) =>
                  dispatch({
                    id: TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE,
                    payload: e.target.checked,
                  })
                }
                disabled={isFormSubmitting}
              />
              <label htmlFor="acceptTermsAndConditions">
                <FormattedMessageWithValidation
                  id="checkout_terms_agreement"
                  values={{
                    privacyPolicy: (
                      <Anchor href={privacyPolicy}>
                        <FormattedMessageWithValidation id="checkout_terms_agreement_privacy" />
                      </Anchor>
                    ),
                    termsConditions: (
                      <Anchor href={termsAndConditions}>
                        <FormattedMessageWithValidation id="checkout_terms_agreement_terms" />
                      </Anchor>
                    ),
                  }}
                />
              </label>
            </TermsAndConditions>
          )}
          <Actions>
            <PrimaryButton
              disabled={!canSubmitForm || isFormSubmitting}
              onClick={() =>
                dispatch({
                  id: SUBMIT_PAYMENT_FORM,
                  payload: {
                    subscriptionChangeRequestId,
                    selectedPaymentMethod,
                  },
                })
              }
            >
              <FormattedMessageWithValidation id="checkout_finishPayment" />
            </PrimaryButton>
            {showBackButton && (
              <OutlineButton
                onClick={() =>
                  dispatch({
                    id: BACK_TO_BILLING_INFO,
                    payload: { subscriptionChangeRequestId },
                  })
                }
                disabled={isFormSubmitting}
              >
                <FormattedMessageWithValidation id="checkout_back" />
              </OutlineButton>
            )}
          </Actions>
        </>
      )}
    </Wrapper>
  );
}

export default Form;
