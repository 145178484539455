import Stepper, { STEPPER_ITEM_STATUS } from 'design-system/components/Stepper';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

export const CHECKOUT_STEPS = {
  BILLING: 'billing',
  PAYMENT: 'payment',
  CONFIRMATION: 'confirmation',
};

export const CURRENT_STEP_STATUS = {
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILED: 'failed',
  PROCESSING: 'processing',
};

const steps = Object.values(CHECKOUT_STEPS);

export default function CheckoutStepper({ currentStep, currentStepStatus }) {
  return (
    <Stepper>
      <Stepper.Item status={status('billing', currentStep, currentStepStatus)}>
        <FormattedMessageWithValidation id="checkout_billing_title" />
      </Stepper.Item>
      <Stepper.Item status={status('payment', currentStep, currentStepStatus)}>
        <FormattedMessageWithValidation id="checkout_step_payment_method" />
      </Stepper.Item>
      <Stepper.Item
        status={status('confirmation', currentStep, currentStepStatus)}
      >
        <FormattedMessageWithValidation id="checkout_step_confirmation" />
      </Stepper.Item>
    </Stepper>
  );
}

function status(step, currentStep, currentStepStatus) {
  if (step === currentStep) {
    return getStatusStep(currentStepStatus) || STEPPER_ITEM_STATUS.CURRENT;
  }

  if (isCompleted(step, currentStep)) {
    return STEPPER_ITEM_STATUS.COMPLETED;
  }

  return null;
}

function isCompleted(step, currentStep) {
  const index = steps.indexOf(step);
  const currentIndex = steps.indexOf(currentStep);

  return index < currentIndex;
}

function getStatusStep(stepStatus) {
  if (
    stepStatus === CURRENT_STEP_STATUS.SUCCESS ||
    stepStatus === CURRENT_STEP_STATUS.PENDING
  ) {
    return STEPPER_ITEM_STATUS.COMPLETED;
  }
  if (stepStatus === CURRENT_STEP_STATUS.FAILED) {
    return STEPPER_ITEM_STATUS.ERROR;
  }
  return undefined;
}
