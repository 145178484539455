import { createSelector } from 'reselect';
import {
  publisherBannedBrandsForFreeListingsSelector,
  publisherCountrySelector,
  publisherIdSelector,
  publisherNameSelector,
  publisherPublishOnThailandPropertySelector,
  publisherSubscriptionPlanIsFreeSelector,
} from './publisher';
import {
  GLOBALIZA_SITE,
  ICASAS_SITE,
  LAENCONTRE_SITE,
  PUNTOPROPIEDAD_SITE,
  THAILAND_PROPERTY_SITE,
  TROVIT_SITE,
} from '../constants';
import { propertyTypesTranslationTags } from '../constants/ad';
import { localeSelector } from './user';
import {
  cookiesPolicyRoute,
  privacyPolicyRoute,
  termsAndConditionsRoute,
} from '../utils/proppitWebRouter';
import { formattedListWithLastConjunction } from '../utils/strings';

export function countryConfigSelector(state) {
  return state?.countryConfig || {};
}

export function configFlagSelector(state, flag) {
  return countryConfigSelector(state)?.flags?.[flag];
}

const DEFAULT_HELP_CENTER_LINKS = {
  home: 'https://info.proppit.com/en/support',
  advice:
    'https://info.proppit.com/en/support/tips-to-improve-your-content-quality',
  howToPromote:
    'https://info.proppit.com/en/support/how-can-i-boost-my-properties',
  faq: 'https://info.proppit.com/en/support/getting-started-in-proppit',
};

export function helpCenterLinksSelector(state) {
  const countryConfig = countryConfigSelector(state);
  const locale = localeSelector(state);

  if (
    countryConfig.helpCenterLinks == null ||
    countryConfig.helpCenterLinks[locale] == null
  ) {
    return DEFAULT_HELP_CENTER_LINKS;
  }

  return countryConfig.helpCenterLinks[locale];
}

export function allSitesSelector(state) {
  const countryConfig = countryConfigSelector(state);
  const { sitesToPublish = [] } = countryConfig;

  return sitesToPublish;
}

export function sitesWherePublishingSelector(state) {
  const sitesToPublish = allSitesSelector(state);

  const publishesOnThailandProperty =
    publisherPublishOnThailandPropertySelector(state);
  if (publishesOnThailandProperty) {
    return sitesToPublish;
  }
  return sitesToPublish.filter(({ site }) => site !== THAILAND_PROPERTY_SITE);
}

export function siteWherePublishingNamesSelector(state) {
  return sitesWherePublishingSelector(state).map(
    (siteToPublish) => siteToPublish.site
  );
}

export function sitesIdSelector(state) {
  const sitesToPublish = siteWherePublishingNamesSelector(state);
  return sitesToPublish.map((item) => item.toLowerCase());
}

export function siteDetailLinksSelector(state) {
  let filteredSites = sitesWherePublishingSelector(state).filter(
    ({ domain }) => domain.length > 0
  );
  const bannedBrands = publisherBannedBrandsForFreeListingsSelector(state);

  return ({ id, isBoosted }) => {
    if (!isBoosted) {
      filteredSites = filteredSites.filter(
        ({ brand }) => !bannedBrands.includes(brand)
      );
    }

    return filteredSites.map(({ site, domain }) => ({
      site,
      detailUrl: `${domain}${id}`,
    }));
  };
}

export function numberOfSitesPublishingForAdSelector(state, { id, isBoosted }) {
  return siteDetailLinksSelector(state)({ id, isBoosted }).length;
}

export function sitesWherePublishingWithBannedInfoSelector(state, isBoosted) {
  const filteredSites = sitesWherePublishingSelector(state);

  const bannedBrands = publisherBannedBrandsForFreeListingsSelector(state);

  return sortSitesByBanned(
    filteredSites.map(({ brand, ...rest }) => ({
      ...rest,
      brand,
      banned: !isBoosted && bannedBrands.includes(brand),
    }))
  );
}

export function adHasBannedBrandsSelector(state, isBoosted) {
  const bannedBrands = publisherBannedBrandsForFreeListingsSelector(state);

  return hasAdBannedBrands(isBoosted, bannedBrands);
}

export function siteDetailLinksWithBannedBrandsForAdSelector(state) {
  const filteredSites = sitesWherePublishingSelector(state).filter(
    ({ domain }) => domain.length > 0
  );
  const bannedBrands = publisherBannedBrandsForFreeListingsSelector(state);

  return ({ id, isBoosted }) =>
    sortSitesByBanned(
      filteredSites.map(({ site, domain, brand }) => ({
        site,
        detailUrl: `${domain}${id}`,
        banned: !isBoosted && bannedBrands.includes(brand),
      }))
    );
}

function buildPublisherPageUrl(
  site,
  publisherPage,
  publisherId,
  publisherName
) {
  if (site === TROVIT_SITE) {
    const normalizedPublisherName = publisherName
      .toLowerCase()
      .replace(new RegExp(/\s+/, 'g'), '-');
    return `${publisherPage}${normalizedPublisherName}.${publisherId}`;
  }
  if (
    [
      ICASAS_SITE,
      PUNTOPROPIEDAD_SITE,
      GLOBALIZA_SITE,
      LAENCONTRE_SITE,
    ].includes(site)
  ) {
    const normalizedPublisherName = publisherName
      .toLowerCase()
      .replace(new RegExp(/\s+/, 'g'), '-');
    return `${publisherPage}${normalizedPublisherName}-${publisherId}`;
  }
  return `${publisherPage}${publisherId}`;
}

export function sitePublisherPageLinksSelector(state) {
  const isFreePublisher = publisherSubscriptionPlanIsFreeSelector(state);
  const publisherCountryCode = publisherCountrySelector(state);
  let filteredSites = sitesWherePublishingSelector(state).filter(
    ({ publisherPage }) => publisherPage.length > 0
  );
  if (isFreePublisher && publisherCountryCode === 'TH') {
    filteredSites = filteredSites.filter(
      ({ site }) => site !== THAILAND_PROPERTY_SITE
    );
  }
  const publisherId = publisherIdSelector(state);
  const publisherName = publisherNameSelector(state);
  return filteredSites.map(({ site, publisherPage }) => ({
    site,
    publisherPageUrl: buildPublisherPageUrl(
      site,
      publisherPage,
      publisherId,
      publisherName
    ),
  }));
}

export function propertyTypesByCountrySelector(state) {
  const countryConfig = countryConfigSelector(state);
  const { adFormRules = [] } = countryConfig;
  return Object.keys(adFormRules);
}

export function propertyTypesWithTranslationTagsSelector(state) {
  return propertyTypesByCountrySelector(state).reduce(
    (options, propertyType) => ({
      ...options,
      [propertyType]: propertyTypesTranslationTags.get(propertyType),
    }),
    {}
  );
}

export function supportEmailSelector(state) {
  const config = countryConfigSelector(state);
  return config.customerSupports?.supportEmail ?? '';
}

export const formattedWebsSelector =
  (state) => (lastConjunctionTranslation) => {
    const sites = siteWherePublishingNamesSelector(state);

    return formattedListWithLastConjunction(sites, lastConjunctionTranslation);
  };

export function propertySupportedLanguagesSelector(state) {
  const countryConfig = countryConfigSelector(state);

  const supportedLanguages = countryConfig.propertySupportedLanguages ?? [];
  if (supportedLanguages.length === 0) {
    return [];
  }

  return supportedLanguages;
}

export function bankTransferDetailsSelector(state) {
  const { bankTransferDetails } = countryConfigSelector(state);
  if (!bankTransferDetails) {
    return [];
  }
  const locale = localeSelector(state);
  return bankTransferDetails[locale] ?? Object.values(bankTransferDetails)[0];
}

export const isMultilangCountrySelector = createSelector(
  propertySupportedLanguagesSelector,
  (languages) => languages.length > 1
);

export function showStatsIssuesBannerSelector(state) {
  return configFlagSelector(state, 'showStatsIssuesBanner') ?? false;
}

export function legalLinksSelector() {
  return {
    termsAndConditions: termsAndConditionsRoute(),
    privacyPolicy: privacyPolicyRoute(),
    cookiesPolicy: cookiesPolicyRoute(),
  };
}

export function countryHasSuperboostEnabled(countryCode) {
  return ['MX'].includes(countryCode);
}

function sortSitesByBanned(map) {
  return map.sort(
    ({ banned: bannedA }, { banned: bannedB }) => bannedA - bannedB
  );
}

function hasAdBannedBrands(adIsBoosted, publisherBannedBrands) {
  if (adIsBoosted) {
    return false;
  }
  return publisherBannedBrands.length > 0;
}
