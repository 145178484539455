import { registerEffectHandler } from 'reffects';

export const NAVIGATE_TO = 'navigateTo';
export const OPEN_NEW_TAB = 'openNewTab';
export const REDIRECT_TO = 'redirectTo';
export const GO_BACK = 'goBack';
export const UPDATE_BROWSER_URL = 'updateBrowserUrl';

export default function registerRouteEffects(
  navigate,
  globalVariable = window
) {
  registerEffectHandler(NAVIGATE_TO, (url) => navigate(url));

  registerEffectHandler(OPEN_NEW_TAB, ({ url, target }) =>
    globalVariable.open(url, target)
  );

  registerEffectHandler(REDIRECT_TO, (url) => {
    // eslint-disable-next-line no-param-reassign
    globalVariable.location.href = url;
  });

  registerEffectHandler(GO_BACK, () => globalVariable.history.back());

  registerEffectHandler(UPDATE_BROWSER_URL, (url) => {
    globalVariable.history.pushState({}, '', url);
  });
}

export function navigateTo(path) {
  return {
    [NAVIGATE_TO]: path,
  };
}

export function openNewTab(url, target = null) {
  return {
    [OPEN_NEW_TAB]: { url, target },
  };
}

export function redirectTo(url) {
  return {
    [REDIRECT_TO]: url,
  };
}

export function updateBrowserUrl(url) {
  return {
    [UPDATE_BROWSER_URL]: url,
  };
}

export function goBack() {
  return {
    [GO_BACK]: null,
  };
}
