import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { TABLE_ALIGNMENT } from 'design-system/components/Table';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { DataTable, TextColumn } from '../../../../../partials/DataTable';
import LeadsStats from '../../LeadsStats';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import StageFilters from '../../StageFilters/StageFilters';
import StatusColumn from '../StatusColumn';
import AdFieldsColumn from './AdFieldsColumn';
import PropertyInterestedInSnippet from '../PropertyInterestedInSnippet';
import AccumulatedPropertiesSnippet from '../AccumulatedPropertiesSnippet';
import buildTableRowEventsRedirectingTo from '../../../../../partials/DataTable/utils/buildTableRowEventsRedirectingTo';
import DateFilters from '../../DateFilters/DateFilters';
import ClearFiltersButton from '../../../../../partials/DataTable/components/filters/ClearFiltersButton';
import { LeadsFilter } from '../../LeadsFilter/LeadsFilter';
import PhoneColumn from '../PhoneColumn';
import { LastActivityColumn } from '../LastActivityColumn';
import LeadsTableSkeleton from './LeadsTableSkeleton';
import { UnreadColumn } from '../UnreadColumn';
import LeadsPropertyFilter from './LeadsPropertyFilter/LeadsPropertyFilter';
import { eligibleLeadsForLeadPlusSelector } from '../selectors';
import EmailColumn from '../EmailColumn';

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  ${spacing.stack(SPACING_SIZE.L)}

  > * {
    ${spacing.inline(SPACING_SIZE.S)}
  }
`;

const EnquiriesCount = styled(Body1)`
  line-height: 38px;
`;

const LeadPlusColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XS)};
`;

const LeadPlusText = styled.span`
  display: inline;
`;

const LeadPlusBadge = styled.div``;

function AgencyLeadsTable() {
  const eligibleLeadsForLeadPlus = useSelector(
    eligibleLeadsForLeadPlusSelector
  );

  const renderLeadPlus = ({ id, name }) => (
    <LeadPlusColumn>
      <LeadPlusText>{name}</LeadPlusText>
      <LeadPlusBadge hidden={!eligibleLeadsForLeadPlus[id]}>
        <Tooltip
          title={
            <FormattedMessageWithValidation id="lead_plus_badge_tooltip" />
          }
          $placement={TOOLTIP_PLACEMENT.RIGHT}
        >
          <Tag variant={TAG_VARIANT.GOLD}>
            <FormattedMessageWithValidation id="lead_plus_badge" />
          </Tag>
        </Tooltip>
      </LeadPlusBadge>
    </LeadPlusColumn>
  );

  return (
    <>
      <Filters>
        <LeadsFilter />
        <LeadsPropertyFilter />
        <StageFilters />
        <DateFilters />
        <ClearFiltersButton />
      </Filters>
      <LeadsStats />
      <DataTable
        allowPagination
        clickableRows
        rowEvents={buildTableRowEventsRedirectingTo(
          (itemId) => `/leads/${itemId}`
        )}
        loadingComponent={<LeadsTableSkeleton />}
        noItemsMessage={
          <FormattedMessageWithValidation id="enqpage_table_empty_results" />
        }
      >
        <TextColumn
          id="name"
          source={{
            id: 'id',
            name: 'name',
          }}
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_name" />}
          renderField={renderLeadPlus}
        />
        <EmailColumn
          id="email"
          source={{ email: 'email', hasVerifiedEmail: 'hasVerifiedEmail' }}
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_email" />}
        />
        <PhoneColumn
          id="phone"
          source={{ phone: 'phone', hasVerifiedPhone: 'hasVerifiedPhone' }}
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_phone" />}
        />
        <StatusColumn
          id="status"
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_status" />}
        />
        <AdFieldsColumn
          id="properties"
          title={<FormattedMessageWithValidation id="enqpage_table_property" />}
          renderField={({ operationType, title, address, image }) => (
            <PropertyInterestedInSnippet
              operationType={operationType}
              title={title}
              address={address}
              image={image}
            />
          )}
          renderAccumulatedField={({ total }) => (
            <AccumulatedPropertiesSnippet total={total} />
          )}
        />
        <AdFieldsColumn
          id="enquiryCount"
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_enquiry" />}
          renderField={renderEnquiryCount}
          renderAccumulatedField={renderEnquiryCount}
          alignment={TABLE_ALIGNMENT.CENTER}
        />
        <LastActivityColumn
          id="lastActivity"
          source={{
            date: 'lastActivity',
            type: 'lastActivityType',
            callStatus: 'lastActivityCallStatus',
          }}
          sortable
          title={<FormattedMessageWithValidation id="enqpage_table_date" />}
        />
        <UnreadColumn
          id="unread"
          source={{
            isUnread: 'isUnread',
          }}
        />
      </DataTable>
    </>
  );
}

function renderEnquiryCount({ enquiriesCount }) {
  return <EnquiriesCount>{enquiriesCount}</EnquiriesCount>;
}

export default AgencyLeadsTable;
