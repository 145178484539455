import { registerEffectHandler } from 'reffects';

const STONLY_IDENTIFY = 'stonly.identify';

export default function registerStonlyEffects(track, widget, store, clock) {
  registerEffectHandler(STONLY_IDENTIFY, ({ userId, email, data }) => {
    const { useStonly } = store.getState();
    if (!useStonly) return undefined;
    // This is a hack to know if the Stonly guide should be displayed again or not
    widget('addEventListener', 'close', () => {
      track('identify', userId, {
        ...data,
        form_filled: true,
        form_filled_on: clock.now(),
      });
    });
    widget('sendData', {
      contactForm: {
        email,
      },
    });
    return track('identify', userId, data);
  });
}

export const stonly = {
  identify(userId, email, data) {
    return {
      [STONLY_IDENTIFY]: {
        userId,
        email,
        data,
      },
    };
  },
};
