import {
  oneOf,
  userIsSalesManagerSelector,
  userIsSalesSelector,
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../../../../selectors/user';

export function showAssociatePublisherSelector(state) {
  return oneOf(userIsSuperadminSelector, userIsSalesManagerSelector)(state);
}

export function showDisassociatePublisherSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsSalesSelector,
    userIsSupportSelector
  )(state);
}

export function showSendPropertiesToWasiSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsSalesSelector,
    userIsSupportSelector
  )(state);
}

export function showMoveToFreeSelector(state, planType) {
  return checkUserIsSuperadminAndPaidPlan(state, planType);
}

export function showMarkAsFreeFromBeginningSelector(state, planType) {
  return checkUserIsSuperadminAndPaidPlan(state, planType);
}

function checkUserIsSuperadminAndPaidPlan(state, planType) {
  return userIsSuperadminSelector(state) && planType === 'paid';
}
