import { Link } from 'react-router-dom';
import Tag, { TAG_VARIANT } from 'design-system/components/Tag';
import { PrimaryButton } from 'design-system/components/Button/presets';
import FormattedMessageWithValidation from '../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { checkoutBillingInformationRoute } from '../../../utils/proppitWebRouter';
import { UPSELL_SUBROUTE } from '../../Checkout/configuration/constants';

export default function SubscriptionChangeRequestStatusAction({ scr }) {
  if (!scr) {
    return null;
  }

  return scr.status === 'paid' ? (
    <Tag variant={TAG_VARIANT.COMPLEMENTARY_SKYBLUE}>
      <FormattedMessageWithValidation id="subcription_upcoming_plan_already_paid" />
    </Tag>
  ) : (
    <PrimaryButton
      forwardedAs={Link}
      to={checkoutBillingInformationRoute({
        subscriptionChangeRequestId: scr.id,
        subRoute: UPSELL_SUBROUTE,
      })}
    >
      <FormattedMessageWithValidation id="subscription_pay_now" />
    </PrimaryButton>
  );
}
