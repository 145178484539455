export const COUNTRY_CODE = {
  THAILAND: 'TH',
  MEXICO: 'MX',
  CHILE: 'CL',
  ARGENTINA: 'AR',
  SPAIN: 'ES',
  UNITED_ARAB_EMIRATES: 'AE',
  COLOMBIA: 'CO',
  PERU: 'PE',
  ECUADOR: 'EC',
};
