import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';
import { navigateTo } from '../../../../effects/routing';

export const CHECKOUT_FIRST_PAYMENT_PENDING_PAGE_MOUNTED = `CHECKOUT_FIRST_PAYMENT_PENDING_PAGE_MOUNTED`;
export const CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS = `CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS`;
export const CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_RECEIVED = `CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_RECEIVED`;
export const CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_FAILED = `CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_FAILED`;

registerEventHandler(
  CHECKOUT_FIRST_PAYMENT_PENDING_PAGE_MOUNTED,
  (_, payload) =>
    effects.dispatch({
      id: CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS,
      payload,
    })
);

registerEventHandler(
  CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS,
  (
    { environment: { apiUrl } },
    { successUrl, failedUrl, subscriptionChangeRequestId }
  ) =>
    http.get({
      url: `${apiUrl}/subscription-change-requests/${subscriptionChangeRequestId}/payment-status`,
      successEvent: {
        id: CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_RECEIVED,
        payload: {
          subscriptionChangeRequestId,
          successUrl,
          failedUrl,
        },
      },
      errorEvent: {
        id: CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_FAILED,
        payload: {
          failedUrl,
        },
      },
    }),
  [environment()]
);

registerEventHandler(
  CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_RECEIVED,
  (
    _,
    [
      {
        data: { status },
      },
      { subscriptionChangeRequestId, successUrl, failedUrl },
    ]
  ) => {
    if (status === 'success') {
      return navigateTo(successUrl);
    }

    if (status === 'failed') {
      return navigateTo(failedUrl);
    }

    return effects.dispatchLater({
      id: CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS,
      payload: { subscriptionChangeRequestId, successUrl, failedUrl },
      milliseconds: 3000,
    });
  }
);

registerEventHandler(
  CHECKOUT_PAYMENT_CHECK_PAY_BY_LINK_PAYMENT_STATUS_FAILED,
  () => ({})
);
