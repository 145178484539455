import {
  paymentMethodIsBankTransferSelector,
  paymentMethodIsCashSelector,
  paymentMethodIsCreditCardSelector,
  paymentMethodSelector,
  publisherCountrySelector,
  publisherSelector,
} from '../../../../../selectors/publisher';
import { availablePaymentMethodsForCountry } from '../../../../../Backoffice/pages/partials/PaymentMethodSelect/selectors';
import { summarySelector } from '../../../partials/Summary/selectors';
import { isChile, isMexico } from '../../../../../utils/countries';

export function paymentGatewayIframeSrcSelector(state) {
  return state['profile:paymentsUrl'];
}

export function showBankTransferOptionSelector(state) {
  if (isMexico(publisherCountrySelector(state))) {
    const { totalPrice } = summarySelector(state);
    if (!totalPrice || totalPrice.amount < 40000) {
      return false;
    }
  }
  return paymentMethodIsBankTransferSelector(state);
}

export function showCashOptionSelector(state) {
  if (!paymentMethodIsAvailable(state, 'cash')) {
    return false;
  }
  return (
    paymentMethodIsBankTransferSelector(state) ||
    paymentMethodIsCashSelector(state)
  );
}

export function showCreditCardOptionSelector(state) {
  if (!paymentMethodIsAvailable(state, 'credit-card')) {
    return false;
  }
  if (isChile(publisherCountrySelector(state))) {
    return paymentMethodIsCreditCardSelector(state);
  }
  return paymentMethodSelector(state) != null;
}

function paymentMethodIsAvailable(state, paymentMethod) {
  return availablePaymentMethodsForCountry(
    publisherCountrySelector(state)
  ).some(({ id }) => id === paymentMethod);
}

export function selectedPaymentMethodSelector(state) {
  return state.selectedPaymentMethod;
}

export function bankTransferReceiptSelector(state) {
  return state.bankTransferReceipt;
}

export function bankTransferReceiptFileSizeLimitExceededErrorSelector(state) {
  return state.bankTransferReceiptFileSizeLimitExceededError ?? false;
}

export function termsAndConditionsAcceptedSelector(state) {
  return Boolean(state.termsAndConditionsAccepted);
}

export function canSubmitFormSelector(state) {
  if (!termsAndConditionsAcceptedSelector(state)) {
    return false;
  }
  const paymentMethod = selectedPaymentMethodSelector(state);
  if (!paymentMethod) {
    return false;
  }
  if (paymentMethod === 'bank-transfer') {
    return Boolean(bankTransferReceiptSelector(state));
  }
  return true;
}

export function showBackButtonSelector(state) {
  return Boolean(state.showBackButton);
}

export function formSubmittingSelector(state) {
  return Boolean(state.form?.submitting);
}

export function bankTransferConceptSelector(state) {
  const publisher = publisherSelector(state);
  if (!publisher?.billingEntity) {
    return null;
  }
  const {
    billingEntity: { billRequired, legalName },
    name,
  } = publisher;
  return billRequired ? legalName : name;
}
