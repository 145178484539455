export const LOGIN = '/login';
export const MAINTENANCE = '/maintenance';
export const FORGOT_PASSWORD = '/forgot-password';
export const CHANGE_PASSWORD = '/change-password';
export const RESET_PASSWORD = '/reset-password';
export const OVERVIEW = '/';
export const ENQUIRIES = '/enquiries';
export const ENQUIRY_DETAIL = '/enquiries/:enquiryId';
export const LEAD_DETAIL = '/leads/:leadId';
export const PROPERTIES = '/properties';
export const SUBSCRIPTION = '/subscription';
export const SUBSCRIPTION_BENEFITS = '/subscription/benefits';
export const SUBSCRIPTION_BANK_TRANSFER_DETAILS = '/subscription/bank-transfer';
export const SUBSCRIPTION_BILLING = '/subscription/billing';
export const NEW_PROPERTY = '/properties/new-property';
export const TERMS_AND_CONDITIONS = '/terms-conditions';
export const COOKIE_POLICY = '/cookies-policy';
export const PRIVACY_POLICY = '/privacy-policy';
export const NEW_UNIT = '/units/new-unit';
export const UNITS = '/units';
export const PROJECTS = '/projects';
export const IMPORT_STATS = '/import-stats';
export const CHECKOUT = '/checkout';
export const PUBLISHERS = '/publishers';
export const UPLOAD_CSV = '/upload-csv';
export const UPLOAD_CSV_FINAL = '/upload-csv-final';

const ROUTES = {
  LOGIN,
  MAINTENANCE,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  OVERVIEW,
  ENQUIRIES,
  ENQUIRY_DETAIL,
  LEAD_DETAIL,
  PROPERTIES,
  SUBSCRIPTION,
  SUBSCRIPTION_BENEFITS,
  SUBSCRIPTION_BANK_TRANSFER_DETAILS,
  SUBSCRIPTION_BILLING,
  NEW_PROPERTY,
  TERMS_AND_CONDITIONS,
  COOKIE_POLICY,
  PRIVACY_POLICY,
  NEW_UNIT,
  UNITS,
  PROJECTS,
  IMPORT_STATS,
  CHECKOUT,
  PUBLISHERS,
  UPLOAD_CSV,
};

export default ROUTES;
