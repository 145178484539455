import { useCallback, useEffect, useState } from 'react';
import { memoize } from 'lodash';
import {
  useFilter,
  useFilters,
} from '../../../../partials/DataTable/hooks/useFilter';
import LeadsSuggestions from './LeadsSuggestions';
import { LEAD_FILTERS } from '../../constants';

const removeOwnFilters = memoize((filters) => {
  const {
    'lead-suggestion': _,
    'lead-suggestion-type': __,
    ...restFilters
  } = filters;

  return restFilters;
});

export function LeadsFilter() {
  const { filters: allFilters } = useFilters();
  const [query, setQuery] = useState(null);

  const { value: suggestion, setValue: setSuggestion } = useFilter(
    LEAD_FILTERS.LEAD_SUGGESTION
  );

  useEffect(() => {
    // sync autosuggestion query with current filter value
    setQuery(suggestion?.text ?? '');
  }, [suggestion]);

  const handleSuggestionSelected = useCallback(({ type, text }) => {
    setSuggestion({ type, text });
  }, []);

  const handleClearSelection = useCallback(() => {
    setSuggestion(null);
    setQuery(null);
  }, []);

  return (
    <LeadsSuggestions
      filters={removeOwnFilters(allFilters)}
      query={query}
      onQueryChange={setQuery}
      onSuggestionSelected={handleSuggestionSelected}
      onClearSelection={handleClearSelection}
    />
  );
}
