import {
  CHANGE_REQUEST_STATUS,
  SUBSCRIPTION_CHANGE_TYPE,
} from '../../../../../../constants/subscription';
import { subscriptionChangeRequestDetailsSelector } from '../../../../../partials/RequestSubscriptionChangeDetails/selectors';
import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSalesManagerSelector,
  userIsSuperadminSelector,
} from '../../../../../../../selectors/user';

const TITLES = {
  [SUBSCRIPTION_CHANGE_TYPE.RENEW]: 'Request renewal detail',
  [SUBSCRIPTION_CHANGE_TYPE.DOWNGRADE_NEXT_CYCLE]: 'Request downgrade detail',
  [SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT]: 'Request new subscription detail',
  [SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEW_CYCLE]:
    'Request subscription for new cycle detail',
  [SUBSCRIPTION_CHANGE_TYPE.UPSELL_NEXT_CYCLE]:
    'Request upcoming subscription detail',
  [SUBSCRIPTION_CHANGE_TYPE.UPSELL_CURRENT_CYCLE]:
    'Request current subscription detail',
};

export function modalTitleSelector(state) {
  const type = subscriptionChangeRequestDetailsSelector(state)?.type;
  return TITLES[type] ?? 'Request upsell detail';
}

export function showMarkAsPaidButtonSelector(state) {
  const request = subscriptionChangeRequestDetailsSelector(state);
  if (request?.billingStatus === 'conciliated') {
    return false;
  }
  return oneOf(userIsSuperadminSelector, userIsFinanceManagerSelector)(state);
}

export function markAsPaidButtonTextSelector(state) {
  const request = subscriptionChangeRequestDetailsSelector(state);
  const requestIsPending = request?.status === CHANGE_REQUEST_STATUS.PENDING;
  if (requestIsPending) {
    return 'Mark as paid';
  }
  return 'Mark as conciliated';
}

export function showCancelButtonSelector(state) {
  const requestDetails = subscriptionChangeRequestDetailsSelector(state);
  if (requestDetails?.status === CHANGE_REQUEST_STATUS.PENDING) {
    return (
      !requestDetails?.paymentNotified ||
      oneOf(userIsSuperadminSelector, userIsFinanceManagerSelector)(state)
    );
  }
  const roles = requestDetails?.paymentNotified
    ? [userIsSuperadminSelector, userIsFinanceManagerSelector]
    : [
        userIsSuperadminSelector,
        userIsFinanceSelector,
        userIsFinanceManagerSelector,
        userIsSalesManagerSelector,
      ];
  return oneOf(...roles)(state);
}

export function disableSCRModalButtonsSelector(state) {
  return state?.scrModal?.disableButtons;
}

export function showMarkAsPaidReasonSelector(state) {
  return showMarkAsPaidButtonSelector(state) && userIsSuperadminSelector(state);
}

export function markAsPaidReasonSelector(state) {
  return subscriptionChangeRequestDetailsSelector(state)?.reason;
}

export function isReasonFilledSelector(state) {
  return !!markAsPaidReasonSelector(state);
}

export function showCancelReasonSelector(state) {
  const status = subscriptionChangeRequestDetailsSelector(state)?.status;
  return (
    showCancelButtonSelector(state) &&
    status === CHANGE_REQUEST_STATUS.PAID &&
    userIsSuperadminSelector(state)
  );
}

export function cancelReasonSelector(state) {
  return subscriptionChangeRequestDetailsSelector(state)?.cancelReason;
}

export function isCancelReasonFilledSelector(state) {
  return !!cancelReasonSelector(state);
}
