import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { openNewTab } from '../../../../../../effects/routing';

export const PAYMENT_IFRAME_EVENT_RECEIVED = 'PAYMENT_IFRAME_EVENT_RECEIVED';

registerEventHandler(
  PAYMENT_IFRAME_EVENT_RECEIVED,
  (_, { id, payload, onSuccessEvent, onBackEvent, onCheckoutFailed }) => {
    if (id === 'payments__loaded') {
      const { height } = payload;
      return state.set({ 'paymentsIframe:height': height });
    }

    if (id === 'payments__saved_correctly') {
      return {
        ...effects.dispatch(onSuccessEvent),
        ...state.set({ 'paymentsIframe:shouldGoFullScreen': false }),
      };
    }

    if (id === 'payments__go_back') {
      return {
        ...effects.dispatch(onBackEvent),
        ...state.set({ 'paymentsIframe:shouldGoFullScreen': false }),
      };
    }

    if (id === 'payments__external_content_shown') {
      return state.set({ 'paymentsIframe:shouldGoFullScreen': true });
    }

    if (id === 'payments__saved_unsatisfactorily') {
      return {
        ...state.set({ 'paymentsIframe:shouldGoFullScreen': false }),
        ...effects.dispatch(onCheckoutFailed),
      };
    }

    if (id === 'payments__terms_and_conditions_button_clicked') {
      return openNewTab('/terms-conditions');
    }

    if (id === 'payments__privacy_policy_button_clicked') {
      return openNewTab('/privacy-policy');
    }

    return {};
  }
);
