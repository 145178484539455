import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import NavigationBar from 'design-system/components/NavigationBar/NavigationBar';
import useTracking from '../../hooks/useTracking';

function TabLink({
  to,
  children,
  deep,
  eventName,
  elementName,
  sectionName,
  ...props
}) {
  const activePathMatch = deep ? `${to}/*`.replace('//', '/') : to;
  const isCurrent = useMatch(activePathMatch);
  const trackingRef = useTracking({
    trackClick: {
      eventName,
    },
    trackImpression: {
      elementName,
      payload: { visible: true },
    },
    sectionName,
  });

  return (
    <NavigationBar.TabLink
      as={Link}
      to={to}
      $isCurrent={isCurrent}
      ref={trackingRef}
      {...props}
    >
      {children}
    </NavigationBar.TabLink>
  );
}

export default styled(TabLink)``;
