import { debounce } from 'lodash';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Autocomplete from 'design-system/components/Autocomplete/Autocomplete';
import { LEADS_PROPERTY_SEARCHED } from '../events';
import { DEFAULT_AUTOCOMPLETE_DEBOUNCE } from '../../../../../../constants';
import { leadsPropertiesSelector } from '../selectors';
import SearchField from './SearchField';
import SearchOption from './SearchOption';
import { useFilter } from '../../../../../../partials/DataTable/hooks/useFilter';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { LEAD_FILTERS } from '../../../../constants';

const debouncedSearchProperties = debounce((searchText) => {
  dispatch({ id: LEADS_PROPERTY_SEARCHED, payload: { searchText } });
}, DEFAULT_AUTOCOMPLETE_DEBOUNCE);

const EMPTY_TEXT = '';

const Wrapper = styled(Autocomplete)`
  min-width: 280px;
`;

export default function LeadsPropertyFilter() {
  const leadsProperties = useSelector(leadsPropertiesSelector);
  const { value: filterValue, setValue: setFilterValue } = useFilter(
    LEAD_FILTERS.LEADS_PROPERTY
  );
  const [query, setQuery] = useState(filterValue?.title);

  useEffect(() => {
    // sync autosuggestion query with current filter value
    setQuery(filterValue?.title ?? EMPTY_TEXT);
  }, [filterValue?.id]);

  return (
    <Wrapper
      value={query}
      options={leadsProperties}
      onValueChange={(value) => {
        setQuery(value);
        debouncedSearchProperties(value);
      }}
      optionToString={(option) => option?.title ?? EMPTY_TEXT}
      onOptionSelected={(option) =>
        setFilterValue({ id: option.id, title: option.title })
      }
      renderInput={({ value = EMPTY_TEXT, getInputProps, onClear }) => (
        <SearchField
          {...getInputProps()}
          value={value}
          onRemove={() => {
            onClear();
            setFilterValue(null);
            clearQuery(setQuery);
          }}
        />
      )}
      renderOptions={({ options, getOptionProps }) =>
        options.map((option, index) => (
          <SearchOption
            option={option}
            {...getOptionProps({ option, index, refKey: 'innerRef' })}
          />
        ))
      }
      emptyStateMessage={
        <FormattedMessageWithValidation id="enqpage_prop_filters_noresult" />
      }
    />
  );
}

function clearQuery(setQuery) {
  setQuery(EMPTY_TEXT);
}
