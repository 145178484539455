import { uniqueId } from 'lodash';
import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import queryString from 'query-string';
import { state } from 'reffects-store';
import { table } from '../../partials/DataTable/effects/table';
import { mapPropertyFilters } from './mappers/mapPropertyFilters';
import { analytics } from '../../effects/analytics';
import { toast } from '../../effects/toast';
import {
  SHOW_AD_BULK_STATUS_NOTIFICATION,
  SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
  SHOW_AD_PUBLISHING_ERROR_NOTIFICATION,
} from '../../partials/modals/ad/events';
import { environment } from '../../coeffects/environment';
import { OPEN_DIALOG } from '../../events/dialogs';
import { AD_FEEDBACK_MODAL } from '../../partials/modals/ad/constants';

const COLLECTION_NAME = 'propertiesTable';

const PROGRESS_TOAST_TAGS = {
  publish: 'propspage_bulk_toast_progress_publish_activate',
  unpublish: 'propspage_bulk_toast_progress_unpublish',
  boost: 'propspage_bulk_toast_progress_boosting',
  unboost: 'propspage_bulk_toast_progress_deactivate',
  delete: 'propspage_bulk_toast_progress_delete',
};

export function createPropertiesTableAdapter() {
  const eventSuffix = uniqueId(COLLECTION_NAME);

  const UPDATE_ITEMS_TABLE_DATA = `UPDATE_ITEMS_TABLE_DATA_${eventSuffix}`;
  const UPDATE_ITEMS_TABLE_DATA_SUCCESS = `UPDATE_ITEMS_TABLE_DATA_SUCCESS_${eventSuffix}`;

  const UPDATE_ITEM = `UPDATE_ITEM_${eventSuffix}`;

  const BOOST_PROPERTY = `BOOST_PROPERTY_${eventSuffix}`;
  const BOOST_PROPERTY_SUCCEED = `BOOST_PROPERTY_SUCCEED_${eventSuffix}`;
  const UNBOOST_PROPERTY = `UNBOOST_PROPERTY_${eventSuffix}`;
  const UNBOOST_PROPERTY_SUCCEED = `UNBOOST_PROPERTY_SUCCEED_${eventSuffix}`;

  const SUPERBOOST_PROPERTY = `SUPERBOOST_PROPERTY_${eventSuffix}`;
  const SUPERBOOST_PROPERTY_SUCCEED = `SUPERBOOST_PROPERTY_SUCCEED_${eventSuffix}`;
  const UNSUPERBOOST_PROPERTY = `UNSUPERBOOST_PROPERTY_${eventSuffix}`;
  const UNSUPERBOOST_PROPERTY_SUCCEED = `UNSUPERBOOST_PROPERTY_SUCCEED_${eventSuffix}`;

  const PUBLISH_PROPERTY = `PUBLISH_PROPERTY_${eventSuffix}`;
  const PUBLISH_PROPERTY_SUCCEED = `PUBLISH_PROPERTY_SUCCEED_${eventSuffix}`;
  const PUBLISH_PROPERTY_ERROR = `PUBLISH_PROPERTY_ERROR_${eventSuffix}`;
  const UNPUBLISH_PROPERTY = `UNPUBLISH_PROPERTY_${eventSuffix}`;
  const UNPUBLISH_PROPERTY_SUCCEED = `UNPUBLISH_PROPERTY_SUCCEED_${eventSuffix}`;

  const DELETE_PROPERTY = `DELETE_PROPERTY_${eventSuffix}`;
  const DELETE_PROPERTY_SUCCEED = `DELETE_PROPERTY_SUCCEED_${eventSuffix}`;

  const UPDATE_ITEMS_BULK = `UPDATE_ITEMS_BULK_${eventSuffix}`;
  const UPDATE_ITEMS_BULK_SUCCEEDED = `UPDATE_ITEMS_BULK_SUCCEEDED_${eventSuffix}`;

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA,
    ({ select, environment: { apiUrl } }, context) => {
      const { collectionName } = context;
      const { currentPage, itemsPerPage } = select.pagination(context);
      const tableFilters = select.filters(context);
      const { field, direction } = select.sorting(context);
      const apiFilters = mapPropertyFilters(tableFilters);

      const query = queryString.stringify(
        {
          _page: currentPage,
          _limit: itemsPerPage,
          _sort: field,
          _order: direction,
          ...apiFilters,
        },
        { skipNull: true }
      );

      return {
        ...table.setBulkProcessing(collectionName, {
          isLoading: true,
          isProcessing: false,
        }),
        ...http.get({
          url: `${apiUrl}/properties${query && `?${query}`}`,
          successEvent: [UPDATE_ITEMS_TABLE_DATA_SUCCESS, context],
        }),
      };
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_TABLE_DATA_SUCCESS,
    (_, [response, context]) => {
      const { rows, totalRows, totalFilteredRows } = response.data;
      const visibleItemsIdList = rows.map(({ id }) => id);

      return table.update(context.collectionName, {
        collection: rows,
        totalItems: totalFilteredRows,
        isEmpty: totalRows === 0,
        visibleItemsIdList,
      });
    }
  );

  registerEventHandler(UPDATE_ITEM, (_, context) => {
    const { item } = context;

    const actionToEventMapping = {
      boost: BOOST_PROPERTY,
      unboost: UNBOOST_PROPERTY,
      superboost: SUPERBOOST_PROPERTY,
      unsuperboost: UNSUPERBOOST_PROPERTY,
      publish: PUBLISH_PROPERTY,
      unpublish: UNPUBLISH_PROPERTY,
      delete: DELETE_PROPERTY,
    };

    return effects.dispatch({
      id: actionToEventMapping[item.action],
      payload: context,
    });
  });

  registerEventHandler(
    BOOST_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.put({
        url: `${apiUrl}/properties/${item.id}/boost`,
        body: JSON.stringify({ boosted: true }),
        successEvent: {
          id: BOOST_PROPERTY_SUCCEED,
          payload: context,
        },
      }),
      ...analytics.trackClick(
        'boost-listing-attempt',
        'listings-table-item-boosting-toggle'
      ),
    }),
    [environment()]
  );

  registerEventHandler(
    BOOST_PROPERTY_SUCCEED,
    (
      _,
      [
        {
          data: {
            adWasBoosted,
            publisherBoostingLimitReached,
            publisherSubscribedToFreePlan,
          },
        },
        context,
      ]
    ) =>
      effects.dispatchMany([
        {
          id: SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
          payload: {
            userAction: 'boost',
            adWasBoosted,
            publisherBoostingLimitReached,
            publisherSubscribedToFreePlan,
            section: 'listings-table-item-elipsis-actions-option',
          },
        },
        {
          id: UPDATE_ITEMS_TABLE_DATA,
          payload: context,
        },
      ])
  );

  registerEventHandler(
    UNBOOST_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.put({
        url: `${apiUrl}/properties/${item.id}/boost`,
        body: { boosted: false },
        successEvent: { id: UNBOOST_PROPERTY_SUCCEED, payload: context },
      }),
      ...analytics.trackClick(
        'unboost-listing-confirmed',
        'listings-table-unboost-modal'
      ),
    }),
    [environment()]
  );

  registerEventHandler(UNBOOST_PROPERTY_SUCCEED, (_, [__, context]) => ({
    ...toast.show({ text: 'propspage_toast_confirmation_deactivate' }),
    ...effects.dispatch({ id: UPDATE_ITEMS_TABLE_DATA, payload: context }),
  }));

  registerEventHandler(
    SUPERBOOST_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.put({
        url: `${apiUrl}/properties/${item.id}/superboost`,
        body: JSON.stringify({ superboosted: true }),
        successEvent: {
          id: SUPERBOOST_PROPERTY_SUCCEED,
          payload: context,
        },
      }),
      ...analytics.trackClick(
        'superboost-listing-attempt',
        'listings-table-item-superboosting-toggle'
      ),
    }),
    [environment()]
  );

  registerEventHandler(
    SUPERBOOST_PROPERTY_SUCCEED,
    (
      _,
      [
        {
          data: {
            adWasSuperboosted,
            publisherSuperboostingLimitReached,
            publisherBoostingLimitReached,
            publisherWithNoSuperboosting,
          },
        },
        context,
      ]
    ) =>
      effects.dispatchMany([
        {
          id: SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
          payload: {
            userAction: 'superboost',
            adWasSuperboosted,
            publisherSuperboostingLimitReached,
            publisherBoostingLimitReached,
            publisherWithNoSuperboosting,
            section: 'listings-table-item-elipsis-actions-option',
          },
        },
        {
          id: UPDATE_ITEMS_TABLE_DATA,
          payload: context,
        },
      ])
  );

  registerEventHandler(
    UNSUPERBOOST_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.put({
        url: `${apiUrl}/properties/${item.id}/superboost`,
        body: { superboosted: false },
        successEvent: { id: UNSUPERBOOST_PROPERTY_SUCCEED, payload: context },
      }),
      ...analytics.trackClick(
        'unsuperboost-listing-confirmed',
        'listings-table-unsuperboost-modal'
      ),
    }),
    [environment()]
  );

  registerEventHandler(UNSUPERBOOST_PROPERTY_SUCCEED, (_, [__, context]) => ({
    ...toast.show({ text: 'propspage_toast_confirmation_unsuperboost' }),
    ...effects.dispatch({ id: UPDATE_ITEMS_TABLE_DATA, payload: context }),
  }));

  registerEventHandler(
    PUBLISH_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.put({
        url: `${apiUrl}/properties/${item.id}/published`,
        body: JSON.stringify({ published: true }),
        successEvent: {
          id: PUBLISH_PROPERTY_SUCCEED,
          payload: context,
        },
        errorEvent: {
          id: PUBLISH_PROPERTY_ERROR,
          payload: { adId: item.id },
        },
      }),
      ...analytics.trackClick(
        'publish-listing-confirmed',
        'listings-table-publishing-modal'
      ),
    }),
    [environment()]
  );

  registerEventHandler(
    PUBLISH_PROPERTY_SUCCEED,
    (
      _,
      [
        {
          data: {
            adWasPublished,
            adWasBoosted,
            publisherBoostingLimitReached,
            publisherSubscribedToFreePlan,
          },
        },
        context,
      ]
    ) =>
      effects.dispatchMany([
        {
          id: SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
          payload: {
            userAction: 'publish_and_boost',
            adWasPublished,
            adWasBoosted,
            publisherBoostingLimitReached,
            publisherSubscribedToFreePlan,
            section: 'listings-table-item-elipsis-actions-option',
          },
        },
        {
          id: UPDATE_ITEMS_TABLE_DATA,
          payload: context,
        },
      ])
  );

  registerEventHandler(
    UNPUBLISH_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.put({
        url: `${apiUrl}/properties/${item.id}/published`,
        body: JSON.stringify({ published: false }),
        successEvent: { id: UNPUBLISH_PROPERTY_SUCCEED, payload: context },
      }),
      ...analytics.trackClick(
        'unpublish-listing-confirmed',
        'listings-table-publishing-modal'
      ),
    }),
    [environment()]
  );

  registerEventHandler(UNPUBLISH_PROPERTY_SUCCEED, (_, [__, context]) => ({
    ...toast.show({ text: 'properties_list_ad_unpublished_toast' }),
    ...effects.dispatchMany([
      { id: UPDATE_ITEMS_TABLE_DATA, payload: context },
      {
        id: OPEN_DIALOG,
        payload: {
          id: AD_FEEDBACK_MODAL,
          parameters: {
            titleText: 'modal_ad_feedback_unpublish_title',
            eventName: 'unpublish_feedback_provided',
            impression: 'unpublish_feedback_modal',
          },
        },
      },
    ]),
  }));

  registerEventHandler(
    DELETE_PROPERTY,
    ({ environment: { apiUrl } }, { item, ...context }) => ({
      ...http.delete({
        url: `${apiUrl}/properties/${item.id}`,
        successEvent: {
          id: DELETE_PROPERTY_SUCCEED,
          payload: context,
        },
      }),
      ...analytics.trackClick(
        'delete-listing-confirmed',
        'listings-table-delete-modal'
      ),
    }),
    [environment()]
  );

  registerEventHandler(DELETE_PROPERTY_SUCCEED, (_, [__, context]) => ({
    ...toast.show({ text: 'propspage_toast_confirmation_delete' }),
    ...effects.dispatchMany([
      { id: UPDATE_ITEMS_TABLE_DATA, payload: context },
      {
        id: OPEN_DIALOG,
        payload: {
          id: AD_FEEDBACK_MODAL,
          parameters: {
            titleText: 'modal_ad_feedback_delete_title',
            eventName: 'delete_feedback_provided',
            impression: 'delete_feedback_modal',
          },
        },
      },
    ]),
  }));

  registerEventHandler(
    UPDATE_ITEMS_BULK,
    ({ select, environment: { apiUrl } }, { action, ...context }) => {
      const { collectionName } = context;
      const visibleSelectedItemIds = select.visibleSelectedItemIds(context);

      return {
        ...table.setBulkProcessing(collectionName),
        ...http.put({
          url: `${apiUrl}/properties/${action}`,
          body: {
            adIds: visibleSelectedItemIds,
          },
          successEvent: {
            id: UPDATE_ITEMS_BULK_SUCCEEDED,
            payload: {
              ...context,
              action,
              adIds: visibleSelectedItemIds,
            },
          },
        }),
        ...analytics.trackClick(
          'bulk-action-confirmed',
          'listings-table-bulk-actions',
          { action }
        ),
        ...toast.show({
          text: PROGRESS_TOAST_TAGS[action],
          values: { n: visibleSelectedItemIds.length },
          milliseconds: 300000,
        }),
      };
    },
    [table.select(), environment()]
  );

  registerEventHandler(
    UPDATE_ITEMS_BULK_SUCCEEDED,
    (_, [{ data }, { action, adIds, ...context }]) =>
      effects.dispatchMany([
        {
          id: SHOW_AD_BULK_STATUS_NOTIFICATION,
          payload: { userAction: action, ...data },
        },
        { id: UPDATE_ITEMS_TABLE_DATA, payload: context },
      ])
  );

  registerEventHandler(PUBLISH_PROPERTY_ERROR, (_, [{ data }, { adId }]) => ({
    ...effects.dispatch({
      id: SHOW_AD_PUBLISHING_ERROR_NOTIFICATION,
      payload: data,
    }),
    ...state.set({
      tryToPublishErrorAdId: adId,
    }),
  }));

  function registerEvents() {
    return {
      collection: UPDATE_ITEMS_TABLE_DATA,
      item: UPDATE_ITEM,
      bulk: UPDATE_ITEMS_BULK,
      events: {
        // Data fetching events
        UPDATE_ITEMS_TABLE_DATA,
        UPDATE_ITEMS_TABLE_DATA_SUCCESS,

        // Bulk events
        UPDATE_ITEM,
        UPDATE_ITEMS_BULK,
        UPDATE_ITEMS_BULK_SUCCEEDED,

        // Per-row mutation events (publish, unpublish, boost, unboost, delete)
        BOOST_PROPERTY,
        BOOST_PROPERTY_SUCCEED,
        UNBOOST_PROPERTY,
        UNBOOST_PROPERTY_SUCCEED,
        SUPERBOOST_PROPERTY,
        SUPERBOOST_PROPERTY_SUCCEED,
        UNSUPERBOOST_PROPERTY,
        UNSUPERBOOST_PROPERTY_SUCCEED,
        PUBLISH_PROPERTY,
        PUBLISH_PROPERTY_SUCCEED,
        PUBLISH_PROPERTY_ERROR,
        UNPUBLISH_PROPERTY,
        UNPUBLISH_PROPERTY_SUCCEED,
        DELETE_PROPERTY,
        DELETE_PROPERTY_SUCCEED,
      },
    };
  }

  return {
    registerEvents,
  };
}
